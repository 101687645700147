export interface Transportadora {
  id: string
  nome: string | null
}

export function getDefaultTransportadora(): Transportadora {
  return {
    id: '',
    nome: ''
  }
}