import React from 'react'
import { Slide } from '@mui/material'
import type { TransitionProps } from '@mui/material/transitions'

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  // @ts-expect-error
  return <Slide direction="up" ref={ref} {...props} />
})