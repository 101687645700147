import React, { type ChangeEvent, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { DataGrid, type GridColDef } from '@mui/x-data-grid'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { getDefaultLoginEmpresa, type LoginEmpresa } from './model/LoginEmpresa'
import PesquisaEmpresas from './pesquisa-empresas'
import { version } from '../package.json'

const columns: GridColDef[] = [
  {
    field: 'codigoCliente',
    headerName: 'Cód. Grupo',
    width: 100
  },
  {
    field: 'codigoEmpresaCliente',
    headerName: 'Cód. Empresa',
    width: 100
  },
  {
    field: 'cnpj',
    headerName: 'CNPJ',
    width: 150
  },
  {
    field: 'razaoSocial',
    headerName: 'Razão Social',
    width: 400
  }
]

export enum Perfil {
  supervisor = 1,
  gerencia,
  representante
}

export default function Login({ onLogin, setMensagem, setOpenMensagem, cnpj, setCnpj }: {
  onLogin(usuario: string, senha: string, codigoEmpresa: string, nomeEmpresa: string, perfil: keyof typeof Perfil, catalog: string, cliente: number): void
  setMensagem(value: string): void
  setOpenMensagem(value: boolean): void
  cnpj: string
  setCnpj(value: string): void
}) {
  const [perfil, setPerfil] = useState('representante' as keyof typeof Perfil)
  const [usuario, setUsuario] = useState('')
  const [senha, setSenha] = useState('')
  const [empresa, setEmpresa] = useState(getDefaultLoginEmpresa())
  const [empresas, setEmpresas] = useState([] as LoginEmpresa[])
  const [open, setOpen] = useState(false)

  async function buscarEmpresas() {
    document.cookie = `cnpj=${cnpj}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Strict; Secure`

    try {
      const result = await fetch(`/login-empresas/${cnpj}`)

      if (result.status === 200) {
        let empresas: LoginEmpresa[] = await result.json()
        empresas = empresas.map((e, index) => ({ ...e, id: (index + 1).toString() }))
        setEmpresas(empresas)
        setEmpresa(getDefaultLoginEmpresa())

        if (!empresas.length) {
          setMensagem('Nenhuma empresa encontrada.')
          setOpenMensagem(true)
        } if (IS_MOBILE)
          setOpen(true)
      } else {
        setMensagem(`Erro ${result.status} - ${result.status}`)
        setOpenMensagem(true)
      }
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor.\nVerifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)

      setOpenMensagem(true)
    }
  }

  function changeCnpj(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setCnpj(event.target.value)
  }

  function changePerfil(event: ChangeEvent<HTMLInputElement>) {
    setPerfil(event.target.value as keyof typeof Perfil)
  }

  function changeUsuario(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setUsuario(event.target.value)
  }

  function changeSenha(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setSenha(event.target.value)
  }

  function loginEnabled() {
    return !!(usuario && senha && empresa.id)
  }

  function login() {
    onLogin(usuario, senha, empresa.codigoEmpresaCliente.toString(), empresa.razaoSocial, perfil, empresa.catalog, empresa.codigoCliente)
  }

  return (
    <>
      <Grid container sx={IS_MOBILE ? {} : { marginTop: '14px' }}>
        <Grid item xs={0} md={2} />
        <Grid item xs={12} md={8} border={IS_MOBILE ? '' : '1px solid #eee'}>
          <AppBar position={IS_MOBILE ? 'fixed' : 'static'}>
            <Toolbar variant='dense'>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Gerenciador de Vendas
              </Typography>
            </Toolbar>
          </AppBar>
          <Stack direction='row' spacing={3} margin={2}>
            <TextField
              label="CNPJ do Grupo de Empresas"
              defaultValue={cnpj}
              variant='standard'
              onChange={changeCnpj}
            />
            <Button variant="contained" onClick={buscarEmpresas} disabled={!(cnpj.length === 14 && /\d{14}/.test(cnpj))}>Buscar</Button>
          </Stack>
          {IS_MOBILE
            ? <>
              <Grid container
                sx={{
                  m: '10px',
                  width: 'calc(100% - 20px)'
                }}
              >
                <Grid item xs={12}>
                  <TextField
                    label='CNPJ'
                    disabled
                    value={empresa.cnpj || ' '}
                    variant="standard"
                    sx={{
                      m: '5px',
                      width: 'calc(100% - 10px)'
                    }}
                    size='small'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Razão Social'
                    disabled
                    value={empresa.razaoSocial || ' '}
                    variant="standard"
                    sx={{
                      m: '5px',
                      width: 'calc(100% - 10px)'
                    }}
                    size='small'
                  />
                </Grid>
              </Grid>
            </>
            : <DataGrid
              rows={empresas}
              columns={columns}
              onRowSelectionModelChange={(model, details) => {
                if (model.length === 1)
                  setEmpresa(empresas.find(e => e.id === model[0]) ?? getDefaultLoginEmpresa())
                else
                  setEmpresa(getDefaultLoginEmpresa())
              }}
              sx={{ height: '267px', margin: '14px' }}
              columnHeaderHeight={25}
              rowHeight={25}
            />}
          <FormControl sx={{ margin: '15px' }}>
            <FormLabel id="demo-row-radio-buttons-group-label">Perfil</FormLabel>
            <RadioGroup
              row={!IS_MOBILE}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={perfil}
              onChange={changePerfil}
            >
              <FormControlLabel value="supervisor" control={<Radio />} label="Supervisor" />
              <FormControlLabel value="gerencia" control={<Radio />} label="Gerência" />
              <FormControlLabel value="representante" control={<Radio />} label="Representante/Funcionário" />
            </RadioGroup>
          </FormControl>
          <Stack direction='row' spacing={3} margin={2}>
            <TextField
              label="Usuário"
              value={usuario}
              variant='standard'
              onChange={changeUsuario}
            />
            <TextField
              label="Senha"
              value={senha}
              variant='standard'
              type='password'
              onChange={changeSenha}
              onKeyDown={e => {
                if (e.key === 'Enter' && loginEnabled())
                  login()
              }}
            />
            <Button
              variant="contained" onClick={login}
              disabled={!loginEnabled()}>Logar</Button>
          </Stack>
        </Grid>
        <Grid item xs={0} md={2} />
        <Grid item xs={6} md={6} p='10px' sx={{ textAlign: 'right' }}>
          <Typography variant='overline'>Versão</Typography>
        </Grid>
        <Grid item xs={6} md={6} p='10px' paddingTop='12px'>
          <Typography variant='button'>{version}</Typography>
        </Grid>
      </Grid>
      {IS_MOBILE && <PesquisaEmpresas open={open} setOpen={setOpen} empresas={empresas} setEmpresa={setEmpresa} />}
    </>
  )
}