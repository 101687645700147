import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { type Pedido } from './model/Pedido'
import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { ItemMenu } from './item-menu'
import { formatCnpj, formatCurrency } from './utils'
import { getPedidoItens, type PedidoItem } from './model/PedidoItem'

export default function DetalhePedido({ visible, empresa, pedido, setMenu, setMensagem, setOpenMensagem }: { visible: boolean, empresa: string, pedido: Pedido, setMenu(value: ItemMenu): void, setMensagem(value: string): void, setOpenMensagem(value: boolean): void }) {
  const [loaded, setLoaded] = useState(0)
  const [itens, setItens] = useState([] as PedidoItem[])

  async function load() {
    if (loaded !== pedido.id) {
      setLoaded(pedido.id)
      await getPedidoItens(empresa, pedido.id, setMensagem, setOpenMensagem, setItens, () => setLoaded(-1))
    }
  }

  load()

  return (
    <Box sx={{
      ...{ width: 'calc(100% - 10px)' },
      ...(!visible ? { display: 'none' } : {})
    }}>
      <List>
        <ListItem sx={{
          backgroundColor: '#f0f0f0',
          m: '6px 0px 0px 6px',
          borderRadius: '6px'
        }}>
          <ListItemText
            primary={`${pedido.codigoCliente}${pedido.fantasia ? ` - ${pedido.fantasia}` : ''}`}
            secondary={<>
              {[
                pedido.cnpj ? ['CNPJ', formatCnpj(pedido.cnpj)] : null,
                ['Data e hora', `${pedido.dataPedido} ${pedido.horaPedido}`],
                pedido.pagamento ? ['Pagamento', `${pedido.pagamento}${pedido.descricaoPagamento ? ` - ${pedido.descricaoPagamento}` : ''}`] : null,
                pedido.status ? ['Status', `${pedido.status}${pedido.identificacoes ? ` - ${pedido.identificacoes}` : ''}`] : null,
                ['ID', pedido.id],
                pedido.observacao ? ['Observação', pedido.observacao] : null,
                [`R$ ${formatCurrency(pedido.totalPedido)}`]
              ].filter((i): i is (string | number)[] => i !== null).map(i =>
                <Grid container>
                  {i[0] && i[1]
                    ? <>
                      <Grid item xs={6}>
                        <Typography variant='overline'>{i[0]}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2' marginTop='4px'>{i[1]}</Typography>
                      </Grid>
                    </>
                    : i[0]
                      ?
                      <Grid item xs={12}>
                        <Typography variant='h6'>{i[0]}</Typography>
                      </Grid>
                      : undefined
                  }
                </Grid>
              )}
            </>}
          />
        </ListItem>
      </List>
      <div style={{ textAlign: 'center' }}>
        <Typography variant='button' marginLeft='15px'>Itens</Typography>
      </div>
      <List>
        {itens.map((item, index) => <>
          <ListItem sx={{
            backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
            m: '6px 0px 0px 6px',
            borderRadius: '6px'
          }}>
            <ListItemText
              primary={`${item.produto} - ${item.nome}`}
              secondary={
                `${item.quantidade ?? 1}${item.unidadeEmbalagem ? ` ${item.unidadeEmbalagem}` : ''
                }${item.unidade ? ` de ${item.volume ?? 1} ${item.unidade}` : ''
                } | Unit: ${formatCurrency(item.valorUnitario ?? 0)
                } | TOTAL: ${formatCurrency(item.valorTotal ?? 0)
                }`
              }
            />
          </ListItem>
        </>
        )}
      </List>
    </Box>
  )
}