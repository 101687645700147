import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import CachedIcon from '@mui/icons-material/Cached'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, GridToolbarContainer, type GridColDef } from '@mui/x-data-grid'
import { getDefaultDestinatario, type Destinatario } from '../model/Destinatario'
import type { Perfil } from '../login'
import { formatCep, formatCnpj, formatCpf, formatTelefone, verifyLoggedAndSet } from '../utils'
import { Button, Grid, IconButton, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@mui/material'
import { ItemMenu } from '../item-menu'
import { type Configuracoes } from '../model/Configuracao'
import { getTipos, type Tipo } from '../model/Tipo'
import { getPaises, type Pais } from '../model/Pais'
import { getMunicipios, type Municipio } from '../model/Municipio'
import type { Representante } from '../model/Representante'
import type { FormaPagamento } from '../model/FormaPagamento'
import type { FormaDesconto } from '../model/FormaDesconto'
// import type { ModoFaturamento } from '../model/ModoFaturamento'
import type { Remetente } from '../model/Remetente'
import { getLocaisEntrega, type LocalEntrega } from '../model/LocalEntrega'
import type { Vendedor } from '../model/Vendedor'

export default function Clientes(props: {
  empresa: string
  vendedor: Vendedor
  perfil: keyof typeof Perfil
  id: number
  visible: boolean
  clientes: Destinatario[]
  setClientes(value: Destinatario[]): void
  setMensagem(value: string): void
  setOpenMensagem(value: boolean): void
  consfiguracoes: Configuracoes
  abas: ItemMenu[]
  setMenu(value: ItemMenu): void
  recarregar(): Promise<void>
  novoCliente: Destinatario
  setNovoCliente(value: Destinatario): void
  setVisualizarCliente(value: Destinatario): void
  tipos: Tipo[]
  setTipos(value: Tipo[]): void
  paises: Pais[]
  setPaises(value: Pais[]): void
  municipios: Municipio[]
  setMunicipios(value: Municipio[]): void
  todosRepresentantes: Representante[]
  setTodosRepresentantes(value: Representante[]): void
  formasPagamento: FormaPagamento[]
  setFormasPagamento(value: FormaPagamento[]): void
  formasDesconto: FormaDesconto[]
  setFormasDesconto(value: FormaDesconto[]): void
  // modosFaturamento: ModoFaturamento[]
  // setModosFaturamento(value: ModoFaturamento[]): void
  remetentes: Remetente[]
  setRemetentes(value: Remetente[]): void
  locaisEntrega: LocalEntrega[]
  setLocaisEntrega(value: LocalEntrega[]): void
  configuracoes: Configuracoes
}) {
  const columns: GridColDef[] = [
    {
      field: 'acoes',
      type: 'actions',
      headerName: 'Ações',
      width: props.consfiguracoes.editarClientes ? 83 : 60,
      getActions({ row }) {
        const cliente = row as Destinatario

        return [
          <GridActionsCellItem
            icon={<Tooltip title='Visualizar Cliente'><VisibilityIcon /></Tooltip>}
            label='Visualizar Cliente'
            onClick={() => handleViewClick(cliente)}
          />,
          ...(
            props.consfiguracoes.editarClientes
              ? [
                <GridActionsCellItem
                  disabled={props.abas.includes(ItemMenu.NovoCliente) || props.abas.includes(ItemMenu.AlterarCliente)}
                  icon={<Tooltip title='Alterar Cliente'><EditIcon /></Tooltip>}
                  label='Alterar Cliente'
                  onClick={() => handleEditClick(cliente)}
                />]
              : []
          )
        ]
      }
    },
    {
      field: 'representanteNome',
      headerName: 'Representante',
      width: 200
    },
    {
      field: 'id',
      headerName: 'Código',
      width: 60,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'fantasia',
      headerName: 'Fantasia',
      width: 250
    },
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      width: 400
    },
    {
      field: 'endereco',
      headerName: 'Endereço',
      width: 300
    },
    {
      field: 'numero',
      headerName: 'Nr.',
      width: 60,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'bairro',
      headerName: 'Bairro',
      width: 250
    },
    {
      field: 'municipio',
      headerName: 'Município',
      width: 200
    },
    {
      field: 'uf',
      headerName: 'UF',
      width: 20
    },
    {
      field: 'cep',
      headerName: 'CEP',
      width: 100,
      valueFormatter(params) {
        return params.value ? formatCep(params.value) : ''
      }
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 160,
      valueFormatter(params) {
        return params.value ? formatCnpj(params.value) : ''
      }
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: 110
    },
    {
      field: 'ie',
      headerName: 'Inscr. Estadual',
      width: 120
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      width: 150,
      valueFormatter(params) {
        return params.value ? formatTelefone(params.value) : ''
      }
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 300
    }
  ]

  const [loaded, setLoaded] = useState(false)
  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState([] as Destinatario[])

  function applyFilter(filter: string) {
    if (filter.trim().length > 0) {
      filter = filter.toLowerCase()

      setFiltered(props.clientes.filter(cliente =>
        cliente.fantasia?.toLowerCase().includes(filter)
        || cliente.razaoSocial?.toLowerCase().includes(filter)
        || cliente.cnpj?.includes(filter)
        || cliente.cpf?.includes(filter)
        || cliente.endereco?.toLowerCase().includes(filter)
        || cliente.municipio?.toLowerCase().includes(filter)
      ).slice(0, filter.length >= 3 ? undefined : 100))
    } else
      setFiltered([])
  }

  useEffect(() => {
    applyFilter(filter)
  }, [props.clientes])

  function handleViewClick(cliente: Destinatario) {
    props.setVisualizarCliente(cliente)
    props.setMenu(ItemMenu.Cliente)
  }

  function handleEditClick(cliente: Destinatario) {
    props.setNovoCliente({ ...cliente })
    props.setMenu(ItemMenu.AlterarCliente)
  }

  function novoCliente() {
    props.setNovoCliente({
      ...getDefaultDestinatario(),
      representante: props.vendedor.codigo.toString(),
      nomeRepresentante: props.vendedor.nome,
      representanteNome: `${props.vendedor.codigo}${props.vendedor.nome ? ` - ${props.vendedor.nome}` : ''}`
    })

    props.setMenu(ItemMenu.NovoCliente)
  }

  async function getClientes() {
    setLoaded(true)
    await props.recarregar()

    if (!props.tipos.length)
      await getTipos(props.setMensagem, props.setOpenMensagem, props.setTipos)

    if (!props.paises.length)
      await getPaises(props.setMensagem, props.setOpenMensagem, props.setPaises)

    if (!props.municipios.length)
      await getMunicipios(props.setMensagem, props.setOpenMensagem, props.setMunicipios)

    if (!(props.todosRepresentantes.length || await verifyLoggedAndSet(`/representantes/${props.empresa}`, props.setMensagem, props.setOpenMensagem, props.setTodosRepresentantes)))
      return

    if (!(props.formasPagamento.length || await verifyLoggedAndSet('/formas-pagamento', props.setMensagem, props.setOpenMensagem, props.setFormasPagamento)))
      return

    if (!(props.formasDesconto.length || await verifyLoggedAndSet('/formas-desconto', props.setMensagem, props.setOpenMensagem, props.setFormasDesconto)))
      return

    // if (!(props.modosFaturamento.length || await verifyLoggedAndSet('/modos-faturamento', props.setMensagem, props.setOpenMensagem, props.setModosFaturamento)))
    //   return

    function setRemetentes(value: Remetente[]) {
      value = value.map(i => ({ ...i, cpfCnpj: i.cnpj || i.cpf || null }))
      props.setRemetentes(value)
    }

    if (!(props.remetentes.length || await verifyLoggedAndSet('/remetentes', props.setMensagem, props.setOpenMensagem, setRemetentes)))
      return

    if (!(props.locaisEntrega.length || await getLocaisEntrega(props.empresa, props.setMensagem, props.setOpenMensagem, props.setLocaisEntrega)))
      return
  }

  if (!(props.clientes.length || loaded))
    getClientes()

  return (
    <Box sx={{
      ...(IS_MOBILE ? {} : { height: 'calc(100vh - 48px - 48px)' }),
      ...(IS_MOBILE ? { width: 'calc(100% - 10px)' } : { width: '100%' }),
      ...(!props.visible ? { display: 'none' } : {})
    }}>
      {IS_MOBILE && <>
        <div style={{ height: '10px' }}></div>
        <Grid container sx={{
          m: '5px',
          p: '10px'
        }}>
          <Grid item xs={12}>
            <TextField variant='standard' size='small' label='Filtro' value={filter}
              sx={{ width: '100%' }}
              onChange={event => {
                let filter = event.target.value
                setFilter(filter)
                applyFilter(filter)
              }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption'>Razão social, nome fantasia, CNPJ, CPF, endereço ou município</Typography>
          </Grid>
        </Grid>
      </>}
      {IS_MOBILE
        ? <List>
          {filtered.map((cliente, index) =>
            <>
              <ListItem sx={{
                backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
                m: '6px 0px 0px 6px',
                borderRadius: '6px'
              }}>
                <ListItemText
                  primary={
                    <>
                      {cliente.razaoSocial}
                      {props.configuracoes.editarClientes &&
                        <IconButton
                          size='small'
                          sx={{ m: '0px 0px 0px 5px' }}
                          onClick={() => handleEditClick(cliente)}
                        >
                          <EditIcon color='primary' />
                        </IconButton>
                      }
                    </>
                  }
                  secondary={<Grid container>
                    {[
                      (cliente.cnpj && ['CNPJ', formatCnpj(cliente.cnpj)]) ?? (cliente.cpf && ['CPF', formatCpf(cliente.cpf)]),
                      cliente.ie && ['Inscr. Estadual', cliente.ie],
                      cliente.email && ['E-mail', cliente.email],
                      cliente.endereco && ['Endereço', `${cliente.endereco}${cliente.numero !== null ? `, ${cliente.numero}` : ''}`],
                      cliente.municipio && ['Município', cliente.municipio],
                      cliente.uf && ['UF', cliente.uf],
                      cliente.situacao && ['Situação', cliente.situacao],
                      cliente.formaPagamento && ['Forma de Pagamento', `${cliente.formaPagamento}${cliente.formaPagamentoNome ? ` - ${cliente.formaPagamentoNome}` : ''}`]
                    ].filter((i): i is string[] => !!i).map(i =>
                      <>
                        <Grid item xs={6}>
                          <Typography variant='overline'>{i[0]}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2' marginTop='4px' sx={{ width: 'calc(50vw - 15px)', overflow: 'hidden', textOverflow: 'ellipsis' }}>{i[1]}</Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>}
                />
              </ListItem>
            </>
          )}
        </List>
        : <DataGrid
          rows={props.clientes}
          columns={columns}
          columnHeaderHeight={25}
          rowHeight={25}
          slots={{ toolbar: AddCliente }}
          slotProps={{
            toolbar: {
              disabled: props.abas.includes(ItemMenu.NovoCliente) || props.abas.includes(ItemMenu.AlterarCliente),
              novoCliente: props.consfiguracoes.editarClientes ? novoCliente : undefined,
              recarregar: props.recarregar
            }
          }}
        />
      }
    </Box>
  )
}

function AddCliente(props: {
  disabled: boolean
  novoCliente?(): void
  recarregar(): void
}) {
  return <GridToolbarContainer>
    {props.novoCliente &&
      <Button disabled={props.disabled} color="primary" startIcon={<AddIcon />} onClick={() => {
        props.novoCliente!()
      }}>
        Adicionar
      </Button>
    }
    <Button color="primary" startIcon={<CachedIcon />} onClick={props.recarregar}>
      Recarregar
    </Button>
  </GridToolbarContainer>
}