import { verifyLoggedAndSet } from '../utils'

export interface LocalEntrega {
  codigoCliente: number
  id: number
  endereco: string | null
}

export function getDefaultLocalEntrega(): LocalEntrega {
  return {
    codigoCliente: 0,
    id: 0,
    endereco: null
  }
}

export async function getLocaisEntrega(empresa: string, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: LocalEntrega[]) => void) {
  return await verifyLoggedAndSet(`/orcamentos/locais-entrega/${empresa}`, setMensagem, setOpenMensagem, setter)
}