import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material'

export function Frete(props: {
  disabled: boolean
  frete: string | null
  setFrete?(value: string | null): void
}) {
  function handleChangeFrete(event: SelectChangeEvent) {
    props.setFrete?.(event.target.value)
  }

  return <FormControl sx={{
    m: '5px',
    width: 'calc(100% - 10px)'
  }} variant={IS_MOBILE ? 'standard' : 'outlined'} size='small'>
    <InputLabel id="frete-label">Frete</InputLabel>
    <Select
      labelId="frete-label"
      id="frete-select"
      value={props.frete || ''}
      label="Frete"
      onChange={handleChangeFrete}
      disabled={props.disabled}
    >
      <MenuItem value={''}></MenuItem>
      <MenuItem value={'0'}>Por Conta do Remetente-Emit(CIF)</MenuItem>
      <MenuItem value={'1'}>Por conta do Destinatário (FOB)</MenuItem>
      <MenuItem value={'2'}>Por conta de Terceiros</MenuItem>
      <MenuItem value={'3'}>Próprio por Conta Remetente</MenuItem>
      <MenuItem value={'4'}>Próprio por conta do Destinatário</MenuItem>
      <MenuItem value={'9'}>Sem ocorrência de Transporte</MenuItem>
    </Select>
  </FormControl>
}