import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, type GridColDef, type GridRowParams } from '@mui/x-data-grid'
import { Dialog, AppBar, IconButton, Toolbar, Typography, Tooltip } from '@mui/material'
import { getProdutos, type Produto } from './model/Produto'
import { Transition } from './transition'
import { Search, SearchIconWrapper, StyledInputBase } from './search'

export default function PesquisaProdutos({ empresa, open, setOpen, produtos, setProdutos, setProduto, setMensagem, setOpenMensagem }: { empresa: string, open: boolean, setOpen(value: boolean): void, produtos: Produto[], setProdutos(value: Produto[]): void, setProduto(value: Produto): void, setMensagem(value: string): void, setOpenMensagem(value: boolean): void }) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: IS_MOBILE ? 'ID' : 'Código',
      width: 70,
      sortable: !IS_MOBILE
    },
    {
      field: 'descricao',
      headerName: 'Produto',
      width: 550
    },
    {
      field: 'unidadePedido',
      headerName: 'Embalagem',
      width: 90
    },
    {
      field: 'quantidadeUnidade',
      headerName: 'Qtde Unidade',
      width: 110,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'unidade',
      headerName: 'Unidade',
      width: 70
    },
    {
      field: 'precoVenda',
      headerName: 'Preço Unitário',
      width: 110,
      align: 'right',
      headerAlign: 'right',
      valueGetter(params) {
        return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
      }
    },
    {
      field: 'precoUnitario',
      headerName: 'Preço Total',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      valueGetter(params) {
        return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
      }
    }
  ]

  if (IS_MOBILE)
    columns.unshift({
      field: 'acoes',
      type: 'actions',
      width: 50,
      getActions(params) {
        return [
          <GridActionsCellItem
            icon={<Tooltip title='Selecionar Produto'><CheckCircleIcon color='primary' /></Tooltip>}
            label='Selecionar Produto'
            onClick={() => handleSelectClick(params)}
          />
        ]
      }
    })

  const [loaded, setLoaded] = useState(false)
  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState([...produtos])

  async function load() {
    setLoaded(true)
    await getProdutos(empresa, setMensagem, setOpenMensagem, setProdutos)
  }

  if (!(produtos.length || loaded))
    load()

  function handleClose() {
    setOpen(false)
  }

  function handleSelectClick(params: GridRowParams<Produto>) {
    setProduto(params.row)
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {IS_MOBILE || <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Pesquisa de Produtos
          </Typography>}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              autoFocus={!IS_MOBILE}
              placeholder="Filtro..."
              inputProps={{ 'aria-label': 'search' }}
              onChange={event => {
                let filter = event.target.value
                setFilter(filter)
                filter = filter.toLowerCase()

                if (filter.trim().length > 0)
                  setFiltered(produtos.filter(produto =>
                    produto.id.includes(filter)
                    || produto.descricao.toLowerCase().includes(filter)
                  ))
                else
                  setFiltered([...produtos])
              }}
            />
          </Search>
          {IS_MOBILE || <Typography variant="subtitle1" component="div">
            Clique duplo para selecionar
          </Typography>}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 'calc(100vh - 48px)',
          width: '100%'
        }}
      >
        <DataGrid
          rows={filtered}
          columns={columns}
          columnHeaderHeight={25}
          rowHeight={25}
          onRowDoubleClick={handleSelectClick}
        />
      </Box>
    </Dialog>
  )
}