import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, type GridColDef } from '@mui/x-data-grid'
import { Dialog, AppBar, IconButton, Toolbar, Typography, Tooltip } from '@mui/material'
import { Transition } from './transition'
import { Search, SearchIconWrapper, StyledInputBase } from './search'
import type { FormaDesconto } from './model/FormaDesconto'

export default function PesquisaFormaDesconto(props: {
  open: boolean
  setOpen(value: boolean): void
  formasDesconto: FormaDesconto[]
  setFormaDesconto(value: FormaDesconto): void
}) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: IS_MOBILE ? 'ID' : 'Código',
      width: 70,
      sortable: !IS_MOBILE
    },
    {
      field: 'descricao',
      headerName: 'Forma de Desconto',
      width: 550
    }
  ]

  if (IS_MOBILE)
    columns.unshift({
      field: 'acoes',
      type: 'actions',
      width: 50,
      getActions({ row }) {
        const formaDesconto = row as FormaDesconto

        return [
          <GridActionsCellItem
            icon={<Tooltip title='Selecionar Forma de Desconto'><CheckCircleIcon color='primary' /></Tooltip>}
            label='Selecionar Forma de Desconto'
            onClick={() => handleSelectClick(formaDesconto)}
          />
        ]
      }
    })

  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState([...props.formasDesconto])

  function handleClose() {
    props.setOpen(false)
  }

  function handleSelectClick(formaDesconto: FormaDesconto) {
    props.setFormaDesconto(formaDesconto)
    props.setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {IS_MOBILE || <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Pesquisa de Formas de Desconto
          </Typography>}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              autoFocus={!IS_MOBILE}
              placeholder="Filtro..."
              inputProps={{ 'aria-label': 'search' }}
              onChange={event => {
                let filter = event.target.value
                setFilter(filter)
                filter = filter.toLowerCase()

                if (filter.trim().length > 0)
                  setFiltered(props.formasDesconto.filter(formaDesconto =>
                    formaDesconto.id.includes(filter)
                    || formaDesconto.descricao?.toLowerCase().includes(filter)
                  ))
                else
                  setFiltered([...props.formasDesconto])
              }}
            />
          </Search>
          {IS_MOBILE || <Typography variant="subtitle1" component="div">
            Clique duplo para selecionar
          </Typography>}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 'calc(100vh - 48px)',
          width: '100%'
        }}
      >
        <DataGrid
          rows={filtered}
          columns={columns}
          columnHeaderHeight={25}
          rowHeight={25}
          onRowDoubleClick={(params, event, details) => {
            const formaDesconto = params.row as FormaDesconto

            props.setFormaDesconto(formaDesconto)
            props.setOpen(false)
          }}
        />
      </Box>
    </Dialog>
  )
}