import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, IconButton, TextField } from '@mui/material'
import { getDefaultOrcamentoItem, type Orcamento, type OrcamentoItem } from '../model/Orcamento'
import { formatCurrency, removeDiacritcs } from '../utils'
import type { ProdutoDetalheOrcamento } from '../model/ProdutoDetalheOrcamento'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { orcamentosItensColumns } from './consulta'
import { TotaisOrcamento } from './totais-orcamento'
import { Busca } from './busca'
import { NumericFormat } from 'react-number-format'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ArrowCircleLeft from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight'
import type { TributacaoProduto } from '../model/TributacaoProduto'
import type { Destinatario } from '../model/Destinatario'
import type { Vendedor } from '../model/Vendedor'
import type { Produto } from '../model/Produto'
import { Acao } from '../acao'

export function ItensOrcamento(props: {
  visible: boolean
  disabled: boolean
  orcamento: Orcamento
  itens: OrcamentoItem[]
  setItens(value: OrcamentoItem[]): void
  produtoDetalheOrcamentos: ProdutoDetalheOrcamento[]
  setProdutoDetalheOrcamentos(value: ProdutoDetalheOrcamento[]): void
  procurarProduto(): void
  item: OrcamentoItem
  setItem(value: number): void
  detalhes: ProdutoDetalheOrcamento | undefined
  acaoOrcamento: Acao
  usuario: Vendedor
  cliente: Destinatario
  produtos: Produto[]
  tributacaoProdutos: TributacaoProduto[]
}) {
  const [calculated, setCalculated] = useState(calcFields(props.item))

  useEffect(() => {
    setCalculated(calcFields(props.item))
  }, [props.item])

  const apiRef = useGridApiRef()

  function changeValue<T extends keyof OrcamentoItem>(key: T, value: OrcamentoItem[typeof key]) {
    const itens = [...props.itens]
    itens.splice(props.item!.id - 1, 1, { ...props.item!, [key]: value })
    props.setItens(itens)
  }

  function add() {
    const itens = [...props.itens, getDefaultOrcamentoItem()]
    itens.at(-1)!.id = itens.length

    props.setItens(itens)
    props.setItem(itens.length)

    if (!IS_MOBILE)
      apiRef.current.setRowSelectionModel([itens.length])
  }

  function remove() {
    const itens = [...props.itens]
    const start = props.item!.id - 1
    itens.splice(start, 1)

    for (let i = start; i < itens.length; i++)
      itens[i].id = i + 1

    props.setItens(itens)
    const row = start < itens.length ? start + 1 : start
    props.setItem(row)

    if (!IS_MOBILE)
      apiRef.current.setRowSelectionModel([row])
  }

  function previous() {
    props.setItem(props.item.id - 1)
  }

  function next() {
    props.setItem(props.item.id + 1)
  }

  function calcFields(item: OrcamentoItem) {
    let unitarioLiquido = item.valorUnitario ?? 0

    if (item.percDesc)
      var descontoUnitario = Number.parseFloat(((item.valorUnitario ?? 0) * item.percDesc / 100).toFixed(2))

    unitarioLiquido -= descontoUnitario! ?? item.descontoUnitario ?? 0
    let valorProdutos = Number.parseFloat((unitarioLiquido * item.quantidade!).toFixed(2))

    if (item.percIPI)
      var valorIPI = Number.parseFloat((valorProdutos * item.percIPI / 100).toFixed(2))

    const valorTotal =
      valorProdutos +
      (valorIPI! ?? item.valorIPI ?? 0) +
      (item.valorFrete ?? 0) +
      (item.despesasAcessorias ?? 0) -
      (item.descontoItem ?? 0)

    const tributacaoProduto = props.tributacaoProdutos.find(t => t.codigoProduto === item.codigoProduto && t.uf === props.cliente.uf)
    const simplesNacional = props.usuario.simplesNacional

    if (tributacaoProduto) {
      var percICMS = simplesNacional ? 0 : tributacaoProduto.percICMS
      var percRedICMS = tributacaoProduto.reducaoICMS
      var percIVA = simplesNacional ? tributacaoProduto.snPercIVA : tributacaoProduto.percIVA
      var percIcmsST = tributacaoProduto.subICMS
    }

    const produto = props.produtos.find(p => p.id === item.codigoProduto)

    if (produto) {
      var percPIS = simplesNacional ? 0 : produto.aliquotaPIS
      var percCOFINS = simplesNacional ? 0 : produto.aliquotaCOFINS
    }

    if (percRedICMS! > 0)
      var valorICMS = Number.parseFloat((valorTotal * ((percICMS! ?? 0) * (100 - (percRedICMS! ?? 0)) / 100) / 100).toFixed(2))
    else
      valorICMS = Number.parseFloat((valorTotal * (percICMS! ?? 0) / 100).toFixed(2))

    var valorIcmsST = Number.parseFloat(((valorTotal + (valorTotal * (percIVA! ?? 0) / 100)) * (percIcmsST! ?? 0) / 100 - valorICMS).toFixed(2))

    if (valorIcmsST < 0)
      valorIcmsST = 0

    const valorPIS = Number.parseFloat((valorTotal * (percPIS! ?? 0) / 100).toFixed(2))
    const valorCOFINS = Number.parseFloat((valorTotal * (percCOFINS! ?? 0) / 100).toFixed(2))

    return {
      unitarioLiquido,
      descontoUnitario: descontoUnitario!,
      valorProdutos,
      valorIPI: valorIPI!,
      valorTotal,
      percICMS: percICMS!,
      percRedICMS: percRedICMS!,
      percIVA: percIVA!,
      percIcmsST: percIcmsST!,
      percPIS: percPIS!,
      percCOFINS: percCOFINS!,
      valorICMS,
      valorIcmsST,
      valorPIS,
      valorCOFINS
    }
  }

  return <Grid container sx={{
    height: `calc(100vh - 96px${props.acaoOrcamento !== Acao.Visualizar ? ' - 46.5px' : ''})`,
    overflowY: 'auto',
    padding: '15px',
    ...(props.visible ? {} : { display: 'none' })
  }}>
    {IS_MOBILE || <>
      <Grid item xs={6} md={2}>
        <TextField label='Orçamento'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.orcamento.id ?? ' '}
          disabled
        />
      </Grid>
      <Grid item xs={0} md={8}>
      </Grid>
      <Grid item xs={6} md={2}>
        <FormControlLabel
          control={<Checkbox
            checked={!!props.orcamento.gerado}
          />}
          label='Encerrado'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          disabled
        />
      </Grid>
    </>}
    <Grid item xs={3} md={1}>
      <TextField label='Nro Item'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={`${props.item.id} / ${props.itens.length}`}
        disabled
      />
    </Grid>
    {IS_MOBILE && <Grid item xs={9} md={12} sx={{ textAlign: 'right' }}>
      <IconButton
        sx={{ m: '5px' }}
        color='primary'
        onClick={previous}
        disabled={props.itens.length <= 1 || props.item.id === 1}
      >
        <ArrowCircleLeft />
      </IconButton>
      <IconButton
        sx={{ m: '5px' }}
        color='primary'
        onClick={next}
        disabled={props.itens.length <= 1 || props.item.id === props.itens.length}
      >
        <ArrowCircleRight />
      </IconButton>
      <IconButton
        sx={{ m: '5px' }}
        color='primary'
        onClick={add}
        disabled={props.disabled || !props.itens.every(i => i.codigoProduto && i.quantidade && i.valorUnitario)}
      >
        <AddCircleIcon />
      </IconButton>
      <IconButton
        sx={{ m: '5px' }}
        color='secondary'
        onClick={remove}
        disabled={props.disabled || !(props.item && props.itens.length > 1)}
      >
        <RemoveCircleIcon />
      </IconButton>
    </Grid>}
    <Grid item xs={12} md={5}>
      <Busca
        disabled={props.disabled}
        id='outlined-adornment-produto1'
        label='Mercadoria e/ou Produto'
        onClick={props.procurarProduto}
        value={props.item.codigoProduto ? `${props.item.codigoProduto} - ${props.item.nomeProduto}` : null}
        required
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Unidade'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.unidade ?? ''}
        onChange={event => changeValue('unidade', removeDiacritcs(event.target.value).substring(0, 6))}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Código Analítico'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.codigoAnalitico ?? ' '}
        disabled
      />
    </Grid>
    {IS_MOBILE || <Grid item xs={12} md={2}>
      {/* <FormControlLabel
        control={<Checkbox
          checked={props.item.instalado === 'S'}
          onChange={event => changeValue('instalado', event.target.checked ? 'S' : 'N')}
        />}
        label='Instalado'
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        disabled={props.disabled}
      /> */}
    </Grid>}
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='Quantidade'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.quantidade ?? ''}
        error={!props.item.quantidade}
        onValueChange={(values, sourceInfo) => changeValue('quantidade', values.floatValue ?? null)}
        disabled={props.disabled}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='Qtde Efetivar'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.quantidadeEfetivar ?? props.item.quantidade ?? ''}
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.event)
            changeValue('quantidadeEfetivar', values.floatValue ?? null)
        }}
        disabled={props.disabled}
        error={(props.item.quantidade ?? 0) < (props.item.quantidadeEfetivar ?? 0)}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={0} md={1}></Grid>
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='$ Unitário'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.valorUnitario ?? ''}
        error={!props.item.valorUnitario}
        onValueChange={(values, sourceInfo) => changeValue('valorUnitario', values.floatValue ?? null)}
        disabled={props.disabled}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        fixedDecimalScale={true}
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='% Desconto'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.percDesc || ''}
        onValueChange={(values, sourceInfo) => changeValue('percDesc', values.floatValue ?? null)}
        disabled={!!(props.disabled || props.item.descontoUnitario || props.item.descontoItem)}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='$ Desconto Unit.'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={
          props.item.descontoUnitario ||
          (props.item.valorUnitario ?? 0) * (props.item.percDesc ?? 0) / 100 ||
          ''
        }
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.event)
            changeValue('descontoUnitario', values.floatValue ?? null)
        }}
        disabled={!!(props.disabled || props.item.percDesc || props.item.descontoItem)}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='$ Unitário Final'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.unitarioLiquido)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='% IPI'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.percIPI ?? ''}
        onValueChange={(values, sourceInfo) => changeValue('percIPI', values.floatValue ?? null)}
        disabled={props.disabled}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='$ Frete'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.valorFrete ?? ''}
        onValueChange={(values, sourceInfo) => changeValue('valorFrete', values.floatValue ?? null)}
        disabled={props.disabled}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        fixedDecimalScale={true}
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='Desp. Acess.'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.despesasAcessorias ?? ''}
        onValueChange={(values, sourceInfo) => changeValue('despesasAcessorias', values.floatValue ?? null)}
        disabled={props.disabled}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        fixedDecimalScale={true}
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <NumericFormat customInput={TextField} label='$ Descto Item'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.descontoItem || ''}
        onValueChange={(values, sourceInfo) => changeValue('descontoItem', values.floatValue ?? null)}
        disabled={!!(props.disabled || props.item.percDesc || props.item.descontoUnitario)}
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=','
        fixedDecimalScale={true}
        thousandSeparator='.'
      />
    </Grid>
    <Grid item xs={12} md={1}>
      <TextField label='Valor Total'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.valorTotal)}
        disabled
      />
    </Grid>
    <Grid item xs={12} md={2}>
      <TextField label='Prazo de Entrega'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.item.prazoEntrega}
        onChange={event => changeValue('prazoEntrega', removeDiacritcs(event.target.value).substring(0, 15))}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='% ICMS'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.percICMS)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='% Red ICMS'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.percRedICMS)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='$ ICMS'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.valorICMS)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='% MVA (ST)'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.percIVA)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='% ICMS/DIFAL'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.percIcmsST)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='$ ICMS ST'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.valorIcmsST)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='% PIS'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.percPIS) || ' '}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='$ PIS'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.valorPIS)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='% COFINS'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.percCOFINS) || ' '}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={1}>
      <TextField label='$ COFINS'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(calculated.valorCOFINS)}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Unidade'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.detalhes?.unidadeMedida ?? ' '}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Largura'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(props.item.largura) || ' '}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Comprimento'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(props.item.comprimento) || ' '}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Altura'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={formatCurrency(props.item.altura) || ' '}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Cor'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.detalhes?.cor ?? ' '}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Especificação Extra da Cor'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.detalhes?.corExtra ?? ' '}
        disabled
      />
    </Grid>
    {IS_MOBILE || <>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button
          sx={{ m: '5px' }}
          variant='contained'
          onClick={add}
          disabled={props.disabled || !props.itens.every(i => i.codigoProduto && i.quantidade && i.valorUnitario)}
        >
          <AddIcon />
        </Button>
        <Button
          sx={{ m: '5px' }}
          variant='contained'
          color='secondary'
          onClick={remove}
          disabled={props.disabled || !(props.item && props.itens.length > 1)}
        >
          <RemoveIcon />
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <DataGrid
          apiRef={apiRef}
          sx={{ m: '5px', height: '213px' }}
          columns={orcamentosItensColumns}
          rows={props.itens}
          columnHeaderHeight={25}
          rowHeight={25}
          onRowSelectionModelChange={(model, details) => {
            props.setItem(model.length === 1 ? model[0] as number : 1)
          }}
        />
      </Grid>
    </>}
    {IS_MOBILE || <TotaisOrcamento
      disabled={props.disabled}
      orcamento={props.orcamento}
    />}
  </Grid>
}