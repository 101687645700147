import React from 'react'
import DoneIcon from '@mui/icons-material/Done'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ShareIcon from '@mui/icons-material/Share'
import PrintIcon from '@mui/icons-material/Print'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material'
import { formatCpfCnpj, formatCurrency, formatDate } from '../utils'
import type { Orcamento } from '../model/Orcamento'
import type { FormaPagamento } from '../model/FormaPagamento'
import type { Destinatario } from '../model/Destinatario'

export function Orcamentos(props: {
  orcamentos: Orcamento[]
  formasPagamento: FormaPagamento[]
  clientes: Destinatario[]
  print(value: Orcamento): Promise<void>
  view(value: Orcamento): void
  edit(value: Orcamento): void
  remove(value: Orcamento): void
}) {
  return <>
    <List>
      {props.orcamentos.map((orcamento, index) =>
        <>
          <ListItem sx={{
            backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
            m: `${index === 0 ? 0 : 6}px 0px 0px 0px`,
            borderRadius: '6px'
          }}>
            <ListItemText
              primary={`${orcamento.codigoCliente}${orcamento.nomeCliente ? ` - ${orcamento.nomeCliente}` : ''}`}
              secondary={<>
                {[
                  props.clientes.find(i => i.id === orcamento.codigoCliente) && ['CPF/CNPJ', formatCpfCnpj(props.clientes.find(i => i.id === orcamento.codigoCliente)?.cpfCnpj)],
                  ['Data', formatDate(orcamento.data)],
                  orcamento.formaPagamento && ['Pagamento', `${orcamento.formaPagamento} - ${props.formasPagamento.find(i => i.id === orcamento.formaPagamento)?.nome ?? ''}`],
                  ['Status', `${orcamento.gerado ? 'Encerrado' : 'Em aberto'}`],
                  ['Orçamento', `${orcamento.id}`, !!orcamento.aprovado],
                  orcamento.obs && ['Observação', orcamento.obs],
                  [`TOTAL: R$ ${formatCurrency(orcamento.valorTotal)}`]
                ].filter((i): i is (string | boolean)[] => !!i).map(i =>
                  <Grid container>
                    {i[0] && i[1]
                      ? i[0] === 'Pagamento' || i[0] === 'Observação'
                        ? <>
                          <Grid item xs={12}>
                            <Typography variant='overline'>{i[0]}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='subtitle2'>{i[1]}</Typography>
                          </Grid>
                        </>
                        : <>
                          <Grid item xs={6}>
                            <Typography variant='overline'>{i[0]}</Typography>
                          </Grid>
                          <Grid item xs={i[0] === 'Orçamento' ? 3 : 6}>
                            <Typography variant='subtitle2' marginTop='4px' color={i[0] === 'Status' && i[1] === 'Encerrado' ? '#1976D2' : undefined}>{i[1]}</Typography>
                          </Grid>
                          {i[0] === 'Orçamento'
                            ? i[2]
                              ? <Grid item xs={3} textAlign='right'>
                                <DoneIcon color='success' />
                              </Grid>
                              : <Grid item xs={3} textAlign='right'>
                                {!orcamento.gerado &&
                                  <IconButton color='primary' sx={{ p: '4px' }} onClick={event => {
                                    props.edit(orcamento)
                                  }}>
                                    <EditIcon />
                                  </IconButton>
                                }
                                {!(orcamento.gerado || orcamento.itensPedido || orcamento.servicosPedido) &&
                                  <IconButton color='primary' sx={{ p: '4px' }} onClick={event => {
                                    props.remove(orcamento)
                                  }}>
                                    <DeleteIcon />
                                  </IconButton>
                                }
                              </Grid>
                            : undefined
                          }
                        </>
                      : i[0]
                        ? <>
                          <Grid item xs={9}>
                            <Typography variant='h6'>{i[0]}</Typography>
                          </Grid>
                          <Grid item xs={3} textAlign='right'>
                            <IconButton color='primary' sx={{ p: '4px' }} onClick={event => {
                              props.view(orcamento)
                            }}>
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton color='primary' sx={{ p: '4px' }} onClick={async event => {
                              await props.print(orcamento)
                            }}>
                              {IS_MOBILE ? <ShareIcon /> : <PrintIcon />}
                            </IconButton>
                          </Grid>
                        </>
                        : undefined
                    }
                  </Grid>
                )}
              </>}
            />
          </ListItem>
        </>
      )}
    </List>
  </>
}