import dayjs from 'dayjs'

export function formatDate(date: Date | dayjs.Dayjs | string | null | undefined, sqlFormat = false) {
  if (date == null)
    return ''
  else if (typeof date === 'string')
    if (sqlFormat)
      return date.substring(0, 10)
    else
      return `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(0, 4)}`
  if (date instanceof Date)
    if (sqlFormat)
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    else
      return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
  else
    if (sqlFormat)
      return `${date.year()}-${(date.month() + 1).toString().padStart(2, '0')}-${date.date().toString().padStart(2, '0')}`
    else
      return `${date.date().toString().padStart(2, '0')}/${(date.month() + 1).toString().padStart(2, '0')}/${date.year()}`
}

export function formatTime(time: Date | dayjs.Dayjs | string) {
  if (time == null)
    return ''
  else if (typeof time === 'string')
    return time.substring(0, 8)
  if (time instanceof Date)
    return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:${time.getSeconds().toString().padStart(2, '0')}`
  else
    return `${time.hour().toString().padStart(2, '0')}:${time.minute().toString().padStart(2, '0')}:${time.second().toString().padStart(2, '0')}`
}

export function formatCurrency(value: number | null | undefined) {
  return value == null ? '' : value.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

export function formatCep(value: string | null) {
  return value && value.length === 8 ? `${value.substring(0, 5)}-${value.substring(5)}` : value
}

export function formatCnpj(value: string | null) {
  return value && `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(12)}`
}

export function formatCpf(value: string | null) {
  return value && `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`
}

export function formatCpfCnpj(value: string | null | undefined) {
  if (value == null)
    return ''
  else if (value.length === 14)
    return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(12)}`
  else
    return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`
}

export function formatTelefone(value: string | null) {
  if (value) {
    value = value.replaceAll(' ', '')

    if (value.length === 12 && value[0] === '0' && value[1] !== '0' && value[3] === '9')
      return `(${value.substring(0, 3)}) ${value.substring(3, 8)}-${value.substring(8)}`
    if (value.length >= 12)
      return `(${value.substring(0, 4)}) ${value.substring(4, value.length - 4)}-${value.substring(value.length - 4)}`
    else
      return `(${value.substring(0, 2)}) ${value.substring(2, value.length - 4)}-${value.substring(value.length - 4)}`
  } else
    return value
}

export function removeDiacritcs(value: string) {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export async function verifyLoggedAndSet<T>(fetchInput: string, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: T) => void, onError?: () => void) {
  try {
    const result = await fetch(fetchInput)

    if (result.status === 200) {
      setter(await result.json())

      return true
    } else if (result.status === 404 && result.redirected && result.url.endsWith('/login')) {
      document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
      setMensagem('Você está deslogado.\nFavor relogar.')
      setOpenMensagem(true)
    } else {
      setMensagem(`Erro ${result.status} - ${result.statusText}`)
      setOpenMensagem(true)
    }

    return false
  } catch (e) {
    const mensagem = (e as Error).message

    if (mensagem === 'Failed to fetch')
      setMensagem('Erro ao tentar conectar com o servidor. Verifique sua conexão.')
    else
      setMensagem(`Erro: ${mensagem}.`)

    setOpenMensagem(true)
    onError?.()

    return false
  }
}

export function validateEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email)
}

export function codigoEstadoToUf(codigoEstado: number) {
  switch (codigoEstado) {
    case 0:
      return 'EX'
    case 12:
      return 'AC'
    case 27:
      return 'AL'
    case 13:
      return 'AM'
    case 16:
      return 'AP'
    case 29:
      return 'BA'
    case 23:
      return 'CE'
    case 53:
      return 'DF'
    case 32:
      return 'ES'
    case 52:
      return 'GO'
    case 21:
      return 'MA'
    case 31:
      return 'MG'
    case 50:
      return 'MS'
    case 51:
      return 'MT'
    case 15:
      return 'PA'
    case 25:
      return 'PB'
    case 26:
      return 'PE'
    case 22:
      return 'PI'
    case 41:
      return 'PR'
    case 33:
      return 'RJ'
    case 24:
      return 'RN'
    case 11:
      return 'RO'
    case 14:
      return 'RR'
    case 43:
      return 'RS'
    case 42:
      return 'SC'
    case 28:
      return 'SE'
    case 35:
      return 'SP'
    case 17:
      return 'TO'
    default:
      return null
  }
}

export function isValidCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj === '' || cnpj.length !== 14)
    return false

  // Eliminate CNPJs with all identical digits
  if (/^(\d)\1+$/.test(cnpj))
    return false

  let length = cnpj.length - 2
  let numbers = cnpj.substring(0, length)
  const digits = cnpj.substring(length)
  let sum = 0
  let pos = length - 7

  for (let i = length; i >= 1; i--) {
    sum += +numbers.charAt(length - i) * pos--

    if (pos < 2)
      pos = 9
  }

  let result = sum % 11 < 2 ? 0 : 11 - sum % 11

  if (result.toString() !== digits.charAt(0))
    return false

  length = length + 1
  numbers = cnpj.substring(0, length)
  sum = 0
  pos = length - 7

  for (let i = length; i >= 1; i--) {
    sum += +numbers.charAt(length - i) * pos--

    if (pos < 2)
      pos = 9
  }

  result = sum % 11 < 2 ? 0 : 11 - sum % 11

  if (result.toString() !== digits.charAt(1))
    return false

  return true
}

export function isValidCPF(cpf: string): boolean {
  cpf = cpf.replace(/\D/g, '') // Remove all non-numeric characters

  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) // Check length and for all identical digits
    return false

  let sum = 0
  let remainder: number

  for (let i = 1; i <= 9; i++)
    sum += +cpf[i - 1] * (11 - i)

  remainder = sum * 10 % 11

  if (remainder === 10 || remainder === 11)
    remainder = 0

  if (remainder !== +cpf[9])
    return false

  sum = 0

  for (let i = 1; i <= 10; i++)
    sum += +cpf[i - 1] * (12 - i)

  remainder = sum * 10 % 11

  if (remainder === 10 || remainder === 11)
    remainder = 0

  if (remainder !== +cpf[10])
    return false

  return true
}