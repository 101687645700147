import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, type GridColDef, type GridRowParams } from '@mui/x-data-grid'
import { Dialog, AppBar, IconButton, Toolbar, Typography, Tooltip } from '@mui/material'
import { Transition } from '../transition'
import { Search, SearchIconWrapper, StyledInputBase } from '../search'
import type { Contato } from '../model/Contato'

export default function PesquisaContato(props: {
  open: boolean
  setOpen(value: boolean): void
  contatos: Contato[]
  setContato(value: Contato): void
  setMensagem(value: string): void
  setOpenMensagem(value: boolean): void
}) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '',
      width: 70
    },
    {
      field: 'nome',
      headerName: 'Contato',
      width: 550
    }
  ]

  if (IS_MOBILE)
    columns.unshift({
      field: 'acoes',
      type: 'actions',
      width: 50,
      getActions(params) {
        return [
          <GridActionsCellItem
            icon={<Tooltip title='Selecionar Contato'><CheckCircleIcon color='primary' /></Tooltip>}
            label='Selecionar Contato'
            onClick={() => handleSelectClick(params)}
          />
        ]
      }
    })

  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState([...props.contatos])

  function handleClose() {
    props.setOpen(false)
  }

  function handleSelectClick(params: GridRowParams<Contato>) {
    props.setContato(params.row)
    props.setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {IS_MOBILE || <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Pesquisa de Contatos
          </Typography>}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              autoFocus={!IS_MOBILE}
              placeholder="Filtro..."
              inputProps={{ 'aria-label': 'search' }}
              onChange={event => {
                let filter = event.target.value
                setFilter(filter)
                filter = filter.toLowerCase()

                if (filter.trim().length > 0)
                  setFiltered(props.contatos.filter(contato =>
                    contato.nome.toLowerCase().includes(filter)
                  ))
                else
                  setFiltered([...props.contatos])
              }}
            />
          </Search>
          {IS_MOBILE || <Typography variant="subtitle1" component="div">
            Clique duplo para selecionar
          </Typography>}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 'calc(100vh - 48px)',
          width: '100%'
        }}
      >
        <DataGrid
          rows={filtered}
          columns={columns}
          columnHeaderHeight={25}
          rowHeight={25}
          onRowDoubleClick={handleSelectClick}
        />
      </Box>
    </Dialog>
  )
}