import { verifyLoggedAndSet } from '../utils'

export interface Contato {
  id: number
  nome: string
}

export function getDefaultContato(): Contato {
  return {
    id: 0,
    nome: ''
  }
}

export async function getContato(empresa: string, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: Contato[]) => void) {
  return await verifyLoggedAndSet(`/contatos/${empresa}`, setMensagem, setOpenMensagem, setter)
}