import React, { useState } from 'react'
import { DataGrid, GridActionsCellItem, useGridApiRef, type GridColDef } from '@mui/x-data-grid'
import type { Orcamento, OrcamentoItem/*, OrcamentoServico*/ } from '../model/Orcamento'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import {
  FormControl, InputLabel, Select, MenuItem, type SelectChangeEvent, TextField,
  IconButton, Input, InputAdornment, OutlinedInput, Button, Tooltip
} from '@mui/material'

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR as ptBRdp } from '@mui/x-date-pickers/locales/ptBR'
import dayjs from 'dayjs'
import PesquisaClientes from '../pesquisa-clientes'
import { getDefaultDestinatario, getDestinatarios, type Destinatario } from '../model/Destinatario'
import { type FormaPagamento, getDefaultFormaPagamento } from '../model/FormaPagamento'
import { type Transportadora, getDefaultTransportadora } from '../model/Transportadora'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import PrintIcon from '@mui/icons-material/Print'
import { formatCurrency, formatDate, verifyLoggedAndSet } from '../utils'
import type { Perfil } from '../login'
import type { Vendedor } from '../model/Vendedor'
import { ItemMenu } from '../item-menu'
import { AbaOrcamento } from './aba-orcamento'
import type { ProdutoDetalheOrcamento } from '../model/ProdutoDetalheOrcamento'
import type { LocalEntrega } from '../model/LocalEntrega'
import { getRepresentantes, type Representante } from '../model/Representante'
import type { Contato } from '../model/Contato'
import { Loading } from '../loading'
import type { Produto } from '../model/Produto'
import Mensagem from '../mensagem'
import type { TributacaoProduto } from '../model/TributacaoProduto'
import type { Configuracoes } from '../model/Configuracao'
import { Acao } from '../acao'
import { Orcamentos } from './orcamentos'

let consultaOrcamentos: string, consultaOrcamentosItens: string, orcamentoAcao: string

export function getConsultaOrcamentos() {
  return consultaOrcamentos
}

export function getConsultaOrcamentosItens() {
  return consultaOrcamentosItens
}

export const orcamentosItensColumns: GridColDef[] = [
  {
    field: 'img',
    headerName: '',
    sortable: false,
    width: 10
  },
  {
    field: 'id',
    headerName: 'Item',
    headerAlign: 'right',
    align: 'right',
    type: 'number',
    width: 30
  },
  {
    field: 'codigoProduto',
    headerName: 'Produto',
    width: 70,
    cellClassName(params) {
      return (params.row as OrcamentoItem).codigoProduto ? '' : 'cell-error'
    }
  },
  {
    field: 'codigoAnalitico',
    headerName: 'Código Analítico',
    width: 120
  },
  {
    field: 'nomeProduto',
    headerName: 'Nome Produto',
    width: 300
  },
  {
    field: 'quantidade',
    headerName: 'Qtde',
    headerAlign: 'right',
    align: 'right',
    width: 50,
    cellClassName(params) {
      return (params.row as OrcamentoItem).quantidade! > 0 ? '' : 'cell-error'
    }
  },
  {
    field: 'unidade',
    headerName: 'UN',
    width: 50
  },
  {
    field: 'valorUnitario',
    headerName: '$ Unitário',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    },
    cellClassName(params) {
      return (params.row as OrcamentoItem).valorUnitario! > 0 ? '' : 'cell-error'
    }
  },
  {
    field: 'descontoUnitario',
    headerName: '(-) $ Descto Unitário',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    },
    width: 200
  },
  {
    field: 'unitarioLiquido',
    headerName: '$ Unit. Líq.',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'valorProdutos',
    headerName: '$ Produtos',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'valorIPI',
    headerName: '$ IPI',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'valorFrete',
    headerName: '$ Frete',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'despesasAcessorias',
    headerName: '$ Desp. Acess.',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    },
    width: 110
  },
  {
    field: 'descontoItem',
    headerName: '$ Descto Item',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'valorTotal',
    headerName: '$ Total',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'percICMS',
    headerName: '% ICMS',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'percRedICMS',
    headerName: '% Red. ICMS',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'valorICMS',
    headerName: '$ ICMS',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'percIVA',
    headerName: '% IVA',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'percIcmsST',
    headerName: '% ICMS ST',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'percPIS',
    headerName: '% PIS',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'valorPIS',
    headerName: '$ PIS',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'percCOFINS',
    headerName: '% COFINS',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  },
  {
    field: 'valorCOFINS',
    headerName: '$ COFINS',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  }
]

export const orcamentosServicosColumns: GridColDef[] = [
  {
    field: 'img',
    headerName: '',
    sortable: false,
    width: 10
  },
  {
    field: 'id',
    headerName: 'Código',
    width: 70
  },
  {
    field: 'descricao',
    headerName: 'Descrição',
    width: 300
  },
  {
    field: 'valor',
    headerName: '$ Valor',
    headerAlign: 'right',
    align: 'right',
    renderCell(params) {
      return formatCurrency(params.value)
    }
  }
]

export const enum TipoData {
  Emissao,
  Aprovacao,
  Entrega
}

// export const enum TipoItem {
//   Item,
//   Servico
// }

export function ConsultaOrcamentos(props: {
  empresa: string
  perfil: keyof typeof Perfil
  vendedor: Vendedor
  visible: boolean
  orcamentos: Orcamento[]
  setOrcamentos(value: Orcamento[]): void
  orcamentosItens: OrcamentoItem[]
  setOrcamentosItens(value: OrcamentoItem[]): void
  // orcamentosServicos: OrcamentoServico[]
  // setOrcamentosServicos(value: OrcamentoServico[]): void
  todosClientes: Destinatario[]
  setTodosClientes(value: Destinatario[]): void
  formasPagamento: FormaPagamento[]
  setFormasPagamento(value: FormaPagamento[]): void
  transportadoras: Transportadora[]
  setTransportadoras(value: Transportadora[]): void
  setMenu(value: ItemMenu): void
  setOrcamento(value: Orcamento): void
  itens: OrcamentoItem[]
  setItens(value: OrcamentoItem[]): void
  // servicos: OrcamentoServico[]
  // setServicos(value: OrcamentoServico[]): void
  setOpenOrcamento(value: boolean): void
  setOpenNovoOrcamento(value: boolean): void
  setAbaOrcamento(value: AbaOrcamento): void
  setAbaNovoOrcamento(value: AbaOrcamento): void
  produtoDetalheOrcamentos: ProdutoDetalheOrcamento[]
  setProdutoDetalheOrcamentos(value: ProdutoDetalheOrcamento[]): void
  locaisEntrega: LocalEntrega[]
  setLocaisEntrega(value: LocalEntrega[]): void
  representantes: Representante[]
  setRepresentantes(value: Representante[]): void
  todosRepresentantes: Representante[]
  setTodosRepresentantes(value: Representante[]): void
  contatos: Contato[]
  setContatos(value: Contato[]): void
  produtos: Produto[]
  setProdutos(value: Produto[]): void
  acaoOrcamento: Acao
  setAcaoOrcamento(value: Acao): void
  setNovoOrcamento(value: Orcamento): void
  setNovoItens(value: OrcamentoItem[]): void
  tributacaoProdutos: TributacaoProduto[]
  setTributacaoProdutos(value: TributacaoProduto[]): void
  configuracoes: Configuracoes
  abas: ItemMenu[]
  showReport(value: Orcamento): void
}) {
  async function handlePrintClick(row: Orcamento) {
    let blob: Blob

    try {
      if (IS_MOBILE) {
        const name = `Orçamento ${row.id} - ${row.nomeCliente}`
        const result = await fetch(`/relatorio-orcamento/${props.empresa}/${row.id}/${name}`)

        if (result.status === 200) {
          blob = await result.blob()

          await navigator.share({
            files: [new File([blob], `${name}.pdf`, { type: 'application/pdf' })]
          })
        } else if (result.status === 404 && result.redirected && result.url.endsWith('/login')) {
          document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
          setMensagem('Você está deslogado.\nFavor relogar.')
          setOpenMensagem(true)
        } else {
          setMensagem(`Erro ${result.status} - ${result.statusText}.`)
          setOpenMensagem(true)
        }
      } else
        props.showReport(row)
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor.\nVerifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)

      setOpenMensagem(true)
    }
  }

  const orcamentoColumns: GridColDef[] = [
    {
      field: 'acoes',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      getActions({ row }) {
        const orcamento = row as Orcamento

        return [
          <GridActionsCellItem
            icon={<Tooltip title='Imprimir Orçamento'><PrintIcon /></Tooltip>}
            label="Imprimir Orçamento"
            onClick={() => handlePrintClick(row)}
          />,
          <GridActionsCellItem
            icon={<Tooltip title='Visualizar Orçamento'><VisibilityIcon /></Tooltip>}
            label='Visualizar Orçamento'
            onClick={() => handleViewClick(row)}
          />,
          <GridActionsCellItem
            icon={<Tooltip title='Alterar Orçamento'><EditIcon /></Tooltip>}
            label='Alterar Orçamento'
            onClick={() => handleEditClick(row)}
            disabled={!!orcamento.gerado}
          />,
          <GridActionsCellItem
            icon={<Tooltip title='Excluir Orçamento'><DeleteIcon /></Tooltip>}
            label='Excluir Orçamento'
            onClick={() => handleRemoveClick(row)}
            disabled={!!(orcamento.gerado || orcamento.itensPedido || orcamento.servicosPedido)}
          />
        ]
      }
    },
    {
      field: 'gerado',
      headerName: 'Status',
      renderCell(params) {
        return params.value ? 'Encerrado' : 'Em aberto'
      },
      cellClassName(params) {
        return params.value ? 'encerrado' : ''
      },
      width: 80
    },
    {
      field: 'id',
      headerName: 'Código',
      width: 70
    },
    {
      field: 'data',
      headerName: 'Data',
      renderCell(params) {
        return formatDate(params.value)
      }
    },
    {
      field: 'aprovado',
      headerName: 'Aprovado',
      type: 'boolean',
      width: 80
    },
    {
      field: 'dataAprovacao',
      headerName: 'Data Aprovação',
      renderCell(params) {
        return formatDate(params.value)
      },
      width: 120
    },
    {
      field: 'codigoCliente',
      headerName: 'Cód. Cliente'
    },
    {
      field: 'nomeCliente',
      headerName: 'Nome do Cliente',
      width: 400
    },
    {
      field: 'valorServico',
      headerName: '$ Serviço',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      }
    },
    {
      field: 'valorBruto',
      headerName: '$ Bruto',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      }
    },
    {
      field: 'valorFrete',
      headerName: '$ Frete',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      }
    },
    {
      field: 'valorEnc',
      headerName: '$ Desp. Acess.',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      },
      width: 110
    },
    {
      field: 'valorDesconto',
      headerName: '(-) $ Desconto',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      },
      width: 110
    },
    {
      field: 'valorTotal',
      headerName: '$ Total',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      }
    },
    {
      field: 'quantidadeItens',
      headerName: 'Qtde. Itens',
      headerAlign: 'right',
      align: 'right',
      width: 90
    },
    {
      field: 'quantidadeServicos',
      headerName: 'Qtde. Serviços',
      headerAlign: 'right',
      align: 'right',
      width: 110
    },
    {
      field: 'totalIcms',
      headerName: 'Total ICMS',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      }
    },
    {
      field: 'totalIcmsST',
      headerName: 'Total ICMS ST',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      },
      width: 110
    },
    {
      field: 'totalPis',
      headerName: 'Total PIS',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      }
    },
    {
      field: 'totalCofins',
      headerName: 'Total COFINS',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      }
    },
    {
      field: 'totalIpi',
      headerName: 'Total IPI',
      headerAlign: 'right',
      align: 'right',
      renderCell(params) {
        return formatCurrency(params.value)
      }
    },
    {
      field: 'dataNascimento',
      headerName: 'Data Nascimento',
      renderCell(params) {
        return formatDate(params.value)
      },
      width: 130
    },
    {
      field: 'sexo',
      headerName: 'Sexo',
      width: 40
    }
  ]

  const apiRef = useGridApiRef()
  const [loaded, setLoaded] = useState(false)
  const [clientes, setClientes] = useState(props.todosClientes)
  const [cliente, setCliente] = useState(getDefaultDestinatario())
  const [procuraCliente, setProcuraCliente] = useState(false)
  const [formaPagamento, setFormaPagamento] = useState(getDefaultFormaPagamento())
  const [transportadora, setTransportadora] = useState(getDefaultTransportadora())
  const [tipoData, setTipoData] = useState(0 as TipoData)
  const data = new Date
  let inicio = new Date(data.getFullYear(), data.getMonth(), data.getDate() - 7)
  const [dataInicial, setDataInicial] = useState(dayjs(inicio) as dayjs.Dayjs | null)
  const [dataFinal, setDataFinal] = useState(dayjs(data) as dayjs.Dayjs | null)
  const [orcamento, setOrcamento] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [openMensagem, setOpenMensagem] = useState(false)
  const [openMensagemExclusao, setOpenMensagemExclusao] = useState(false)
  const [fecharConsulta, setFecharConsulta] = useState(false)
  // const [tipoItens, setTipoItens] = useState(TipoItem.Item)
  const [orcamentoSelecionado, setOrcamentoSelecionado] = useState('')
  const [openLoading, setOpenLoading] = useState(true)

  function handleChangeTipoData(event: SelectChangeEvent) {
    setTipoData(+event.target.value)
  }

  function _setTodosClientes(clientes: Destinatario[]) {
    props.setTodosClientes(clientes)
    setClientes(clientes)
  }

  async function recarregarClientes() {
    await getDestinatarios(props.empresa, props.configuracoes.filtrarClientesPorRepresentanteOrcamentos ? props.perfil : 'gerencia', props.vendedor.codigo, setMensagem, setOpenMensagem, _setTodosClientes, ['A', 'T', 'G'])
  }

  async function load() {
    setLoaded(true)

    if (!(props.todosClientes.length || await getDestinatarios(props.empresa, props.configuracoes.filtrarClientesPorRepresentanteOrcamentos ? props.perfil : 'gerencia', props.vendedor.codigo, setMensagem, setOpenMensagem, _setTodosClientes, ['A', 'T', 'G'], () => setFecharConsulta(true))))
      return

    if (!(props.formasPagamento.length || await verifyLoggedAndSet('/formas-pagamento', setMensagem, setOpenMensagem, props.setFormasPagamento, () => setFecharConsulta(true))))
      return

    if (!(props.transportadoras.length || await verifyLoggedAndSet(`/transportadoras/${props.empresa}`, setMensagem, setOpenMensagem, props.setTransportadoras, () => setFecharConsulta(true))))
      return

    if (!(props.produtoDetalheOrcamentos.length || await verifyLoggedAndSet(`/orcamentos/produtos-detalhes/${props.empresa}`, setMensagem, setOpenMensagem, props.setProdutoDetalheOrcamentos, () => setFecharConsulta(true))))
      return

    if (!(props.locaisEntrega.length || await verifyLoggedAndSet(`/orcamentos/locais-entrega/${props.empresa}`, setMensagem, setOpenMensagem, props.setLocaisEntrega, () => setFecharConsulta(true))))
      return

    if (!(props.representantes.length || await getRepresentantes(props.empresa, props.perfil, props.vendedor.codigo, setMensagem, setOpenMensagem, props.setRepresentantes, () => setFecharConsulta(true))))
      return

    if (!(props.todosRepresentantes.length || await verifyLoggedAndSet(`/representantes/${props.empresa}`, setMensagem, setOpenMensagem, props.setTodosRepresentantes, () => setFecharConsulta(true))))
      return

    if (!(props.contatos.length || await verifyLoggedAndSet(`/contatos/${props.empresa}`, setMensagem, setOpenMensagem, props.setContatos, () => setFecharConsulta(true))))
      return

    if (!(props.produtos.length || await verifyLoggedAndSet(`/produtos/${props.empresa}`, setMensagem, setOpenMensagem, props.setProdutos, () => setFecharConsulta(true))))
      return

    if (!(props.tributacaoProdutos.length || await verifyLoggedAndSet(`/tributacao-produtos/${props.empresa}`, setMensagem, setOpenMensagem, props.setTributacaoProdutos, () => setFecharConsulta(true))))
      return
  }

  if (!loaded)
    load()

  function procurarCliente() {
    setProcuraCliente(true)
  }

  function clear() {
    setTipoData(TipoData.Emissao)
    setDataInicial(dayjs(inicio))
    setDataFinal(dayjs(data))
    setOrcamento('')
    setCliente(getDefaultDestinatario())
  }

  async function search() {
    consultaOrcamentos = `/orcamentos/consulta/${props.empresa},${props.perfil},${props.vendedor.codigo},${tipoData},${formatDate(dataInicial!, true)},${formatDate(dataFinal!, true)},${orcamento},${cliente.id}`
    consultaOrcamentosItens = `/orcamentos/itens/${props.empresa},${props.perfil},${props.vendedor.codigo},${tipoData},${formatDate(dataInicial!, true)},${formatDate(dataFinal!, true)},${orcamento},${cliente.id}`

    await verifyLoggedAndSet(consultaOrcamentos, setMensagem, setOpenMensagem, props.setOrcamentos)
    await verifyLoggedAndSet(consultaOrcamentosItens, setMensagem, setOpenMensagem, props.setOrcamentosItens)
    // await verifyLoggedAndSet(`/orcamentos/servicos/${props.empresa},${props.vendedor.codigo},${tipoData},${formatDate(dataInicial!, true)},${formatDate(dataFinal!, true)},${orcamento},${cliente.id}`, setMensagem, setOpenMensagem, props.setOrcamentosServicos)
  }

  function add() {
    props.setOpenNovoOrcamento(true)
    props.setAcaoOrcamento(Acao.Incluir)
  }

  // function orcamentosItens() {
  //   setTipoItens(TipoItem.Item)
  // }

  // function orcamentosServicos() {
  //   setTipoItens(TipoItem.Servico)
  // }

  function _setOrcamentoSelecionado(orcamento: string) {
    setOrcamentoSelecionado(orcamento)

    if (orcamento === '') {
      props.setItens([])
      // props.setServicos([])
    } else {
      props.setItens(props.orcamentosItens.filter(i => i.orcamento === orcamento))
      // props.setServicos(props.orcamentosServicos.filter(i => i.orcamento === orcamento))
    }
  }

  function handleViewClick(orcamento: Orcamento) {
    props.setOrcamento(orcamento)
    props.setItens(props.orcamentosItens.filter(i => i.orcamento === orcamento.id))
    // props.setServicos(props.orcamentosServicos.filter(i => i.orcamento === orcamento.id))
    props.setOpenOrcamento(true)
    props.setAbaOrcamento(AbaOrcamento.Dados)
    props.setAcaoOrcamento(Acao.Visualizar)
  }

  function handleEditClick(orcamento: Orcamento) {
    props.setNovoOrcamento({ ...orcamento })
    props.setNovoItens([...props.orcamentosItens.filter(i => i.orcamento === orcamento.id).map(i => ({ ...i }))])
    props.setOpenNovoOrcamento(true)
    props.setAbaNovoOrcamento(AbaOrcamento.Dados)
    props.setAcaoOrcamento(Acao.Alterar)
  }

  function handleRemoveClick(orcamento: Orcamento) {
    orcamentoAcao = orcamento.id!
    setMensagem('Confirma a exclusão do registro?')
    setOpenMensagemExclusao(true)
  }

  async function excluir() {
    setOpenMensagemExclusao(false)

    try {
      const response = await fetch(`/orcamento/${props.empresa}/${orcamentoAcao}`, { method: 'delete' })

      if (response.status === 200) {
        const result: { result: number } | { message: string } =
          await response.json()

        if ('message' in result)
          setMensagem(`Erro: ${result.message}.`)
        else {
          switch (result.result) {
            case 0:
              setMensagem(`Orçamento ${orcamentoAcao} excluído.`)

              break
            case 1:
              setMensagem(`Orçamento ${orcamentoAcao} não encontrado.`)

              break
            case 2:
              setMensagem(`Não é possível excluir o registro porque o Orçamento já se encontra encerrado`)

              break
            case 3:
              setMensagem(`Não é possível excluir o registro porque existem Ítens vinculados ao Pedido de Venda`)

              break
            case 4:
              setMensagem(`Não é possível excluir o registro porque existem Serviços vinculados ao Pedido de Venda`)

              break
            case 5:
              setMensagem(`Não é possível excluir o registro porque existem Ítens e Serviços vinculados ao Pedido de Venda`)
          }
        }
      } else if (response.status === 404 && response.redirected && response.url.endsWith('/login')) {
        document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
        setMensagem('Você está deslogado.\nFavor relogar.')
      } else
        setMensagem(`Erro ${response.status} - ${response.statusText}.`)
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor. Verifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)
    }

    setOpenMensagem(true)
  }

  function cancelarExclusao() {
    setOpenMensagemExclusao(false)
  }

  async function fecharMensagem() {
    setOpenMensagem(false)

    if (mensagem.startsWith('Orçamento '))
      await search()
  }

  return <Box sx={{
    height: 'calc((100vh - 48px - 48px))',
    width: '100%',
    ...(!props.visible ? { padding: '5px', display: 'none' } : { padding: '5px' })
  }}>
    <Grid container>
      <Grid item xs={4} md={3}>
        <FormControl sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }} variant={IS_MOBILE ? 'standard' : 'outlined'} size='small'>
          <InputLabel id="tipo-data-label">Data</InputLabel>
          <Select
            labelId="tipo-data-label"
            id="tipo-data-select"
            value={tipoData.toString()}
            label="Data"
            onChange={handleChangeTipoData}
            sx={IS_MOBILE ? { p: '9px 0px 2px 0px' } : {}}
          >
            <MenuItem value={0}>Emissão</MenuItem>
            <MenuItem value={1}>Aprovação</MenuItem>
            {/* <MenuItem value={2}>Entrega</MenuItem> */}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} md={3}>
        <LocalizationProvider
          adapterLocale='pt-br'
          // @ts-expect-error
          localeText={ptBRdp.components.MuiLocalizationProvider.defaultProps.localeText}
          dateAdapter={AdapterDayjs}>
          <DatePicker label='Data Inicial' sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
            inputRef={i => {
              if (i)
                i.style.padding = `8.5px 0px 8.5px ${IS_MOBILE ? 0 : 14}px`
            }}
            value={dataInicial}
            onChange={(value, context) => {
              setDataInicial(value)
            }}
            slotProps={IS_MOBILE ? { textField: { variant: 'standard' } } : {}}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={4} md={3}>
        <LocalizationProvider
          adapterLocale='pt-br'
          // @ts-expect-error
          localeText={ptBRdp.components.MuiLocalizationProvider.defaultProps.localeText}
          dateAdapter={AdapterDayjs}>
          <DatePicker label='Data Final' sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
            inputRef={i => {
              if (i)
                i.style.padding = `8.5px 0px 8.5px ${IS_MOBILE ? 0 : 14}px`
            }}
            value={dataFinal}
            onChange={(value, context) => {
              setDataFinal(value)
            }}
            slotProps={IS_MOBILE ? { textField: { variant: 'standard' } } : {}}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Orçamento'
          value={orcamento}
          onChange={event =>
            setOrcamento(event.target.value
              .split('')
              .filter(c => c >= '0' && c <= '9')
              .join('')
              .substring(0, 6)
            )
          }
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
        />
      </Grid>
      <Grid item xs={8} md={9}>
        <FormControl sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }} variant={IS_MOBILE ? 'standard' : 'outlined'} size='small'>
          <InputLabel htmlFor="outlined-adornment-cliente">Cliente</InputLabel>
          {IS_MOBILE
            ? <Input
              id="outlined-adornment-cliente"
              type='text'
              readOnly
              value={cliente.id ? `${cliente.id} - ${cliente.fantasia}` : ' '}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={procurarCliente}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            : <OutlinedInput
              id="outlined-adornment-cliente"
              type='text'
              readOnly
              value={cliente.id ? `${cliente.id} - ${cliente.fantasia}` : ' '}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={procurarCliente}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Cliente"
            />
          }
        </FormControl>
      </Grid>
      <Grid item xs={4} md={1} sx={{ textAlign: 'center' }}>
        <Button sx={{ m: '5px', p: '8px 16px', width: 'calc(100% - 10px)' }} variant='contained' color='secondary' onClick={clear}>
          <ClearIcon />
        </Button>
      </Grid>
      <Grid item xs={4} md={1} sx={{ textAlign: 'center' }}>
        <Button sx={{ m: '5px', p: '8px 16px', width: 'calc(100% - 10px)' }} variant='contained' onClick={search}>
          <SearchIcon />
        </Button>
      </Grid>
      <Grid item xs={4} md={1} sx={{ textAlign: 'center' }}>
        <Button sx={{ m: '5px', p: '7.2px 16px', width: 'calc(100% - 10px)' }} variant='outlined' onClick={add}>
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
    {IS_MOBILE
      ? <Orcamentos
        orcamentos={props.orcamentos}
        formasPagamento={props.formasPagamento}
        clientes={props.todosClientes}
        print={handlePrintClick}
        view={handleViewClick}
        edit={handleEditClick}
        remove={handleRemoveClick}
      />
      : <DataGrid
        sx={{ m: '5px', height: 'calc((100vh - 48px - 48px - 115px) / 2)' }}
        columns={orcamentoColumns}
        rows={props.orcamentos}
        columnHeaderHeight={25}
        rowHeight={25}
        onRowSelectionModelChange={(model, details) => {
          _setOrcamentoSelecionado(model.length === 1 ? model[0] as string : '')
        }}
      />}
    {IS_MOBILE || <DataGrid
      apiRef={apiRef}
      sx={{ m: '5px', height: 'calc((100vh - 48px - 48px - 115px) / 2)' }}
      columns={orcamentosItensColumns}//{tipoItens === TipoItem.Item ? orcamentosItensColumns : orcamentosServicosColumns}
      rows={props.itens}//{tipoItens === TipoItem.Item ? props.itens : props.servicos}
      columnHeaderHeight={25}
      rowHeight={25}
    // slots={{
    //   toolbar: ChangeItensServicos
    // }}
    // slotProps={{ toolbar: { tipoItens, itens: orcamentosItens, servicos: orcamentosServicos } }}
    />}
    {procuraCliente &&
      <PesquisaClientes
        open={procuraCliente} setOpen={setProcuraCliente}
        clientes={clientes} setCliente={setCliente}
        formasPagamento={props.formasPagamento} setFormaPagamento={setFormaPagamento}
        transportadoras={props.transportadoras} setTransportadora={setTransportadora}
        recarregar={recarregarClientes}
        abas={props.abas}
      />
    }
    <Loading open={openLoading} setOpen={setOpenLoading} load={load} />
    <Mensagem titulo='Excluir Orçamento' mensagem={mensagem} open={openMensagemExclusao} ok={excluir} cancelar={cancelarExclusao} id='excluir-orcamento' />
    <Mensagem titulo='Orçamento' mensagem={mensagem} open={openMensagem} ok={fecharMensagem} id='consulta-orcamento' />
  </Box>
}

// function ChangeItensServicos({ tipoItens, itens, servicos }: { tipoItens: TipoItem, itens(): void, servicos(): void }) {
//   return <GridToolbarContainer>
//     <Button
//       color="primary"
//       variant={tipoItens === TipoItem.Item ? 'contained' : 'outlined'}
//       onClick={() => { itens() }}
//     >
//       Itens Mercadoria/Produto
//     </Button>
//     <Button
//       color="primary"
//       variant={tipoItens === TipoItem.Servico ? 'contained' : 'outlined'}
//       onClick={() => { servicos() }}
//     >
//       Serviços Executados
//     </Button>
//   </GridToolbarContainer>
// }