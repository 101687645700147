{
  "name": "@order-manager/client",
  "version": "1.6",
  "private": true,
  "description": "",
  "main": "index.js",
  "scripts": {
    "build": "esbuild --bundle --outfile=../www/public/bundle.js --sourcemap --charset=utf8 --jsx=automatic --minify src/index.tsx",
    "watch": "nodemon -w src -e ts,tsx -x 'esbuild --bundle --outfile=../www/public/bundle.js --sourcemap --charset=utf8 --jsx=automatic --minify src/index.tsx'"
  },
  "keywords": [],
  "author": "",
  "dependencies": {
    "@mui/icons-material": "^5.15.12",
    "@mui/lab": "^5.0.0-alpha.167",
    "@mui/material": "^5.15.12",
    "@mui/x-data-grid": "^6.19.6",
    "@mui/x-date-pickers": "^6.19.6",
    "@emotion/react": "^11.11.4",
    "@emotion/styled": "^11.11.0",
    "dayjs": "^1.11.10",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-number-format": "^5.3.3"
  },
  "devDependencies": {
    "@types/react-dom": "^18.2.21",
    "esbuild": "^0.20.1",
    "nodemon": "^3.1.0"
  }
}