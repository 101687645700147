import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { FormControl, InputLabel, Input, InputAdornment, IconButton, OutlinedInput } from '@mui/material'

export function Busca(props: {
  visible?: boolean
  disabled: boolean
  id: string
  label: string
  value: string | null
  onClick(): void
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  required?: boolean
  reset?(): void
}) {
  return <FormControl
    sx={{
      m: '5px',
      width: 'calc(100% - 10px)',
      ...(props.visible === false ? { display: 'none' } : {})
    }}
    variant={IS_MOBILE ? 'standard' : 'outlined'}
    size='small'
    disabled={props.disabled}
  >
    <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
    {IS_MOBILE
      ? <Input
        id={props.id}
        type='text'
        readOnly={!props.onChange}
        value={!!props.onChange ? props.value : props.value ?? ' '}
        onChange={props.onChange!}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              onClick={props.onClick}
              edge='end'
              disabled={props.disabled}
            >
              <SearchIcon />
            </IconButton>
            {props.reset &&
              <IconButton
                onClick={props.reset}
                edge='end'
                disabled={props.disabled}
              >
                <ClearIcon />
              </IconButton>
            }
          </InputAdornment>
        }
        disabled={props.disabled}
        error={!!props.required && (!props.value || props.value === ' ')}
      />
      : <OutlinedInput
        id={props.id}
        type='text'
        readOnly={!props.onChange}
        value={!!props.onChange ? props.value : props.value ?? ' '}
        onChange={props.onChange!}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              onClick={props.onClick}
              edge='end'
              disabled={props.disabled}
            >
              <SearchIcon />
            </IconButton>
            {props.reset &&
              <IconButton
                onClick={props.reset}
                edge='end'
                disabled={props.disabled}
              >
                <ClearIcon />
              </IconButton>
            }
          </InputAdornment>
        }
        label={props.label}
        disabled={props.disabled}
        error={!!props.required && (!props.value || props.value === ' ')}
      />
    }
  </FormControl>
}