import { formatDate } from '../utils'

export interface Orcamento {
  gerado: boolean | null
  id: string | null
  data: string | null
  aprovado: boolean | null
  dataAprovacao: string | null
  codigoCliente: number | null
  nomeCliente: string | null
  valorServico: number | null
  valorBruto: number | null
  valorFrete: number | null
  valorEnc: number | null
  valorDesconto: number | null
  valorTotal: number | null
  quantidadeItens: number
  quantidadeServicos: number
  totalIcms: number | null
  totalIcmsST: number | null
  totalPis: number | null
  totalCofins: number | null
  totalIpi: number | null
  sexo: string | null
  modalidade: string | null
  validade: string | null
  entrega: string | null
  contato: string | null
  localEntrega: number | null
  formaPagamento: string | null
  frete: string | null
  vendedor: number | null
  // vendedor2: number | null
  // vendedor3: number | null
  colocacao: string | null
  acabamento: string | null
  servico: boolean | null
  obs: string | null
  percEnc: number | null
  valorMoeda: number | null
  usuario: string | null
  percDesconto: number | null
  itensPedido: number
  servicosPedido: number
  dataNascimento: string | null
}

export interface OrcamentoItem {
  orcamento: string
  id: number
  bxEstoque: boolean | null
  codigoProduto: string | null
  codigoAnalitico: string | null
  codigoAnalitico2: string | null
  nomeProduto: string | null
  quantidade: number | null
  unidade: string | null
  valorUnitario: number | null
  descontoUnitario: number | null
  unitarioLiquido: number | null
  valorProdutos: number | null
  valorIPI: number | null
  valorFrete: number | null
  despesasAcessorias: number | null
  descontoItem: number | null
  valorTotal: number | null
  percICMS: number | null
  percRedICMS: number | null
  valorICMS: number | null
  percIVA: number | null
  percIcmsST: number | null
  valorIcmsST: number | null
  percPIS: number | null
  valorPIS: number | null
  percCOFINS: number | null
  valorCOFINS: number | null
  instalado: 'S' | 'N' | null
  quantidadeEfetivar: number | null
  percDesc: number | null
  percIPI: number | null
  prazoEntrega: string | null
  largura: number | null
  comprimento: number | null
  altura: number | null
  valorMoeda: number | null
}

export interface OrcamentoServico {
  orcamento: string
  id: number
  descricao: string | null
  valor: number | null
}

export function getDefaultOrcamento(): Orcamento {
  return {
    gerado: null,
    id: null,
    data: formatDate(new Date, true),
    aprovado: null,
    dataAprovacao: null,
    codigoCliente: null,
    nomeCliente: null,
    valorServico: null,
    valorBruto: null,
    valorFrete: null,
    valorEnc: null,
    valorDesconto: null,
    valorTotal: null,
    quantidadeItens: 0,
    quantidadeServicos: 0,
    totalIcms: null,
    totalIcmsST: null,
    totalPis: null,
    totalCofins: null,
    totalIpi: null,
    sexo: null,
    modalidade: null,
    validade: null,
    entrega: null,
    contato: null,
    localEntrega: null,
    formaPagamento: null,
    frete: null,
    vendedor: null,
    // vendedor2: null,
    // vendedor3: null,
    colocacao: null,
    acabamento: null,
    servico: null,
    obs: null,
    percEnc: null,
    valorMoeda: null,
    usuario: null,
    percDesconto: null,
    itensPedido: 0,
    servicosPedido: 0,
    dataNascimento: null
  }
}

export function getDefaultOrcamentoItem(): OrcamentoItem {
  return {
    orcamento: '',
    id: 1,
    bxEstoque: null,
    codigoProduto: null,
    codigoAnalitico: null,
    codigoAnalitico2: null,
    nomeProduto: null,
    quantidade: null,
    unidade: null,
    valorUnitario: null,
    descontoUnitario: null,
    unitarioLiquido: null,
    valorProdutos: null,
    valorIPI: null,
    valorFrete: null,
    despesasAcessorias: null,
    descontoItem: null,
    valorTotal: null,
    percICMS: null,
    percRedICMS: null,
    valorICMS: null,
    percIVA: null,
    percIcmsST: null,
    valorIcmsST: null,
    percPIS: null,
    valorPIS: null,
    percCOFINS: null,
    valorCOFINS: null,
    instalado: null,
    quantidadeEfetivar: null,
    percDesc: null,
    percIPI: null,
    prazoEntrega: null,
    largura: null,
    comprimento: null,
    altura: null,
    valorMoeda: null
  }
}

export function getDefaultOrcamentoServico(): OrcamentoServico {
  return {
    orcamento: '',
    id: 0,
    descricao: null,
    valor: null
  }
}