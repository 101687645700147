import { verifyLoggedAndSet } from '../utils'

export interface FormaPagamento {
  id: string
  nome: string | null
  fatorFinanceiro: number | null
  ipi: string | null
  arredondaParcela: string | null
  tipoPagamento: string | null
  indicacaoPagamento: number | null
}

export function getDefaultFormaPagamento(): FormaPagamento {
  return {
    id: '',
    nome: null,
    fatorFinanceiro: null,
    ipi: null,
    arredondaParcela: null,
    tipoPagamento: null,
    indicacaoPagamento: null
  }
}

export async function getFormasPagamento(setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: FormaPagamento[]) => void) {
  return await verifyLoggedAndSet('/formas-pagamento', setMensagem, setOpenMensagem, setter)
}