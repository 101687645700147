import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import type { Tipo } from '../model/Tipo'

export function Classificacao(props: {
  disabled: boolean
  tipos: Tipo[]
  tipo: number | null
  setTipo?(value: number | null): void
  required?: boolean
}) {
  function handleChangeTipo(event: SelectChangeEvent) {
    const value = event.target.value
    props.setTipo?.(value ? +value : null)
  }

  return <FormControl sx={{
    m: '5px',
    width: 'calc(100% - 10px)'
  }} variant={IS_MOBILE ? 'standard' : 'outlined'} size='small'>
    <InputLabel id="classificacao-label">Classificação</InputLabel>
    <Select
      labelId="classificacao-label"
      id="classificacao-select"
      value={props.tipo?.toString() ?? ''}
      label="Classificação"
      onChange={handleChangeTipo}
      disabled={props.disabled}
      error={!!props.required && !props.tipo}
    >
      <MenuItem key={0} value=''></MenuItem>
      {props.tipos.map(t =>
        <MenuItem key={t.id} value={t.id.toString()}>{t.descricao}</MenuItem>
      )}
    </Select>
  </FormControl>
}