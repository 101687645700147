import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/CheckCircle'
import SearchIcon from '@mui/icons-material/Search'
import CachedIcon from '@mui/icons-material/Cached'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import { DataGrid, type GridColDef } from '@mui/x-data-grid'
import { type Destinatario } from './model/Destinatario'
import { Dialog, AppBar, IconButton, Toolbar, Typography, Grid, TextField, List, ListItem, ListItemText } from '@mui/material'
import { getDefaultFormaPagamento, type FormaPagamento } from './model/FormaPagamento'
import { getDefaultTransportadora, type Transportadora } from './model/Transportadora'
import { formatCnpj, formatCpf, formatCpfCnpj } from './utils'
import { Search, SearchIconWrapper, StyledInputBase } from './search'
import { Transition } from './transition'
import { ItemMenu } from './item-menu'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Código',
    width: 60,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'fantasia',
    headerName: 'Fantasia',
    width: 250
  },
  {
    field: 'razaoSocial',
    headerName: 'Razão Social',
    width: 400
  },
  {
    field: 'cpfCnpj',
    headerName: 'CPF/CNPJ',
    width: 160,
    valueFormatter(params) {
      return params.value ? formatCpfCnpj(params.value) : ''
    }
  },
  {
    field: 'municipio',
    headerName: 'Município',
    width: 200
  },
  {
    field: 'uf',
    headerName: 'UF',
    width: 20
  },
  {
    field: 'representanteNome',
    headerName: 'Representante',
    width: 200
  }
]

export default function PesquisaClientes(props: {
  open: boolean
  setOpen(value: boolean): void
  clientes: Destinatario[]
  setCliente(value: Destinatario): void
  formasPagamento?: FormaPagamento[]
  setFormaPagamento?(value: FormaPagamento): void
  transportadoras?: Transportadora[]
  setTransportadora?(value: Transportadora): void
  recarregar(): void
  novoCliente?: (() => void) | undefined
  abas: ItemMenu[]
}) {
  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState(IS_MOBILE ? [] : [...props.clientes])

  useEffect(() => {
    // @ts-expect-error
    onChangeFilter({ target: { value: filter } })
  }, [props.clientes])

  function onChangeFilter(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let filter = event.target.value
    setFilter(filter)
    filter = filter.toLowerCase()

    if (filter.trim().length > 0)
      setFiltered(props.clientes.filter(cliente =>
        cliente.id.toString().includes(filter)
        || cliente.fantasia?.toLowerCase().includes(filter)
        || cliente.razaoSocial?.toLowerCase().includes(filter)
        || cliente.cpfCnpj?.includes(filter)
        || cliente.municipio?.toLowerCase().includes(filter)
        || cliente.uf?.toLowerCase().includes(filter)
        || cliente.representanteNome?.toLowerCase().includes(filter)
      ))
    else
      setFiltered(IS_MOBILE ? [] : [...props.clientes])
  }

  function handleClose() {
    props.setOpen(false)
  }

  function selectCliente(cliente: Destinatario) {
    props.setCliente(cliente)

    if (props.formasPagamento && props.setFormaPagamento)
      if (cliente.formaPagamento)
        props.setFormaPagamento(props.formasPagamento.find(f => f.id === cliente.formaPagamento) ?? getDefaultFormaPagamento())

    if (props.transportadoras && props.setTransportadora)
      if (cliente.numeroTransportadora)
        props.setTransportadora(props.transportadoras.find(f => f.id === cliente.numeroTransportadora) ?? getDefaultTransportadora())
      else
        props.setTransportadora(props.transportadoras.find(f => f.id === '000001') ?? getDefaultTransportadora())

    props.setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: IS_MOBILE ? 'fixed' : 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Pesquisa de Clientes
          </Typography>
          {props.novoCliente &&
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={props.novoCliente}
              disabled={props.abas.includes(ItemMenu.NovoCliente) || props.abas.includes(ItemMenu.AlterarCliente)}
            >
              <AddIcon />
            </IconButton>
          }
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => props.recarregar()}
          >
            <CachedIcon />
          </IconButton>
          {IS_MOBILE ||
            <>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  autoFocus={!IS_MOBILE}
                  placeholder="Filtro..."
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={onChangeFilter}
                />
              </Search>
              <Typography variant="subtitle1" component="div">
                Clique duplo para selecionar
              </Typography>
            </>
          }
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          ...(IS_MOBILE ? {} : { height: 'calc(100vh - 48px)' }),
          ...(IS_MOBILE ? { width: 'calc(100% - 10px)' } : { width: '100%' })
        }}
      >
        {IS_MOBILE && <>
          <div style={{ height: IS_MOBILE ? '48px' : '10px' }}></div>
          <Grid container sx={{
            m: '5px',
            p: '10px'
          }}>
            <Grid item xs={12}>
              <TextField variant='standard' size='small' label='Filtro' value={filter}
                sx={{ width: '100%' }}
                onChange={event => {
                  let filter = event.target.value
                  setFilter(filter)
                  filter = filter.toLowerCase()

                  if (filter.trim().length > 0)
                    setFiltered(props.clientes.filter(cliente =>
                      cliente.fantasia?.toLowerCase().includes(filter)
                      || cliente.razaoSocial?.toLowerCase().includes(filter)
                      || cliente.cpfCnpj?.includes(filter)
                      || cliente.cpf?.includes(filter)
                      || cliente.endereco?.toLowerCase().includes(filter)
                      || cliente.municipio?.toLowerCase().includes(filter)
                    ).slice(0, filter.length >= 3 ? undefined : 100))
                  else
                    setFiltered([])
                }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption'>Nome fantasia, razão social, CNPJ, CPF, endereço ou município</Typography>
            </Grid>
          </Grid>
        </>}
        {IS_MOBILE
          ? <List>
            {filtered.map((cliente, index) =>
              <>
                <ListItem sx={{
                  backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
                  m: '6px 0px 0px 5px',
                  borderRadius: '6px'
                }}>
                  <ListItemText
                    primary={<Grid container>
                      <Grid item xs={11}>
                        {`${cliente.id} - ${cliente.fantasia}`}
                      </Grid>
                      <Grid item xs={1} textAlign='center'>
                        <IconButton color='primary' sx={{ paddingTop: '0px' }} onClick={() => {
                          selectCliente(cliente)
                        }}><CheckIcon /></IconButton>
                      </Grid>
                    </Grid>}
                    secondary={<Grid container>
                      {
                        [
                          (cliente.cnpj && ['CNPJ', formatCnpj(cliente.cnpj)]) ?? (cliente.cpf && ['CPF', formatCpf(cliente.cpf)]),
                          cliente.ie && ['Inscr. Estadual', cliente.ie],
                          cliente.email && ['E-mail', cliente.email],
                          cliente.endereco && ['Endereço', `${cliente.endereco}${cliente.numero !== null ? `, ${cliente.numero}` : ''}`],
                          cliente.municipio && ['Município', cliente.municipio],
                          cliente.uf && ['UF', cliente.uf],
                          cliente.situacao && ['Situação', cliente.situacao],
                          cliente.formaPagamento && ['Forma de Pagamento', `${cliente.formaPagamento}${cliente.formaPagamentoNome ? ` - ${cliente.formaPagamentoNome}` : ''}`],
                          cliente.representante && ['Representante', cliente.representante]
                        ].filter((i): i is string[] => !!i).map(i =>
                          <>
                            <Grid item xs={6}>
                              <Typography variant='overline'>{i[0]}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant='subtitle2' marginTop='4px' sx={{ width: 'calc(50vw - 15px)', overflow: 'hidden', textOverflow: 'ellipsis' }}>{i[1]}</Typography>
                            </Grid>
                          </>
                        )
                      }
                    </Grid>
                    }
                  />
                </ListItem>
              </>
            )}
          </List>
          : <DataGrid
            rows={filtered}
            columns={columns}
            columnHeaderHeight={25}
            rowHeight={25}
            onRowDoubleClick={(params, event, details) =>
              selectCliente(params.row as Destinatario)
            }
          />
        }
      </Box>
    </Dialog>
  )
}