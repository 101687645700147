import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import type { Vendedor } from './model/Vendedor'
import { getDefaultDestinatario, type Destinatario, getDestinatarios } from './model/Destinatario'
import PesquisaClientes from './pesquisa-clientes'
import type { Perfil } from './login'
import { getDefaultFormaPagamento, type FormaPagamento } from './model/FormaPagamento'
import { getDefaultTransportadora, type Transportadora } from './model/Transportadora'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR as ptBRdp } from '@mui/x-date-pickers/locales/ptBR'
import dayjs from 'dayjs'
import type { Produto } from './model/Produto'
import PedidoVendaItens, { getItens, idItem, resetItens } from './pedido-venda-itens'
import PesquisaProdutos from './pesquisa-produtos'
import { ItemMenu } from './item-menu'
import Mensagem from './mensagem'
import { formatCnpj, formatCurrency, formatDate, removeDiacritcs, verifyLoggedAndSet } from './utils'
import PesquisaRepresentantes from './pesquisa-representantes'
import { getDefaultRepresentante, type Representante } from './model/Representante'
import { PedidoItens } from './pedido-itens'
import type { NewPedidoItem } from './model/NewPedidoItem'
import SelecaoProdutosMobile from './selecao-produtos-mobile'
import { Loading } from './loading'

export default function PedidoVenda({
  empresa,
  nomeEmpresa,
  perfil,
  vendedor,
  visible,
  todosClientes,
  setTodosClientes,
  formasPagamento,
  setFormasPagamento,
  transportadoras,
  setTransportadoras,
  produtos,
  setProdutos,
  removeAba,
  recarregaPedidos,
  representantes,
  setRepresentantes,
  abas
}: {
  empresa: string
  nomeEmpresa: string
  perfil: keyof typeof Perfil
  vendedor: Vendedor
  visible: boolean
  todosClientes: Destinatario[]
  setTodosClientes(value: Destinatario[]): void
  formasPagamento: FormaPagamento[]
  setFormasPagamento(value: FormaPagamento[]): void
  transportadoras: Transportadora[]
  setTransportadoras(value: Transportadora[]): void
  produtos: Produto[]
  setProdutos(value: Produto[]): void
  removeAba(aba: ItemMenu): void
  recarregaPedidos(): void
  representantes: Representante[]
  setRepresentantes(value: Representante[]): void
  abas: ItemMenu[]
}) {
  const [loaded, setLoaded] = useState(false)
  const [clientes, setClientes] = useState(todosClientes)
  const [cliente, setCliente] = useState(getDefaultDestinatario())
  const [procuraCliente, setProcuraCliente] = useState(false)
  const [representante, setRepresentante] = useState(perfil === 'representante' ? { id: vendedor.codigo, nome: vendedor.nome, supervisor: vendedor.supervisor } : getDefaultRepresentante())
  const [procuraRepresentante, setProcuraRepresentante] = useState(false)
  const [formaPagamento, setFormaPagamento] = useState(getDefaultFormaPagamento())
  const [transportadora, setTransportadora] = useState(getDefaultTransportadora())
  const dataPedido = new Date
  const [dataEntrega, setDataEntrega] = useState(dayjs(dataPedido) as dayjs.Dayjs | null)
  const [pedidoCliente, setPedidoCliente] = useState('')
  const [observacao, setObservacao] = useState('')
  const [procuraProduto, setProcuraProduto] = useState(false)
  const [_gridChanged, setGridChanged] = useState(false)
  const [mensagem, setMensagem] = useState('')
  const [openMensagem, setOpenMensagem] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [itens, setItens] = useState([] as NewPedidoItem[])
  const [totalPedido, setTotalPedido] = useState(0)
  const [openLoading, setOpenLoading] = useState(true)
  const [fecharPedido, setFecharPedido] = useState(false)

  function removeItem(item: NewPedidoItem) {
    setItens(itens.filter(i => i !== item))
  }

  function _setTodosClientes(clientes: Destinatario[]) {
    setTodosClientes(clientes)
    setClientes(clientes)
  }

  async function recarregarClientes() {
    await getDestinatarios(empresa, perfil, vendedor.codigo, setMensagem, setOpenMensagem, _setTodosClientes)
  }

  async function load() {
    setLoaded(true)

    if (!(todosClientes.length || await getDestinatarios(empresa, perfil, vendedor.codigo, setMensagem, setOpenMensagem, _setTodosClientes, undefined, () => setFecharPedido(true))))
      return

    if (!(formasPagamento.length || await verifyLoggedAndSet('/formas-pagamento', setMensagem, setOpenMensagem, setFormasPagamento, () => setFecharPedido(true))))
      return

    if (!(transportadoras.length || await verifyLoggedAndSet(`/transportadoras/${empresa}`, setMensagem, setOpenMensagem, setTransportadoras, () => setFecharPedido(true))))
      return

    if (!(produtos.length || await verifyLoggedAndSet(`/produtos/${empresa}`, setMensagem, setOpenMensagem, setProdutos, () => setFecharPedido(true))))
      return
  }

  if (!loaded)
    load()

  function procurarCliente() {
    setProcuraCliente(true)
  }

  function procurarRepresentante() {
    setProcuraRepresentante(true)
  }

  function selectProduto(produto: Produto) {
    if (IS_MOBILE) {
      const id = (itens.at(-1)?.id ?? 0) + 1

      const item: NewPedidoItem = {
        id,
        nome: produto.descricao,
        produto: produto.id,
        quantidade: 1,
        unidadePedido: produto.unidadePedido,
        unidade: produto.unidade,
        valorUnitario: produto.precoVenda ?? 0,
        valorTotal: produto.precoUnitario,
        volume: produto.quantidadeUnidade ?? 1,
        volumeTotalUnidades: produto.quantidadeUnidade ?? 1,
        quantidadeStr: '1',
        valorUnitarioStr: (produto.precoVenda ?? 0).toFixed(2).replace('.', ','),
        valorUnitarioProduto: produto.precoVenda ?? 0,
        valorTotalProduto: produto.precoUnitario
      }

      setItens([...itens, item])
    } else {
      const item = getItens().find(i => i.id === idItem)!
      item.produto = produto.id
      item.nome = produto.descricao
      item.volumeTotalUnidades = item.volume = produto.quantidadeUnidade ?? 1
      item.unidade = produto.unidade
      item.quantidade = 1
      item.valorUnitario = produto.precoVenda ?? 1
      item.valorTotal = produto.precoUnitario
    }
  }

  function selectRepresentante(representante: Representante) {
    setRepresentante(representante)
    setClientes(todosClientes.filter(c => c.representante ? Number.parseInt(c.representante) === representante.id : false))
    setCliente(getDefaultDestinatario())
    setFormaPagamento(getDefaultFormaPagamento())
    setTransportadora(getDefaultTransportadora())
  }

  function podeSalvar() {
    const _itens = IS_MOBILE ? itens : getItens()
    const total = _itens.reduce((p, c) => p + c.valorTotal, 0)

    if (totalPedido !== total)
      setTotalPedido(total)

    return !!(
      representante.id
      && cliente.id
      && formaPagamento.id
      && dataEntrega && dataEntrega.isValid()
      && _itens.length
      && _itens.every(i => {
        return !!(i.nome && (i.quantidade > 0) && (i.valorUnitario > 0) && (i.valorTotal > 0))
      })
    )
  }

  async function salvar() {
    setDisabled(true)

    const headers = new Headers
    const _itens = IS_MOBILE ? itens : getItens()

    const totalPedido = _itens.reduce((p, c) => p + c.valorTotal, 0).toFixed(4)

    headers.append('pedido', JSON.stringify({
      cliente: cliente.id,
      dataPedido: formatDate(dataPedido, true),
      usuario: representante.id,
      formaPagamento: formaPagamento.id,
      observacao,
      dataEntrega: formatDate(dataEntrega!, true),
      totalPedido,
      pedidoCliente,
      supervisor: representante.supervisor,
      transportadora: transportadora.id,
      tipoPed: IS_MOBILE ? 0 : 1,
      itens: _itens.map(i => ({
        produto: i.produto,
        quantidade: i.quantidade,
        valorUnitario: i.valorUnitario,
        volume: i.volume
      }))
    }))

    try {
      const response = await fetch(`/salvar-pedido/${empresa}`, {
        headers,
        method: 'post'
      })

      if (response.status === 200) {
        const result: { ID_PEDIDO: number, limiteExcedido: boolean } | { message: string } =
          await response.json()

        if ('message' in result)
          setMensagem(`Erro: ${result.message}.`)
        else
          setMensagem(`Pedido ${result.ID_PEDIDO} salvo.${result.limiteExcedido ? `\nO limite de crédido do Cliente foi excedido.\nEstará sujeito a liberação do(a) ${nomeEmpresa}.` : ''}`)
      } else if (response.status === 404 && response.redirected && response.url.endsWith('/login')) {
        document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
        setMensagem('Você está deslogado.\nFavor relogar.')
        setOpenMensagem(true)
      } else
        setMensagem(`Erro ${response.status} - ${response.statusText}.`)
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor. Verifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)
    }

    setOpenMensagem(true)
  }

  function gridChanged() {
    setGridChanged(!_gridChanged)
  }

  function fechaPedido() {
    resetItens()
    removeAba(ItemMenu.NovoPedido)
  }

  function fechaMensagem() {
    setDisabled(false)
    setOpenMensagem(false)

    if (mensagem.startsWith('Pedido ')) {
      recarregaPedidos()
      fechaPedido()
    } else if (fecharPedido)
      fechaPedido()
  }

  return (
    <div style={!visible ? { padding: '5px', display: 'none' } : { padding: '5px' }}>
      <Grid container>
        {perfil !== 'representante' &&
          <>
            <Grid item xs={12} md={5}>
              <FormControl sx={{
                m: '5px',
                width: 'calc(100% - 10px)'
              }} variant={IS_MOBILE ? 'standard' : 'outlined'} size='small'>
                <InputLabel htmlFor="outlined-adornment-representante">Representante</InputLabel>
                {IS_MOBILE
                  ? <Input
                    id="outlined-adornment-representante"
                    type='text'
                    readOnly
                    value={representante.id ? `${representante.id} - ${representante.nome}` : ' '}
                    error={!representante.id}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={procurarRepresentante}
                          edge="end"
                          disabled={disabled}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    disabled={disabled}
                  />
                  : <OutlinedInput
                    id="outlined-adornment-representante"
                    type='text'
                    readOnly
                    value={representante.id ? `${representante.id} - ${representante.nome}` : ' '}
                    error={!representante.id}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={procurarRepresentante}
                          edge="end"
                          disabled={disabled}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Representante"
                    disabled={disabled}
                  />
                }
              </FormControl>
            </Grid>
            <Grid item xs={0} md={7}></Grid>
          </>
        }
        <Grid item xs={12} md={5}>
          <FormControl sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }} variant={IS_MOBILE ? 'standard' : 'outlined'} size='small'>
            <InputLabel htmlFor="outlined-adornment-cliente">Cliente</InputLabel>
            {IS_MOBILE
              ? <Input
                id="outlined-adornment-cliente"
                type='text'
                readOnly
                value={cliente.id ? `${cliente.id} - ${cliente.fantasia}` : ' '}
                error={!cliente.id}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={procurarCliente}
                      edge="end"
                      disabled={disabled || !representante.id}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              : <OutlinedInput
                id="outlined-adornment-cliente"
                type='text'
                readOnly
                value={cliente.id ? `${cliente.id} - ${cliente.fantasia}` : ' '}
                error={!cliente.id}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={procurarCliente}
                      edge="end"
                      disabled={disabled || !representante.id}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Cliente"
              />
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label='CNPJ'
            disabled
            value={cliente.cnpj ? formatCnpj(cliente.cnpj) : ' '}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label='Inscr. Estadual'
            disabled
            value={cliente.ie || ' '}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label='Município'
            disabled
            value={cliente.municipio || ' '}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField
            label='UF'
            disabled
            value={cliente.uf || ' '}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label='Data Pedido'
            disabled
            value={formatDate(dataPedido)}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label='Forma Pagamento'
            disabled
            value={formaPagamento.id ? `${formaPagamento.id} - ${formaPagamento.nome}` : ''}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            error={!formaPagamento.id}
            size='small'
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            label='Transportadora'
            disabled
            value={transportadora.id ? `${transportadora.id} - ${transportadora.nome}` : ''}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <LocalizationProvider
            adapterLocale='pt-br'
            // @ts-expect-error
            localeText={ptBRdp.components.MuiLocalizationProvider.defaultProps.localeText}
            dateAdapter={AdapterDayjs}>
            <DatePicker label='Data Entrega' sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
              inputRef={i => {
                if (i)
                  i.style.padding = '8.5px 0px 8.5px 14px'
              }}
              value={dataEntrega}
              onChange={(value, context) => {
                setDataEntrega(value)
              }}
              disabled={disabled}
              slotProps={IS_MOBILE ? { textField: { variant: 'standard' } } : {}}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField
            label='Identificação do Pedido do Cliente'
            value={pedidoCliente}
            onChange={event => setPedidoCliente(removeDiacritcs(event.target.value).substring(0, 20))}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Observações'
            value={observacao}
            onChange={event => setObservacao(removeDiacritcs(event.target.value))}
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
            multiline
            rows={2}
            disabled={disabled}
          />
        </Grid>
        {IS_MOBILE
          ? <PedidoItens
            setProcuraProduto={setProcuraProduto}
            disabled={disabled}
            itens={itens}
            setItens={setItens}
            removeItem={removeItem}
          />
          : <Grid item md={12}>
            <PedidoVendaItens perfil={perfil} produtos={produtos} setProcuraProduto={setProcuraProduto} gridChanged={gridChanged} disabled={disabled} />
          </Grid>
        }
        <Grid item xs={0} md={8} sx={IS_MOBILE ? {} : { paddingLeft: '6px', paddingTop: '10px' }}>
          {IS_MOBILE || <Typography variant='button'>TOTAL: R$ {formatCurrency(totalPedido)}</Typography>}
        </Grid>
        <Grid item xs={6} md={2} sx={{ textAlign: 'center' }}>
          <Button sx={{ m: '5px' }} disabled={!podeSalvar() || disabled} onClick={async () => await salvar()}>Salvar Pedido</Button>
        </Grid>
        <Grid item xs={6} md={2} sx={{ textAlign: 'center' }}>
          <Button sx={{ m: '5px' }} onClick={() => {
            fechaPedido()
          }} disabled={disabled}>Cancelar</Button>
        </Grid>
      </Grid>
      {procuraCliente &&
        <PesquisaClientes
          open={procuraCliente} setOpen={setProcuraCliente}
          clientes={clientes} setCliente={setCliente}
          formasPagamento={formasPagamento} setFormaPagamento={setFormaPagamento}
          transportadoras={transportadoras} setTransportadora={setTransportadora}
          recarregar={recarregarClientes}
          abas={abas}
        />
      }
      {procuraProduto && (
        IS_MOBILE
          ? <SelecaoProdutosMobile empresa={empresa} open={procuraProduto} setOpen={setProcuraProduto} produtos={produtos} setProdutos={setProdutos} setProduto={selectProduto} setMensagem={setMensagem} setOpenMensagem={setOpenMensagem} itens={itens} setItens={setItens} removeItem={removeItem} />
          : <PesquisaProdutos empresa={empresa} open={procuraProduto} setOpen={setProcuraProduto} produtos={produtos} setProdutos={setProdutos} setProduto={selectProduto} setMensagem={setMensagem} setOpenMensagem={setOpenMensagem} />
      )}
      {procuraRepresentante &&
        <PesquisaRepresentantes
          empresa={empresa}
          perfil={perfil}
          id={vendedor.codigo}
          open={procuraRepresentante} setOpen={setProcuraRepresentante}
          representantes={representantes} setRepresentantes={setRepresentantes}
          setRepresentante={selectRepresentante}
          setMensagem={setMensagem} setOpenMensagem={setOpenMensagem}
        />
      }
      <Mensagem titulo='Novo Pedido' mensagem={mensagem} open={openMensagem} ok={fechaMensagem} id='novo-pedido' />
      <Loading open={openLoading} setOpen={setOpenLoading} load={load} />
    </div>
  )
}