import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'
import ListIcon from '@mui/icons-material/ViewList'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import SettingsIcon from '@mui/icons-material/Settings'
import { ItemMenu } from './item-menu'
import { ListItemAvatar, Avatar } from '@mui/material'
import type { Vendedor } from './model/Vendedor'
import { IDTela, type Configuracoes } from './model/Configuracao'
import type { Perfil } from './login'

export default function Menu({ setMenu, menus, logout, vendedor, configuracoes, perfil }: {
  setMenu(value: ItemMenu): void
  menus: ItemMenu[]
  logout(): void
  vendedor: Vendedor
  configuracoes: Configuracoes
  perfil: keyof typeof Perfil
}) {
  return (
    <Box sx={{ height: IS_MOBILE ? '100vh' : 'calc(100vh - 48px)', backgroundColor: '#eee', maxWidth: 'calc(100vw - 30px)' }}>
      {IS_MOBILE && <>
        <nav aria-label="usuário">
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={vendedor.nome ?? ''} secondary={
                `${vendedor.perfil[0].toUpperCase()}${vendedor.perfil.slice(1)} ${vendedor.codigo}`
              } />
            </ListItem>
          </List>
        </nav>
        <Divider />
      </>}
      <nav aria-label="menu">
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setMenu(ItemMenu.Clientes)}>
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary='Clientes' />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setMenu(ItemMenu.Produtos)}>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary="Produtos" />
            </ListItemButton>
          </ListItem>
          {configuracoes.telas.includes(IDTela.Orcamentos) && <ListItem disablePadding>
            <ListItemButton onClick={() => setMenu(ItemMenu.Orcamentos)}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Orçamentos" />
            </ListItemButton>
          </ListItem>}
          {IS_MOBILE && menus.includes(ItemMenu.NovoOrcamento) &&
            <ListItem disablePadding>
              <ListItemButton onClick={() => setMenu(ItemMenu.NovoOrcamento)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Novo Orçamento" />
              </ListItemButton>
            </ListItem>
          }
          {configuracoes.telas.includes(IDTela.Pedidos) && <ListItem disablePadding>
            <ListItemButton onClick={() => setMenu(ItemMenu.Pedidos)}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Pedidos" />
            </ListItemButton>
          </ListItem>}
          {IS_MOBILE && menus.includes(ItemMenu.NovoPedido) &&
            <ListItem disablePadding>
              <ListItemButton onClick={() => setMenu(ItemMenu.NovoPedido)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Novo Pedido" />
              </ListItemButton>
            </ListItem>
          }
          {perfil === 'gerencia' && configuracoes.telaConfiguracao &&
            <ListItem disablePadding>
              <ListItemButton onClick={() => setMenu(ItemMenu.Configuracao)}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configuração" />
              </ListItemButton>
            </ListItem>
          }
        </List>
      </nav>
      {
        IS_MOBILE && <>
          <Divider />
          <nav aria-label="logout">
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => logout()}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Deslogar" />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </>
      }
    </Box >
  )
}