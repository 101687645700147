import { codigoEstadoToUf, verifyLoggedAndSet } from '../utils'

export interface Municipio {
  id: number
  nome: string | null
  uf: string | null
}

export async function getMunicipios(setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: Municipio[]) => void) {
  function _setter(value: Municipio[]) {
    value = value.map(i => ({ ...i, uf: i.uf !== null ? codigoEstadoToUf(+i.uf) : null }))
    setter(value)
  }

  return verifyLoggedAndSet('/municipios', setMensagem, setOpenMensagem, _setter)
}