import React from 'react'
import { Grid, TextField, FormControlLabel, Checkbox } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR as ptBRdp } from '@mui/x-date-pickers/locales/ptBR'
import dayjs from 'dayjs'
import type { Orcamento } from '../model/Orcamento'
import { TotaisOrcamento } from './totais-orcamento'
import { Frete } from './frete'
import type { FormaPagamento } from '../model/FormaPagamento'
import type { LocalEntrega } from '../model/LocalEntrega'
import type { Representante } from '../model/Representante'
import { Busca } from './busca'
import type { Perfil } from '../login'
import { Sexo } from './sexo'
import type { Contato } from '../model/Contato'
import { formatDate, removeDiacritcs } from '../utils'
import { Acao } from '../acao'
import type { Destinatario } from '../model/Destinatario'

export function DadosOrcamento(props: {
  visible: boolean
  disabled: boolean
  perfil: keyof typeof Perfil
  orcamento: Orcamento
  setOrcamento(value: Orcamento): void
  procurarCliente(): void
  formasPagamento: FormaPagamento[]
  procurarFormaPagamento(): void
  locaisEntrega: LocalEntrega[]
  procurarLocalEntrega(): void
  representantes: Representante[]
  procurarRepresentante(): void
  contatos: Contato[]
  procurarContato(): void
  cnpj: string
  acaoOrcamento: Acao
  representante: Representante
  cliente: Destinatario
}) {
  return <Grid container sx={{
    height: `calc(100vh - 96px${props.acaoOrcamento !== Acao.Visualizar ? ' - 46.5px' : ''})`,
    overflowY: 'auto',
    padding: '15px',
    ...(props.visible ? {} : { display: 'none' })
  }}>
    <Grid item xs={12} md={2}>
      <TextField label='Orçamento'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.orcamento.id ?? ' '}
        disabled
      />
    </Grid>
    <Grid item xs={12} md={2}>
      {/* <TextField label='Modalidade'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.orcamento.modalidade}
        onChange={(event) => props.setOrcamento({ ...props.orcamento, modalidade: removeDiacritcs(event.target.value).substring(0, 10) })}
        disabled={props.disabled}
      /> */}
    </Grid>
    <Grid item xs={0} md={4}>
    </Grid>
    <Grid item xs={6} md={2}>
      <FormControlLabel
        control={<Checkbox
          checked={!!props.orcamento.gerado}
        />}
        label='Encerrado'
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <FormControlLabel
        control={<Checkbox
          checked={!!props.orcamento.aprovado}
        />}
        label='Aprovado'
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        disabled
      />
    </Grid>

    <Grid item xs={6} md={2}>
      <LocalizationProvider
        adapterLocale='pt-br'
        // @ts-expect-error
        localeText={ptBRdp.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Data Emissão'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          inputRef={i => {
            if (i)
              i.style.padding = `8.5px 0px 8.5px ${IS_MOBILE ? 0 : 14}px`
          }}
          value={dayjs(props.orcamento.data!)}
          slotProps={IS_MOBILE ? { textField: { variant: 'standard' } } : {}}
          disabled
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField label='Prazo de Validade'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)',
          ...(IS_MOBILE ? { p: '8px 0px 0px 0px' } : {})
        }}
        size='small'
        value={props.orcamento.validade}
        onChange={(event) => props.setOrcamento({ ...props.orcamento, validade: removeDiacritcs(event.target.value).substring(0, 10) })}
        disabled={props.disabled}
        inputRef={(i: HTMLInputElement) => {
          if (IS_MOBILE && i)
            i.style.paddingBottom = `8px`
        }}
      />
    </Grid>
    <Grid item xs={12} md={2}>
      <TextField label='Prazo de Entrega'
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        value={props.orcamento.entrega}
        onChange={(event) => props.setOrcamento({ ...props.orcamento, entrega: removeDiacritcs(event.target.value).substring(0, 30) })}
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} md={2}>
      <Busca
        disabled={props.disabled}
        id='outlined-adornment-contato'
        label='Contato'
        onClick={props.procurarContato}
        onChange={event => props.setOrcamento({ ...props.orcamento, contato: removeDiacritcs(event.target.value).substring(0, 40) })}
        value={props.orcamento.contato}
      />
    </Grid>
    <Grid item xs={12} md={2}>
      <LocalizationProvider
        adapterLocale='pt-br'
        // @ts-expect-error
        localeText={ptBRdp.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Data Aprovação'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          inputRef={i => {
            if (i)
              i.style.padding = `8.5px 0px 8.5px ${IS_MOBILE ? 0 : 14}px`
          }}
          value={dayjs(props.orcamento.dataAprovacao)}
          slotProps={IS_MOBILE ? { textField: { variant: 'standard' } } : {}}
          disabled
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={0} md={2}></Grid>

    {props.perfil !== 'representante' &&
      <Grid item xs={12} md={12}>
        <Busca
          disabled={props.disabled}
          id='outlined-adornment-representante1'
          label='Representante'
          onClick={props.procurarRepresentante}
          value={props.orcamento.vendedor ? `${props.orcamento.vendedor} - ${props.representantes.find(r => r.id === props.orcamento.vendedor)?.nome ?? null}` : null}
          required
        />
      </Grid>
    }
    <Grid item xs={12} md={12}>
      <Busca
        disabled={props.disabled || (props.perfil !== 'representante' && !props.orcamento.vendedor)}
        id='outlined-adornment-cliente1'
        label='Cliente'
        onClick={props.procurarCliente}
        value={props.orcamento.codigoCliente ? `${props.orcamento.codigoCliente} - ${props.orcamento.nomeCliente}` : null}
        required
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Busca
        disabled={props.disabled}
        id='outlined-adornment-local-entrega'
        label='Local de Entrega'
        onClick={props.procurarLocalEntrega}
        value={props.orcamento.localEntrega && props.orcamento.codigoCliente ? `${props.orcamento.localEntrega} - ${props.locaisEntrega.find(l => l.codigoCliente === props.orcamento.codigoCliente && l.id === props.orcamento.localEntrega)?.endereco ?? null}` : null}
      // required
      />
    </Grid>
    <Grid item xs={12} md={5}>
      <Busca
        disabled={props.disabled || !props.orcamento.codigoCliente}
        id='outlined-adornment-forma-pagamento'
        label='Forma de Pagamento'
        onClick={props.procurarFormaPagamento}
        value={props.orcamento.formaPagamento ? `${props.orcamento.formaPagamento} - ${props.formasPagamento.find(f => f.id === props.orcamento.formaPagamento)?.nome ?? null}` : null}
        required
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <Frete
        disabled={props.disabled}
        frete={props.orcamento.frete}
        setFrete={value => props.setOrcamento({ ...props.orcamento, frete: value })}
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <LocalizationProvider
        adapterLocale='pt-br'
        // @ts-expect-error
        localeText={ptBRdp.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Data Nascimento'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)',
          }}
          inputRef={i => {
            if (i)
              i.style.padding = `8.5px 0px 8.5px ${IS_MOBILE ? 0 : 14}px`
          }}
          value={props.orcamento.dataNascimento ? dayjs(props.orcamento.dataNascimento) : null}
          onChange={(value, context) => {
            props.setOrcamento({ ...props.orcamento, dataNascimento: value && value.isValid() ? formatDate(value, true) : null })
          }}
          slotProps={IS_MOBILE ? { textField: { variant: 'standard' } } : {}}
          disabled={props.disabled}
          className={['13827655000180', '11139437000127', '36347826000179'].includes(props.cnpj) && props.cliente.exigeIdadeSexo && !props.orcamento.dataNascimento ? 'error' : ''}
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={6} md={2}>
      <Sexo
        disabled={props.disabled}
        sexo={props.orcamento.sexo}
        setSexo={value => props.setOrcamento({ ...props.orcamento, sexo: value })}
        required={['13827655000180', '11139437000127', '36347826000179'].includes(props.cnpj) && props.cliente.exigeIdadeSexo}
      />
    </Grid>
    {/* <Grid item xs={12} md={6}>
      <TextField
        label='Representante 2'
        value={props.orcamento.vendedor2 ? `${props.orcamento.vendedor2} - ${props.representantes.find(r => r.id === props.orcamento.vendedor2)?.nome ?? ''}` : ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        label='Representante 3'
        value={props.orcamento.vendedor3 ? `${props.orcamento.vendedor3} - ${props.representantes.find(r => r.id === props.orcamento.vendedor3)?.nome ?? ''}` : ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} md={6}></Grid> */}
    {/* <Grid item xs={12} md={5}>
      <TextField
        label='Colocação'
        value={props.orcamento.colocacao}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} md={5}>
      <TextField
        label='Acabamento'
        value={props.orcamento.acabamento}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} md={2}>
      <FormControlLabel
        control={<Checkbox
          checked={!!props.orcamento.servico}
          onChange={event => props.setOrcamento({ ...props.orcamento, servico: event.target.checked })}
        />}
        label='Incluir Serviço'
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        disabled={props.disabled}
      />
    </Grid> */}
    <Grid item xs={12} md={12}>
      <TextField
        label='Observações'
        value={props.orcamento.obs}
        onChange={(event) => props.setOrcamento({ ...props.orcamento, obs: removeDiacritcs(event.target.value) })}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled={props.disabled}
        multiline
        rows={2}
      />
    </Grid>
    <TotaisOrcamento
      disabled={props.disabled}
      orcamento={props.orcamento}
    />
  </Grid>
}