import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { AbaOrcamento } from './aba-orcamento'

export default function AbasOrcamento({ aba, setAba }: { aba: AbaOrcamento, setAba(value: AbaOrcamento): void }) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setAba(newValue)
  }

  return (
    <Box sx={{ bgcolor: 'background.paper' }} style={{ position: 'absolute', top: '48px' }}>
      <Tabs
        value={aba}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab key={AbaOrcamento.Dados} value={AbaOrcamento.Dados} label="Dados"
          onClick={() => setAba(AbaOrcamento.Dados)}
        />
        <Tab key={AbaOrcamento.Itens} value={AbaOrcamento.Itens} label="Itens"
          onClick={() => setAba(AbaOrcamento.Itens)}
        />
        {/* <Tab key={AbaOrcamento.Servicos} value={AbaOrcamento.Servicos} label="Serviços"
          onClick={() => setAba(AbaOrcamento.Servicos)}
        /> */}
      </Tabs>
    </Box>
  )
}