import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, useGridApiRef, type GridColDef, type GridRowSelectionModel, type GridCallbackDetails } from '@mui/x-data-grid'
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { getConfiguracoes, telas, type Configuracoes } from './model/Configuracao'
import { ItemMenu } from './item-menu'

let loaded = false

export function Configuracao(props: {
  cliente: string
  empresa: string
  visible: boolean
  setMensagem(value: string): void
  setOpenMensagem(value: boolean): void
  configuracoes: Configuracoes
  setConfiguracoes(value: Configuracoes): void
  removeAba(aba: ItemMenu): void
}) {
  const [canSave, setCanSave] = useState(false)
  const [filtrarClientesPorRepresentanteOrcamentos, setFiltrarClientesPorRepresentanteOrcamentos] = useState(props.configuracoes.filtrarClientesPorRepresentanteOrcamentos)

  useEffect(() => {
    resetSelected()
  }, [props.configuracoes])

  function resetSelected() {
    const telas = props.configuracoes.telas
    const model = [...apiRef.current.getSelectedRows().keys()]

    for (const tela of model)
      apiRef.current.selectRow(tela, false)

    for (const tela of telas)
      apiRef.current.selectRow(tela)

    setFiltrarClientesPorRepresentanteOrcamentos(props.configuracoes.filtrarClientesPorRepresentanteOrcamentos)
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Código',
      width: IS_MOBILE ? 70 : 100,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: IS_MOBILE ? 190 : 400
    }
  ]

  const apiRef = useGridApiRef()

  async function getConfiguracao() {
    if (!loaded) {
      loaded = true

      if (!props.configuracoes)
        await getConfiguracoes(props.empresa, props.cliente, props.setMensagem, props.setOpenMensagem, props.setConfiguracoes)
    }
  }

  getConfiguracao()

  function onRowSelectionModelChange(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) {
    const telas = props.configuracoes.telas

    if (rowSelectionModel.length !== telas.length) {
      setCanSave(true)

      return
    }

    for (const tela of telas)
      if (!rowSelectionModel.includes(tela)) {
        setCanSave(true)

        return
      }

    setCanSave(false)
  }

  async function salvar() {
    try {
      const telas = [...apiRef.current.getSelectedRows().keys()].toSorted() as number[]
      await fetch(`/configuracao/${props.empresa}/${props.cliente}/telas/${telas.join(',')}`, { method: 'post' })
      await fetch(`/configuracao/${props.empresa}/${props.cliente}/filtrarClientesPorRepresentanteOrcamentos/${filtrarClientesPorRepresentanteOrcamentos ? '1' : '0'}`, { method: 'post' })
      await getConfiguracoes(props.empresa, props.cliente, props.setMensagem, props.setOpenMensagem, props.setConfiguracoes)
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        props.setMensagem('Erro ao tentar conectar com o servidor. Verifique sua conexão.')
      else
        props.setMensagem(`Erro: ${mensagem}.`)

      props.setOpenMensagem(true)
    }
  }

  function _canSave() {
    return canSave
      || props.configuracoes.filtrarClientesPorRepresentanteOrcamentos !== filtrarClientesPorRepresentanteOrcamentos
  }

  return (
    <Box
      sx={{
        padding: '10px',
        height: '300px',
        ...(props.visible ? {} : { display: 'none' })
      }}
    >
      <Typography variant='h6' sx={{ marginBottom: '10px' }}>Telas habilitados</Typography>
      <DataGrid apiRef={apiRef}
        columns={columns}
        rows={telas}
        columnHeaderHeight={25}
        rowHeight={25}
        checkboxSelection
        onRowSelectionModelChange={onRowSelectionModelChange}
      />
      <Grid container>
        <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!filtrarClientesPorRepresentanteOrcamentos}
                onChange={(e, checked) => {
                  setFiltrarClientesPorRepresentanteOrcamentos(!checked)
                }}
              />
            }
            label='Não aplicar filtro de clientes por representante na tela de Orçamentos'
          />
        </Grid>
      </Grid>
      <div style={{ textAlign: 'right', paddingTop: '10px' }}>
        <Button variant='contained' disabled={!_canSave()} onClick={salvar}>Salvar</Button>
        <Button variant='outlined' sx={{ marginLeft: '10px' }} disabled={!_canSave()} onClick={resetSelected}>Cancelar</Button>
        <Button variant='outlined' sx={{ marginLeft: '10px' }} disabled={_canSave()} onClick={() => props.removeAba(ItemMenu.Configuracao)}>Fechar</Button>
      </div>
    </Box>
  )
}