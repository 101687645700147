import { verifyLoggedAndSet } from '../utils'

export interface ItemConfiguracao {
  codcliente: number
  codempresa: number
  categoria: string
  chave: string
  valor: string
}

export const enum IDTela {
  Pedidos = 1,
  Orcamentos,
  Clientes
}

export interface Configuracoes {
  configuracao: ItemConfiguracao[]
  telas: IDTela[]
  telaConfiguracao: boolean
  filtrarClientesPorRepresentanteOrcamentos: boolean
  editarClientes: boolean
}

export async function getConfiguracoes(empresa: string, cliente: string, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: Configuracoes) => void) {
  function _setter(value: ItemConfiguracao[]) {
    const configuracoes: Configuracoes = {
      configuracao: value,
      telas: value.find(c => c.chave === 'telas')?.valor.split(' ').map(v => +v) ?? [],
      telaConfiguracao: value.find(c => c.chave === 'telaConfiguracao')?.valor === '1',
      filtrarClientesPorRepresentanteOrcamentos: value.find(c => c.chave === 'filtrarClientesPorRepresentanteOrcamentos')?.valor !== '0',
      editarClientes: value.find(c => c.chave === 'editarClientes')?.valor === '1'
    }

    configuracoes.editarClientes &&= configuracoes.telas.includes(IDTela.Clientes)

    setter(configuracoes)
  }

  await verifyLoggedAndSet(`/configuracao/${empresa}/${cliente}`, setMensagem, setOpenMensagem, _setter)
}

export interface Tela {
  id: number
  descricao: string
}

export const telas: Tela[] = [
  {
    id: 1,
    descricao: 'Pedidos'
  },
  {
    id: 2,
    descricao: 'Orçamentos'
  },
  {
    id: 3,
    descricao: 'Clientes (edição)'
  }
]