import React, { useEffect, useState } from 'react'

import {
  AppBar, Box, Button, Checkbox, Dialog, FormControl, FormControlLabel, FormLabel,
  Grid, Radio, RadioGroup, TextField, Toolbar, Typography
} from '@mui/material'

import { getDefaultDestinatario, type Destinatario, type Situacao } from '../model/Destinatario'
import { ItemMenu } from '../item-menu'
import { formatDate, isValidCNPJ, isValidCPF, removeDiacritcs, validateEmail } from '../utils'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { Classificacao } from './classificacao'
import type { Tipo } from '../model/Tipo'
import { Uf } from './uf'
import type { Pais as IPais } from '../model/Pais'
import PesquisaPais from './pesquisa-pais'
import Mensagem from '../mensagem'
import { Busca } from '../orcamentos/busca'
import PesquisaMunicipio from './pesquisa-municipio'
import type { Municipio } from '../model/Municipio'
import PesquisaRepresentante from '../pesquisa-representante'
import type { Representante } from '../model/Representante'
import PesquisaFormaPagamento from '../pesquisa-forma-pagamento'
import type { FormaPagamento } from '../model/FormaPagamento'
import type { FormaDesconto } from '../model/FormaDesconto'
import PesquisaFormaDesconto from '../pesquisa-forma-desconto'
// import PesquisaModoFaturamento from '../pesquisa-modo-faturamento'
// import type { ModoFaturamento } from '../model/ModoFaturamento'
// import type { Remetente } from '../model/Remetente'
// import PesquisaFornecedor from '../pesquisa-fornecedor'
import type { LocalEntrega } from '../model/LocalEntrega'
import type { Vendedor } from '../model/Vendedor'
import type { Cep } from '../model/Cep'
import { Transition } from '../transition'

export function DadosCliente(props: {
  disabled: boolean
  setDisabled?(value: boolean): void
  visible: boolean
  usuario: Vendedor
  cliente: Destinatario
  setCliente(value: Destinatario): void
  aba: ItemMenu
  removeAba(aba: ItemMenu, parent?: ItemMenu): void
  tipos: Tipo[]
  paises: IPais[]
  procuraPais: boolean
  setProcuraPais(value: boolean): void
  procurarPais(): void
  municipios: Municipio[]
  procuraMunicipio: boolean
  setProcuraMunicipio(value: boolean): void
  procurarMunicipio(): void
  representantes: Representante[]
  formasPagamento: FormaPagamento[]
  formasDesconto: FormaDesconto[]
  // modosFaturamento: ModoFaturamento[]
  // remetentes: Remetente[]
  locaisEntrega: LocalEntrega[]
  recarregaClientes(): Promise<void>
  open: boolean
  setOpen(value: boolean): void
  codigoCliente: string
}) {
  const [mensagem, setMensagem] = useState('')
  const [openMensagem, setOpenMensagem] = useState(false)
  const [procuraRepresentante, setProcuraRepresentante] = useState(false)
  const [procuraFormaPagamento, setProcuraFormaPagamento] = useState(false)
  const [procuraFormaDesconto, setProcuraFormaDesconto] = useState(false)
  // const [procuraModoFaturamento, setProcuraModoFaturamento] = useState(false)
  // const [procuraRemetente, setProcuraRemetente] = useState(false)

  let erros = getErros(props.cliente)

  useEffect(() => {
    erros = getErros(props.cliente)
  }, [props.cliente])

  function getErros(cliente: Destinatario) {
    let
      razaoSocial = false,
      fantasia = false,
      internet = false,
      email = false,
      situacao = !cliente.situacao,
      naoContribuinte = false,
      localEntrega = false,
      cep = false,
      uf = false,
      codigoMunicipio = false,
      municipio = false,
      bairro = false,
      cpf = false,
      cnpj = false,
      ie = false,
      pais = false,
      endereco = false,
      numero = false,
      telefone = false,
      celular = false,
      representante = false

    if (!['I', 'D'].includes(cliente.situacao ?? '')) {
      razaoSocial = !cliente.razaoSocial
      fantasia = !(!razaoSocial || !!cliente.fantasia)

      if (!['T', 'G'].includes(cliente.situacao ?? '')) {
        if (!['CONSUMIDOR', 'CONSUMIDORFINAL'].includes(cliente.fantasia ?? '')) {
          naoContribuinte = !cliente.cpf && cliente.naoContribuinte === 'S'
          localEntrega = !(!cliente.id || cliente.localEntrega !== 1 || props.locaisEntrega.find(i => i.codigoCliente === cliente.id))

          if (cliente.uf !== 'EX') {
            cep = cliente.cep?.length !== 8
            uf = !cliente.uf
            codigoMunicipio = !cliente.codigoMunicipio
            municipio = !cliente.municipio
            bairro = !cliente.bairro
            const cpfValido = cliente.cpf?.length === 11 && isValidCPF(cliente.cpf)
            cnpj = !(cpfValido && !cliente.cnpj) && !(cliente.cnpj?.length === 14 && isValidCNPJ(cliente.cnpj) && !cliente.cpf)
            cpf = !(!cnpj && !cliente.cpf) && !cpfValido
            ie = (!cliente.ie || ['ISENTO', 'ISENTA'].includes(cliente.ie.trim())) && cliente.naoContribuinte === 'S'
          } else {
            pais = !cliente.pais
            cnpj = !!cliente.cnpj && cliente.cnpj !== '00000000000000'
            cpf = !!cliente.cpf
          }

          endereco = !cliente.endereco
          numero = !cliente.numero
          telefone = cliente.telefone?.length !== 12
          celular = !!cliente.celular && cliente.celular.length !== 13
          representante = !cliente.representante
        } else
          naoContribuinte = cliente.naoContribuinte === 'S'

        internet = !(!cliente.internet || validateEmail(cliente.internet))
        email = !(!internet && !!cliente.internet && !cliente.email) && !(!!cliente.email && validateEmail(cliente.email))
      } else {
        internet = !(!cliente.internet || validateEmail(cliente.internet))
        email = !(!internet && !!cliente.internet && !cliente.email) && !(!cliente.email || validateEmail(cliente.email))
      }
    }

    return {
      razaoSocial,
      fantasia,
      internet,
      email,
      situacao,
      naoContribuinte,
      localEntrega,
      cep,
      uf,
      codigoMunicipio,
      municipio,
      bairro,
      cpf,
      cnpj,
      ie,
      pais,
      endereco,
      numero,
      telefone,
      celular,
      representante,
      temErros: razaoSocial || fantasia || internet || email || situacao || naoContribuinte || localEntrega || cep || uf || codigoMunicipio || municipio || bairro || cpf || cnpj || ie || pais || endereco || numero || telefone || celular || representante
    }
  }

  async function fechaMensagem() {
    props.setDisabled!(false)
    setOpenMensagem(false)

    if (mensagem.startsWith('Cliente ')) {
      fechaCadastro()

      await props.recarregaClientes()
    }
  }

  function _setPais(value: IPais) {
    props.setCliente({
      ...props.cliente,
      pais: value.id.toString()
    })
  }

  function _setMunicipio(value: Municipio) {
    props.setCliente({
      ...props.cliente,
      codigoMunicipio: value.id,
      municipio: value.nome
    })
  }

  function _setRepresentante(value: Representante) {
    props.setCliente({ ...props.cliente, representante: value.id.toString(), nomeRepresentante: value.nome, representanteNome: value.id ? `${value.id}${value.nome ? ` - ${value.nome}` : ''}` : '' })
  }

  function _setFormaPagamento(value: FormaPagamento) {
    props.setCliente({ ...props.cliente, formaPagamento: value.id, formaPagamentoNome: value.nome })
  }

  function _setFormaDesconto(value: FormaDesconto) {
    props.setCliente({ ...props.cliente, formaDesconto: value.id })
  }

  // function _setModosFaturamento(value: ModoFaturamento) {
  //   props.setCliente({ ...props.cliente, modoFaturamento: value.id })
  // }

  // function _setRemetente(value: Remetente) {
  //   props.setCliente({ ...props.cliente, fornecedor: value.id })
  // }

  function fechaCadastro() {
    props.setCliente(getDefaultDestinatario())

    props.removeAba(props.aba,
      props.open && props.aba === ItemMenu.NovoCliente
        ? ItemMenu.NovoOrcamento
        : undefined
    )

    props.setOpen(false)
  }

  async function salvar() {
    props.setDisabled!(true)

    const now = new Date
    const dataCadastro = formatDate(now, true)
    const cliente = { ...props.cliente, dataCadastro }

    if (!cliente.fantasia)
      cliente.fantasia = cliente.razaoSocial?.substring(0, 30) ?? null

    if (!cliente.email)
      cliente.email = cliente.internet

    cliente.naoContribuinte ??= 'N'
    cliente.isentoIPI ??= 'N'
    cliente.emailMarketing ??= false

    delete cliente.cpfCnpj
    delete cliente.representanteNome

    if (cliente.uf === 'EX') {
      cliente.cep = null
      cliente.cnpj = '00000000000000'
      cliente.codigoMunicipio = null
      cliente.municipio = null
    } else {
      cliente.pais = null
    }

    const headers = new Headers
    headers.append('cliente', JSON.stringify(cliente))

    try {
      const response = await fetch(`/salvar-cliente/${props.codigoCliente}`, {
        headers,
        method: 'post'
      })

      if (response.status === 200) {
        const result: { CODIGO: number } | { message: string } =
          await response.json()

        if ('message' in result)
          setMensagem(`Erro: ${result.message}.`)
        else if (result.CODIGO === -1)
          setMensagem(`Empresa não tem permissão para ${cliente.id ? 'alterar' : 'incluir'} clientes.`)
        else if (result.CODIGO === 0)
          setMensagem(`O ${cliente.cnpj ? 'CNPJ' : 'CPF'} já está cadastrado para outro cliente.`)
        else
          setMensagem(`Cliente ${result.CODIGO} ${props.aba === ItemMenu.AlterarCliente ? 'alterado' : 'incluído'}.`)
      } else if (response.status === 404 && response.redirected && response.url.endsWith('/login')) {
        document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
        setMensagem('Você está deslogado.\nFavor relogar.')
      } else
        setMensagem(`Erro ${response.status} - ${response.statusText}.`)
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor. Verifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)
    }

    setOpenMensagem(true)
  }

  const result = <>
    <Grid container
      sx={{
        ...(IS_MOBILE ? {} : { height: `calc(100vh - 48px${props.open && props.aba === ItemMenu.NovoCliente ? '' : ' - 48px'} - 46.5px)` }),
        ...(IS_MOBILE ? { width: 'calc(100% - 10px)' } : { width: '100%' }),
        ...(!props.visible ? { display: 'none' } : {}),
        ...(IS_MOBILE ? {} : { overflowY: 'auto' }),
        ...(IS_MOBILE && props.open && props.aba === ItemMenu.NovoCliente ? { marginTop: '48px' } : {}),
        padding: '5px'
      }}
    >
      <Grid item xs={12} md={2}>
        {(!IS_MOBILE || !!props.cliente.id) &&
          <TextField label='Cliente'
            variant={IS_MOBILE ? 'standard' : 'outlined'}
            sx={{
              m: '5px',
              width: 'calc(100% - 10px)'
            }}
            size='small'
            value={props.cliente.id || ' '}
            disabled
          />
        }
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField label='Razão Social'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.razaoSocial ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, razaoSocial: removeDiacritcs(event.target.value).substring(0, 100) })}
          disabled={props.disabled}
          error={erros.razaoSocial}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField label='Fantasia'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.fantasia || props.cliente.razaoSocial?.substring(0, 30) || ''}
          onChange={event => props.setCliente({ ...props.cliente, fantasia: removeDiacritcs(event.target.value).substring(0, 30) })}
          disabled={props.disabled}
          error={erros.fantasia}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Classificacao
          disabled={props.disabled}
          tipos={props.tipos}
          tipo={props.cliente.tipo}
          setTipo={tipo => props.setCliente({ ...props.cliente, tipo })}
        />
      </Grid>
      <Grid item xs={12} md={6}
        sx={
          IS_MOBILE ?
            {
              backgroundColor: '#f0f0f0',
              m: '6px 0px 0px 5px',
              p: '2px 0px 0px 6px',
              borderRadius: '6px'
            }
            : {}
        }
      >
        <FormControl
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          disabled={props.disabled}
          error={erros.situacao}
        >
          <FormLabel id="situacao-radio-buttons-group-label">Situação do Cliente</FormLabel>
          <RadioGroup
            row
            aria-labelledby="situacao-radio-buttons-group-label"
            name="situacao-radio-buttons-group"
            value={props.cliente.situacao ?? ''}
            onChange={(event, value) => props.setCliente({ ...props.cliente, situacao: value as Situacao })}
          >
            <FormControlLabel value="A" control={<Radio />} label="Ativo" />
            <FormControlLabel value="I" control={<Radio />} label="Inativo" />
            <FormControlLabel value="D" control={<Radio />} label="Destivado" />
            <FormControlLabel value="T" control={<Radio />} label="Interessado" />
            <FormControlLabel value="G" control={<Radio />} label="Governamental" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}
        sx={{
          alignSelf: 'center',
          ...(
            IS_MOBILE
              ? {
                backgroundColor: '#f0f0f0',
                m: '6px 0px 0px 5px',
                p: '2px 0px 0px 6px',
                borderRadius: '6px'
              }
              : {}
          )
        }}
      >
        <FormControlLabel
          control={<Checkbox
            checked={props.cliente.naoContribuinte === 'S'}
            onChange={(event, checked) => props.setCliente({ ...props.cliente, naoContribuinte: checked ? 'S' : 'N' })}
          />}
          label='Contribuinte do ICMS'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          disabled={props.disabled}
          className={erros.naoContribuinte ? 'error' : ''}
        />
      </Grid>
      <Grid item xs={12} md={3}
        sx={{
          alignSelf: 'center',
          ...(
            IS_MOBILE
              ? {
                backgroundColor: '#f0f0f0',
                m: '6px 0px 0px 5px',
                p: '2px 0px 0px 6px',
                borderRadius: '6px'
              }
              : {}
          )
        }}
      >
        <FormControlLabel
          control={<Checkbox
            checked={props.cliente.isentoIPI === 'S'}
            onChange={(event, checked) => props.setCliente({ ...props.cliente, isentoIPI: checked ? 'S' : 'N' })}
          />}
          label='Isento de IPI - Liminar Judicial'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <PatternFormat customInput={TextField} label='CEP'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          format='#####-###'
          mask='_'
          placeholder='_____-___'
          value={props.cliente.uf !== 'EX' && props.cliente.cep ? props.cliente.cep : ''}
          onChange={async event => {
            const cep = event.target.value.replaceAll(/[_-]/g, '') || null
            props.setCliente({ ...props.cliente, cep })

            if (cep?.length === 8) {
              try {
                const response = await fetch(`https://viacep.com.br/ws/${cep}/json`)

                if (response.status === 200) {
                  const json: Cep = await response.json()

                  if ('erro' in json) {
                    setMensagem('CEP não encontrado')
                    setOpenMensagem(true)
                  } else
                    props.setCliente({
                      ...props.cliente,
                      cep,
                      endereco: json.logradouro,
                      complemento: json.complemento || props.cliente.complemento,
                      bairro: json.bairro,
                      codigoMunicipio: +json.ibge,
                      municipio: json.localidade,
                      uf: json.uf
                    })
                } else {
                  setMensagem(`Erro ${response.status} - ${response.status}`)
                  setOpenMensagem(true)
                }
              } catch (e) {
                const mensagem = (e as Error).message

                if (mensagem === 'Failed to fetch')
                  setMensagem('Erro ao tentar conectar com o servidor.\nVerifique sua conexão.')
                else
                  setMensagem(`Erro: ${mensagem}.`)

                setOpenMensagem(true)
              }
            }
          }}
          disabled={props.disabled || props.cliente.uf === 'EX'}
          error={erros.cep}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Uf
          disabled={props.disabled}
          uf={props.cliente.uf} setUf={uf => props.setCliente({ ...props.cliente, uf, codigoMunicipio: null, municipio: null })}
          required={erros.uf}
        />
      </Grid>
      <Grid item xs={props.cliente.uf === 'EX' ? 12 : 0} md={props.cliente.uf === 'EX' ? 7 : 0}>
        <Busca
          visible={props.cliente.uf === 'EX'}
          disabled={props.disabled}
          id='outlined-adornment-pais'
          label='País'
          onClick={props.procurarPais}
          value={props.cliente.pais ? `${props.cliente.pais} - ${props.paises.find(p => p.id === +props.cliente.pais!)?.descricao ?? ''}` : null}
          required={erros.pais}
        />
      </Grid>
      <Grid item xs={props.cliente.uf !== 'EX' ? 12 : 0} md={props.cliente.uf !== 'EX' ? 7 : 0}>
        <Busca
          visible={props.cliente.uf !== 'EX'}
          disabled={props.disabled || !props.cliente.uf}
          id='outlined-adornment-municipio'
          label='Município'
          onClick={props.procurarMunicipio}
          value={
            props.cliente.codigoMunicipio
              ? `${props.cliente.codigoMunicipio}${props.cliente.municipio ? ` - ${props.cliente.municipio}` : ''}`
              : ''
          }
          required={erros.codigoMunicipio}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField label='Bairro'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.bairro ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, bairro: removeDiacritcs(event.target.value).substring(0, 60) })}
          disabled={props.disabled}
          error={erros.bairro}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField label='Logradouro'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.endereco ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, endereco: removeDiacritcs(event.target.value).substring(0, 60) })}
          disabled={props.disabled}
          error={erros.endereco}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <TextField label='Número'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.numero ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, numero: removeDiacritcs(event.target.value).substring(0, 60) })}
          disabled={props.disabled}
          error={erros.numero}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField label='Complemento'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.complemento ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, complemento: removeDiacritcs(event.target.value).substring(0, 60) })}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={3}
        sx={{
          alignSelf: 'center',
          ...(
            IS_MOBILE
              ? {
                backgroundColor: '#f0f0f0',
                m: '6px 0px 0px 5px',
                p: '2px 0px 0px 6px',
                borderRadius: '6px'
              }
              : {}
          )
        }}
      >
        <FormControlLabel
          control={<Checkbox
            checked={props.cliente.localEntrega === 1}
            onChange={(event, checked) => props.setCliente({ ...props.cliente, localEntrega: checked ? 1 : 0 })}
          />}
          label='Emitir NF p/ End. Entrega'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          disabled={props.disabled || !props.cliente.id}
          className={erros.localEntrega ? 'erro' : ''}
        />
      </Grid>
      <Grid item xs={12} md={4}
        sx={{
          alignSelf: 'center',
          ...(
            IS_MOBILE
              ? {
                backgroundColor: '#f0f0f0',
                m: '6px 0px 0px 5px',
                p: '2px 0px 0px 6px',
                borderRadius: '6px'
              }
              : {}
          )
        }}
      >
        <FormControlLabel
          control={<Checkbox
            checked={props.cliente.calculoPadraoST === 'S'}
            onChange={(event, checked) => props.setCliente({ ...props.cliente, calculoPadraoST: checked ? 'S' : 'N' })}
          />}
          label='Aplicar cálculo padrão no ST com IVA'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={5}
        sx={{
          alignSelf: 'center',
          ...(
            IS_MOBILE
              ? {
                backgroundColor: '#f0f0f0',
                m: '6px 0px 0px 5px',
                p: '2px 0px 0px 6px',
                borderRadius: '6px'
              }
              : {}
          )
        }}
      >
        <FormControlLabel
          control={<Checkbox
            checked={!props.cliente.exigeIdadeSexo}
            onChange={(event, checked) => props.setCliente({ ...props.cliente, exigeIdadeSexo: !checked })}
          />}
          label='Não exigir Data de Nascimento e Sexo'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <PatternFormat customInput={TextField} label='CNPJ'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          format='##.###.###/####-##'
          mask='_'
          placeholder='__.___.___/____-__'
          value={props.cliente.cnpj ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, cnpj: event.target.value.replaceAll(/[_\./-]/g, '') })}
          disabled={props.disabled}
          error={erros.cnpj}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField label='Inscrição Estadual'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.ie ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, ie: removeDiacritcs(event.target.value).substring(0, 20) })}
          disabled={props.disabled}
          error={erros.ie}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField label='C.C.M. (Inscrição Municipal)'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.inscricaoMunicipal ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, inscricaoMunicipal: removeDiacritcs(event.target.value).substring(0, 8) })}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField label='RG'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.rg ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, rg: removeDiacritcs(event.target.value).substring(0, 10) })}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <PatternFormat customInput={TextField} label='CPF'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          format='###.###.###-##'
          mask='_'
          placeholder='___.___.___-__'
          value={props.cliente.cpf ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, cpf: event.target.value.replaceAll(/[_\.-]/g, '') })}
          disabled={props.disabled}
          error={erros.cpf}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField label='Contato'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.contato ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, contato: removeDiacritcs(event.target.value).substring(0, 30) })}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <PatternFormat customInput={TextField} label='Telefone Fixo'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          format='(####) ####-####'
          mask='_'
          placeholder='(____) ____-____'
          value={props.cliente.telefone ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, telefone: event.target.value.replaceAll(/[\(\)_ -]/g, '') })}
          disabled={props.disabled}
          error={erros.telefone}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <PatternFormat customInput={TextField} label='Celular'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          format='(####) #####-####'
          mask='_'
          placeholder='(____) _____-____'
          value={props.cliente.celular ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, celular: event.target.value.replaceAll(/[\(\)_ -]/g, '') })}
          disabled={props.disabled}
          error={erros.celular}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField label='E-mail'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.internet ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, internet: removeDiacritcs(event.target.value).substring(0, 60) })}
          error={erros.internet}
          type='email'
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField label='E-mail para Envio da NF-e'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.email || props.cliente.internet || ''}
          onChange={event => props.setCliente({ ...props.cliente, email: removeDiacritcs(event.target.value).substring(0, 250) })}
          error={erros.email}
          type='email'
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField label='Observações Extras'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.observacaoExtra ?? ''}
          onChange={event => props.setCliente({ ...props.cliente, observacaoExtra: removeDiacritcs(event.target.value).substring(0, 60) })}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} md={5}
        sx={
          IS_MOBILE
            ? {
              backgroundColor: '#f0f0f0',
              m: '6px 0px 0px 5px',
              p: '2px 0px 0px 6px',
              borderRadius: '6px'
            }
            : {}
        }
      >
        <FormControlLabel
          control={<Checkbox
            checked={!!props.cliente.emailMarketing}
            onChange={(event, checked) => props.setCliente({ ...props.cliente, emailMarketing: checked })}
          />}
          label='Notificar Marketing após Envio de NF-e'
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          disabled={props.disabled}
        />
      </Grid>
      {/* <Grid item xs={12} md={5}>
        <Busca
          disabled={props.disabled}
          id='outlined-adornment-fornecedor'
          label='Fornecedor Relacionado'
          onClick={() => setProcuraRemetente(true)}
          value={props.cliente.fornecedor ? `${props.cliente.fornecedor} - ${props.remetentes.find(i => i.id === props.cliente.fornecedor)?.razaoSocial ?? ''}` : null}
          reset={() => props.setCliente({ ...props.cliente, fornecedor: null })}
        />
      </Grid> */}
      <Grid item xs={12} md={5}>
        <Busca
          disabled={props.disabled}
          id='outlined-adornment-representante'
          label='Representante'
          onClick={() => setProcuraRepresentante(true)}
          value={props.cliente.representanteNome ?? null}
          required={erros.representante}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <NumericFormat customInput={TextField} label='Comissão'
          variant={IS_MOBILE ? 'standard' : 'outlined'}
          sx={{
            m: '5px',
            width: 'calc(100% - 10px)'
          }}
          size='small'
          value={props.cliente.comissao ?? ''}
          onValueChange={(values, sourceInfo) => props.setCliente({ ...props.cliente, comissao: values.floatValue ?? null })}
          disabled={props.disabled}
          allowNegative={false}
          decimalScale={2}
          decimalSeparator=','
          thousandSeparator='.'
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Busca
          disabled={props.disabled}
          id='outlined-adornment-forma-pagamento'
          label='Forma de Pagamento'
          onClick={() => setProcuraFormaPagamento(true)}
          value={props.cliente.formaPagamento ? `${props.cliente.formaPagamento}${props.cliente.formaPagamentoNome ? ` - ${props.cliente.formaPagamentoNome}` : ''}` : null}
          reset={() => props.setCliente({ ...props.cliente, formaPagamento: null })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Busca
          disabled={props.disabled}
          id='outlined-adornment-forma-desconto'
          label='Forma de Desconto'
          onClick={() => setProcuraFormaDesconto(true)}
          value={props.cliente.formaDesconto ? `${props.cliente.formaDesconto} - ${props.formasDesconto.find(i => i.id === props.cliente.formaDesconto)?.descricao ?? ''}` : null}
          reset={() => props.setCliente({ ...props.cliente, formaDesconto: null })}
        />
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <Busca
          disabled={props.disabled}
          id='outlined-adornment-modo-faturamento'
          label='Modo de Faturamento'
          onClick={() => setProcuraModoFaturamento(true)}
          value={props.cliente.modoFaturamento ? `${props.cliente.modoFaturamento} - ${props.modosFaturamento.find(i => i.id === props.cliente.formaDesconto)?.descricao ?? ''}` : null}
        />
      </Grid> */}
    </Grid>
    <Grid container sx={{
      ...(!props.visible ? { display: 'none' } : {})
    }}>
      <Grid item xs={0} md={8}></Grid>
      <Grid item xs={6} md={2} sx={{ textAlign: 'center' }}>
        <Button
          sx={{ m: '5px' }}
          onClick={async () => await salvar()}
          disabled={props.disabled || erros.temErros}
        >Salvar Cliente</Button>
      </Grid>
      <Grid item xs={6} md={2} sx={{ textAlign: 'center' }}>
        <Button
          sx={{ m: '5px' }}
          onClick={() => {
            fechaCadastro()
          }}
        >Cancelar</Button>
      </Grid>
    </Grid>
    {props.procuraPais &&
      <PesquisaPais
        open={props.procuraPais} setOpen={props.setProcuraPais}
        paises={props.paises}
        setPais={_setPais}
      />
    }
    {props.procuraMunicipio &&
      <PesquisaMunicipio
        open={props.procuraMunicipio} setOpen={props.setProcuraMunicipio}
        municipios={props.cliente.uf && props.cliente.uf !== 'EX' ? props.municipios.filter(i => i.uf === props.cliente.uf) : []}
        setMunicipio={_setMunicipio}
      />
    }
    {procuraRepresentante &&
      <PesquisaRepresentante
        open={procuraRepresentante} setOpen={setProcuraRepresentante}
        representantes={props.representantes}
        setRepresentante={_setRepresentante}
      />
    }
    {procuraFormaPagamento &&
      <PesquisaFormaPagamento
        open={procuraFormaPagamento} setOpen={setProcuraFormaPagamento}
        formasPagamento={props.formasPagamento}
        setFormaPagamento={_setFormaPagamento}
      />
    }
    {procuraFormaDesconto &&
      <PesquisaFormaDesconto
        open={procuraFormaDesconto} setOpen={setProcuraFormaDesconto}
        formasDesconto={props.formasDesconto}
        setFormaDesconto={_setFormaDesconto}
      />
    }
    {/* {procuraModoFaturamento &&
      <PesquisaModoFaturamento
        open={procuraModoFaturamento} setOpen={setProcuraModoFaturamento}
        modosFaturamento={props.modosFaturamento}
        setModoFaturamento={_setModosFaturamento}
      />
    } */}
    {/* {procuraRemetente &&
      <PesquisaFornecedor
        open={procuraRemetente} setOpen={setProcuraRemetente}
        remetentes={props.remetentes}
        setRemetente={_setRemetente}
      />
    } */}
    <Mensagem titulo='Cliente' mensagem={mensagem} open={openMensagem} ok={fechaMensagem} id='novo-cliente' />
  </>

  function handleClose() {
    props.removeAba(props.aba,
      props.open && props.aba === ItemMenu.NovoCliente
        ? ItemMenu.NovoOrcamento
        : undefined
    )

    props.setOpen(false)
  }

  if (props.open && props.aba === ItemMenu.NovoCliente)
    return <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar variant='dense'>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Incluir Cliente
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{
        ...(IS_MOBILE ? {} : { position: 'relative', top: '48px' }),
        ...(IS_MOBILE ? { width: 'calc(100% - 10px)' } : { width: '100%' }),
      }}>
        {result}
      </Box>
    </Dialog>

  return result
}