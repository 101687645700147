import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, type GridColDef, type GridRowParams } from '@mui/x-data-grid'
import { Dialog, AppBar, IconButton, Toolbar, Typography, Tooltip } from '@mui/material'
import { Transition } from '../transition'
import { Search, SearchIconWrapper, StyledInputBase } from '../search'
import { type LocalEntrega } from '../model/LocalEntrega'

export default function PesquisaLocalEntrega(props: {
  open: boolean
  setOpen(value: boolean): void
  locaisEntrega: LocalEntrega[]
  setLocalEntrega(value: LocalEntrega): void
  setMensagem(value: string): void
  setOpenMensagem(value: boolean): void
}) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: IS_MOBILE ? 'ID' : 'Código',
      width: 70,
      sortable: !IS_MOBILE
    },
    {
      field: 'endereço',
      headerName: 'Local de Entrega',
      width: 550
    }
  ]

  if (IS_MOBILE)
    columns.unshift({
      field: 'acoes',
      type: 'actions',
      width: 50,
      getActions(params) {
        return [
          <GridActionsCellItem
            icon={<Tooltip title='Selecionar Local de Entrega'><CheckCircleIcon color='primary' /></Tooltip>}
            label='Selecionar Local de Entrega'
            onClick={() => handleSelectClick(params)}
          />
        ]
      }
    })

  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState([...props.locaisEntrega])

  function handleClose() {
    props.setOpen(false)
  }

  function handleSelectClick(params: GridRowParams<LocalEntrega>) {
    props.setLocalEntrega(params.row)
    props.setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {IS_MOBILE || <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Pesquisa de Locais de Entrega
          </Typography>}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              autoFocus={!IS_MOBILE}
              placeholder="Filtro..."
              inputProps={{ 'aria-label': 'search' }}
              onChange={event => {
                let filter = event.target.value
                setFilter(filter)
                filter = filter.toLowerCase()

                if (filter.trim().length > 0)
                  setFiltered(props.locaisEntrega.filter(localEntrega =>
                    localEntrega.id.toString().includes(filter)
                    || localEntrega.endereco?.toLowerCase().includes(filter)
                  ))
                else
                  setFiltered([...props.locaisEntrega])
              }}
            />
          </Search>
          {IS_MOBILE || <Typography variant="subtitle1" component="div">
            Clique duplo para selecionar
          </Typography>}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 'calc(100vh - 48px)',
          width: '100%'
        }}
      >
        <DataGrid
          rows={filtered}
          columns={columns}
          columnHeaderHeight={25}
          rowHeight={25}
          onRowDoubleClick={handleSelectClick}
        />
      </Box>
    </Dialog>
  )
}