import React, { useEffect, useState } from 'react'
import { AppBar, Box, Button, Dialog, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getDefaultOrcamento, getDefaultOrcamentoItem, type Orcamento, type OrcamentoItem/*, OrcamentoServico*/ } from '../model/Orcamento'
import PesquisaClientes from '../pesquisa-clientes'
import { getDefaultDestinatario, type Destinatario } from '../model/Destinatario'
import { type FormaPagamento } from '../model/FormaPagamento'
import { type Transportadora } from '../model/Transportadora'
import { Transition } from '../transition'
import AbasOrcamento from './abas'
import { AbaOrcamento } from './aba-orcamento'
import { DadosOrcamento } from './dados'
import { ItensOrcamento } from './itens'
import type { ProdutoDetalheOrcamento } from '../model/ProdutoDetalheOrcamento'
// import { ServicosOrcamento } from './servicos'
import { type LocalEntrega } from '../model/LocalEntrega'
import { getDefaultRepresentante, type Representante } from '../model/Representante'
import PesquisaFormaPagamento from '../pesquisa-forma-pagamento'
import PesquisaLocalEntrega from './pesquisa-local-entrega'
import type { Perfil } from '../login'
import { getDefaultContato, type Contato } from '../model/Contato'
import PesquisaContato from './pesquisa-contato'
import PesquisaProdutos from '../pesquisa-produtos'
import { getDefaultProduto, type Produto } from '../model/Produto'
import Mensagem from '../mensagem'
import { formatDate, formatTime } from '../utils'
import type { Vendedor } from '../model/Vendedor'
import type { TributacaoProduto } from '../model/TributacaoProduto'
import PesquisaRepresentantes from '../pesquisa-representantes'
import { Acao } from '../acao'
import type { ItemMenu } from '../item-menu'

export function OrcamentoDialog(props: {
  disabled: boolean
  setDisabled?(value: boolean): void
  empresa: string
  perfil: keyof typeof Perfil
  orcamento: Orcamento
  setOrcamento(value: Orcamento): void
  orcamentoItens: OrcamentoItem[]
  setOrcamentoItens(value: OrcamentoItem[]): void
  // orcamentoServicos: OrcamentoServico[]
  // setOrcamentoServicos(value: OrcamentoServico[]): void
  todosClientes: Destinatario[]
  setTodosClientes(value: Destinatario[]): void
  formasPagamento: FormaPagamento[]
  setFormasPagamento(value: FormaPagamento[]): void
  transportadoras: Transportadora[]
  setTransportadoras(value: Transportadora[]): void
  open: boolean
  setOpen(value: boolean): void
  aba: AbaOrcamento
  setAba(value: AbaOrcamento): void
  produtoDetalheOrcamentos: ProdutoDetalheOrcamento[]
  setProdutoDetalheOrcamentos(value: ProdutoDetalheOrcamento[]): void
  locaisEntrega: LocalEntrega[]
  representantes: Representante[]
  setRepresentantes(value: Representante[]): void
  todosRepresentantes: Representante[]
  contatos: Contato[]
  produtos: Produto[]
  setProdutos(value: Produto[]): void
  setMensagem(value: string): void
  setOpenMensagem(value: boolean): void
  cnpj: string
  usuario: Vendedor
  recarregaOrcamentos(): Promise<void>
  acaoOrcamento: Acao
  setAcaoOrcamento(value: Acao): void
  tributacaoProdutos: TributacaoProduto[]
  recarregarClientes(): void
  novoCliente?: (() => void) | undefined
  abas: ItemMenu[]
}) {
  const [clientes, setClientes] = useState(props.todosClientes)
  const [cliente, setCliente] = useState(props.todosClientes.find(c => c.id === props.orcamento.codigoCliente) ?? getDefaultDestinatario())
  const [procuraCliente, setProcuraCliente] = useState(false)
  const [procuraFormaPagamento, setProcuraFormaPagamento] = useState(false)
  const [procuraLocalEntrega, setProcuraLocalEntrega] = useState(false)

  const [representante, setRepresentante] = useState(
    props.perfil !== 'representante'
      ? getDefaultRepresentante()
      : { id: props.usuario.codigo, nome: props.usuario.nome, supervisor: props.usuario.supervisor } as Representante
  )

  const [procuraRepresentante, setProcuraRepresentante] = useState(false)
  const [contato, setContato] = useState(getDefaultContato())
  const [procuraContato, setProcuraContato] = useState(false)
  const [produto, setProduto] = useState(getDefaultProduto())
  const [procuraProduto, setProcuraProduto] = useState(false)
  const [item, setItem] = useState(1)
  const [mensagem, setMensagem] = useState('')
  const [openMensagem, setOpenMensagem] = useState(false)
  const [fecharOrcamento, setFecharOrcamento] = useState(false)

  useEffect(() => {
    setClientes(props.todosClientes/*.filter(c => c.representante ? Number.parseInt(c.representante) === representante.id : false)*/)
  }, [props.todosClientes])

  function procurarCliente() {
    setProcuraCliente(true)
  }

  function procurarFormaPagamento() {
    setProcuraFormaPagamento(true)
  }

  function procurarLocalEntrega() {
    setProcuraLocalEntrega(true)
  }

  function procurarRepresentante() {
    setProcuraRepresentante(true)
  }

  function procurarContato() {
    setProcuraContato(true)
  }

  function procurarProduto() {
    setProcuraProduto(true)
  }

  function handleClose() {
    props.setOpen(false)

    if (props.acaoOrcamento === Acao.Alterar) {
      props.setOrcamento(getDefaultOrcamento())
      props.setOrcamentoItens([getDefaultOrcamentoItem()])
      props.setAba(AbaOrcamento.Dados)
    }

    props.setAcaoOrcamento(Acao.Nenhuma)
  }

  function _setContato(value: Contato) {
    setContato(value)
    props.setOrcamento({ ...props.orcamento, contato: value.nome })
  }

  function _setCliente(value: Destinatario) {
    setCliente(value)
    props.setOrcamento({
      ...props.orcamento,
      codigoCliente: value.id,
      nomeCliente: value.razaoSocial,
      formaPagamento: value.formaPagamento
    })
  }

  function _setLocalEntrega(value: LocalEntrega) {
    props.setOrcamento({ ...props.orcamento, localEntrega: value.id })
  }

  function _setFormaPagamento(value: FormaPagamento) {
    props.setOrcamento({ ...props.orcamento, formaPagamento: value.id })
  }

  function _setRepresentante(value: Representante) {
    setRepresentante(value)
    setClientes(props.todosClientes/*.filter(c => c.representante ? Number.parseInt(c.representante) === value.id : false)*/)
    setCliente(getDefaultDestinatario())
    props.setOrcamento({ ...props.orcamento, vendedor: value.id, codigoCliente: null, formaPagamento: null })
  }

  function _setProduto(value: Produto) {
    setProduto(value)

    const orcamentoItens = [...props.orcamentoItens]
    const orcamentoItem = orcamentoItens[item - 1]

    orcamentoItens.splice(item - 1, 1, {
      ...orcamentoItem,
      codigoProduto: value.id,
      nomeProduto: value.descricao,
      codigoAnalitico: value.codigoAnalitico,
      codigoAnalitico2: value.codigoAnalitico2,
      valorMoeda: value.tipo === 'N' ? 0 : props.orcamento.valorMoeda,
      valorUnitario: orcamentoItem.valorUnitario == null || orcamentoItem.valorUnitario <= 0 ? value.precoVenda : orcamentoItem.valorUnitario,
      unidade: value.unidade,
      percIPI: orcamentoItem.percIPI == null || orcamentoItem.percIPI <= 0 ? value.percIPI : orcamentoItem.percIPI,
    })

    props.setOrcamentoItens(orcamentoItens)
  }

  function podeSalvar() {
    const itens = props.orcamentoItens

    return !!(
      props.orcamento.codigoCliente
      // && props.orcamento.localEntrega
      && props.orcamento.formaPagamento
      && (!['13827655000180', '11139437000127', '36347826000179'].includes(props.cnpj) || !cliente.exigeIdadeSexo || props.orcamento.dataNascimento && props.orcamento.sexo)
      && (props.orcamento.vendedor || representante.id)
      && itens.length
      && itens.every(i => {
        return !!(i.nomeProduto && i.quantidade! > 0 && i.valorUnitario! > 0 && i.quantidade! >= (i.quantidadeEfetivar ?? 0))
      })
    )
  }

  async function salvar() {
    props.setDisabled!(true)

    const headers = new Headers
    const itens = [...props.orcamentoItens]

    let
      totalProdutos = 0,
      totalBruto = 0,
      totalGeral = 0,
      totalFrete = 0,
      totalDespesasAcessorias = 0,
      totalDescontos = 0,
      totalIPI = 0,
      totalICMS = 0,
      totalIcmsST = 0,
      totalPIS = 0,
      totalCOFINS = 0

    for (const item of itens) {
      item.unitarioLiquido = item.valorUnitario ?? 0

      if (item.percDesc)
        item.descontoUnitario = Number.parseFloat(((item.valorUnitario ?? 0) * item.percDesc / 100).toFixed(2))

      item.unitarioLiquido -= item.descontoUnitario ?? 0
      item.valorProdutos = Number.parseFloat((item.unitarioLiquido * item.quantidade!).toFixed(2))

      if (item.percIPI)
        item.valorIPI = Number.parseFloat((item.valorProdutos * item.percIPI / 100).toFixed(2))

      item.valorTotal =
        item.valorProdutos +
        (item.valorIPI ?? 0) +
        (item.valorFrete ?? 0) +
        (item.despesasAcessorias ?? 0) -
        (item.descontoItem ?? 0)

      const tributacaoProduto = props.tributacaoProdutos.find(t => t.codigoProduto === item.codigoProduto && t.uf === cliente.uf)
      const simplesNacional = props.usuario.simplesNacional

      if (tributacaoProduto) {
        item.percICMS = simplesNacional ? 0 : tributacaoProduto.percICMS
        item.percRedICMS = tributacaoProduto.reducaoICMS
        item.percIVA = simplesNacional ? tributacaoProduto.snPercIVA : tributacaoProduto.percIVA
        item.percIcmsST = tributacaoProduto.subICMS
      }

      const produto = props.produtos.find(p => p.id === item.codigoProduto)

      if (produto) {
        item.percPIS = simplesNacional ? 0 : produto.aliquotaPIS
        item.percCOFINS = simplesNacional ? 0 : produto.aliquotaCOFINS
      }

      if (item.percRedICMS! > 0)
        item.valorICMS = Number.parseFloat((item.valorTotal * ((item.percICMS ?? 0) * (100 - (item.percRedICMS ?? 0)) / 100) / 100).toFixed(2))
      else
        item.valorICMS = Number.parseFloat((item.valorTotal * (item.percICMS ?? 0) / 100).toFixed(2))

      item.valorIcmsST = Number.parseFloat(((item.valorTotal + (item.valorTotal * (item.percIVA ?? 0) / 100)) * (item.percIcmsST ?? 0) / 100 - item.valorICMS).toFixed(2))

      if (item.valorIcmsST < 0)
        item.valorIcmsST = 0

      item.valorPIS = Number.parseFloat((item.valorTotal * (item.percPIS ?? 0) / 100).toFixed(2))
      item.valorCOFINS = Number.parseFloat((item.valorTotal * (item.percCOFINS ?? 0) / 100).toFixed(2))

      totalProdutos += item.valorTotal
      totalBruto += item.valorProdutos
      totalGeral += item.valorTotal
      totalFrete += (item.valorFrete ?? 0)
      totalDespesasAcessorias += (item.despesasAcessorias ?? 0)
      totalDescontos += (item.descontoItem ?? 0)
      totalIPI += (item.valorIPI ?? 0)
      totalICMS += (item.valorICMS ?? 0)
      totalIcmsST += (item.valorIcmsST ?? 0)
      totalPIS += (item.valorPIS ?? 0)
      totalCOFINS += (item.valorCOFINS ?? 0)

      item.quantidadeEfetivar ??= item.quantidade
    }

    const now = new Date
    const usuario = `${formatDate(now)}_${formatTime(now)}_${props.usuario.loginWeb}`

    const orcamento: Orcamento & { itens: OrcamentoItem[] } = {
      ...props.orcamento,
      usuario,
      itens
    }

    if (orcamento.percEnc)
      orcamento.valorEnc = Number.parseFloat((totalProdutos * orcamento.percEnc / 100).toFixed(2))

    orcamento.percDesconto = 0
    orcamento.valorDesconto = 0
    orcamento.valorBruto = totalProdutos

    orcamento.valorTotal =
      orcamento.valorBruto +
      (orcamento.valorFrete ?? 0) +
      (orcamento.valorEnc ?? 0) -
      orcamento.valorDesconto

    orcamento.valorFrete = totalFrete
    orcamento.valorEnc = totalDespesasAcessorias
    orcamento.valorDesconto = totalDescontos
    orcamento.valorBruto = totalBruto
    orcamento.totalIpi = totalIPI
    orcamento.totalIcms = totalICMS
    orcamento.totalIcmsST = totalIcmsST
    orcamento.totalPis = totalPIS
    orcamento.totalCofins = totalCOFINS
    orcamento.valorTotal = totalGeral

    orcamento.vendedor ??= representante.id

    if (orcamento.nomeCliente)
      orcamento.nomeCliente = orcamento.nomeCliente.substring(0, 40)

    orcamento.data = formatDate(orcamento.data!, true)

    if (orcamento.dataNascimento)
      orcamento.dataNascimento = formatDate(orcamento.dataNascimento, true)

    headers.append('orcamento', JSON.stringify(orcamento))

    try {
      const response = await fetch(`/salvar-orcamento/${props.empresa}`, {
        headers,
        method: 'post'
      })

      if (response.status === 200) {
        const result: { CODIGO: string } | { message: string } =
          await response.json()

        if ('message' in result)
          setMensagem(`Erro: ${result.message}.`)
        else if (props.acaoOrcamento === Acao.Alterar && !result.CODIGO)
          setMensagem(`Orçamento ${orcamento.id} já se encontra encerrado e por isso não foi alterado`)
        else
          setMensagem(`Orçamento ${result.CODIGO} ${props.acaoOrcamento === Acao.Alterar ? 'alterado' : 'incluído'}.`)
      } else if (response.status === 404 && response.redirected && response.url.endsWith('/login')) {
        document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
        setMensagem('Você está deslogado.\nFavor relogar.')
      } else
        setMensagem(`Erro ${response.status} - ${response.statusText}.`)
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor. Verifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)
    }

    setOpenMensagem(true)
  }

  function fechaOrcamento() {
    props.setOpen(false)
    props.setOrcamento(getDefaultOrcamento())
    props.setOrcamentoItens([getDefaultOrcamentoItem()])
    props.setAba(AbaOrcamento.Dados)
    props.setAcaoOrcamento(Acao.Nenhuma)
  }

  async function fechaMensagem() {
    props.setDisabled!(false)
    setOpenMensagem(false)

    if (mensagem.startsWith('Orçamento ')) {
      fechaOrcamento()

      await props.recarregaOrcamentos()
    } else if (fecharOrcamento)
      fechaOrcamento()
  }

  return <Dialog
    fullScreen
    open={props.open}
    onClose={handleClose}
    TransitionComponent={Transition}
    disableEscapeKeyDown={props.acaoOrcamento !== Acao.Visualizar}
  >
    <AppBar sx={{ position: 'fixed' }}>
      <Toolbar variant='dense'>
        {props.acaoOrcamento === Acao.Visualizar &&
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        }
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          {props.acaoOrcamento === Acao.Alterar
            ? 'Alterar '
            : props.acaoOrcamento === Acao.Incluir
              ? 'Incluir '
              : ''}Orçamento
        </Typography>
      </Toolbar>
    </AppBar>
    <AbasOrcamento aba={props.aba} setAba={props.setAba} />
    <Box sx={{
      position: 'relative', top: '96px',
      width: '100%'
    }}>
      <DadosOrcamento
        visible={props.aba === AbaOrcamento.Dados}
        disabled={props.disabled}
        perfil={props.perfil}
        orcamento={props.orcamento}
        setOrcamento={props.setOrcamento}
        procurarCliente={procurarCliente}
        formasPagamento={props.formasPagamento}
        procurarFormaPagamento={procurarFormaPagamento}
        locaisEntrega={props.locaisEntrega}
        procurarLocalEntrega={procurarLocalEntrega}
        representantes={props.todosRepresentantes}
        procurarRepresentante={procurarRepresentante}
        contatos={props.contatos}
        procurarContato={procurarContato}
        cnpj={props.cnpj}
        acaoOrcamento={props.acaoOrcamento}
        representante={representante}
        cliente={cliente}
      />
      <ItensOrcamento
        visible={props.aba === AbaOrcamento.Itens}
        disabled={props.disabled}
        orcamento={props.orcamento}
        itens={props.orcamentoItens} setItens={props.setOrcamentoItens}
        produtoDetalheOrcamentos={props.produtoDetalheOrcamentos} setProdutoDetalheOrcamentos={props.setProdutoDetalheOrcamentos}
        procurarProduto={procurarProduto}
        item={props.orcamentoItens[item - 1]} setItem={setItem}
        detalhes={props.produtoDetalheOrcamentos.find(d => d.codigoProduto === props.orcamentoItens[item - 1]?.codigoProduto)}
        acaoOrcamento={props.acaoOrcamento}
        usuario={props.usuario}
        cliente={cliente}
        produtos={props.produtos}
        tributacaoProdutos={props.tributacaoProdutos}
      />
      {props.acaoOrcamento !== Acao.Visualizar && <Grid container>
        <Grid item xs={0} md={8}></Grid>
        <Grid item xs={6} md={2} sx={{ textAlign: 'center' }}>
          <Button
            sx={{ m: '5px' }}
            onClick={async () => await salvar()}
            disabled={props.disabled || !podeSalvar()}
          >Salvar Orçamento</Button>
        </Grid>
        <Grid item xs={6} md={2} sx={{ textAlign: 'center' }}>
          <Button
            sx={{ m: '5px' }}
            onClick={() => {
              fechaOrcamento()
            }}
            disabled={props.disabled}
          >Cancelar</Button>
        </Grid>
      </Grid>}
      {/* <ServicosOrcamento
        visible={props.aba === AbaOrcamento.Servicos}
        disabled={props.disabled}
        orcamento={props.orcamento}
        servicos={props.orcamentoServicos}
      /> */}
      {procuraContato &&
        <PesquisaContato
          open={procuraContato} setOpen={setProcuraContato}
          contatos={props.contatos}
          setContato={_setContato}
          setMensagem={props.setMensagem} setOpenMensagem={props.setOpenMensagem}
        />
      }
      {procuraCliente &&
        <PesquisaClientes
          open={procuraCliente} setOpen={setProcuraCliente}
          clientes={clientes} setCliente={_setCliente}
          recarregar={props.recarregarClientes}
          novoCliente={props.novoCliente}
          abas={props.abas}
        />
      }
      {procuraLocalEntrega &&
        <PesquisaLocalEntrega
          open={procuraLocalEntrega} setOpen={setProcuraLocalEntrega}
          locaisEntrega={props.locaisEntrega}
          setLocalEntrega={_setLocalEntrega}
          setMensagem={props.setMensagem} setOpenMensagem={props.setOpenMensagem}
        />
      }
      {procuraFormaPagamento &&
        <PesquisaFormaPagamento
          open={procuraFormaPagamento} setOpen={setProcuraFormaPagamento}
          formasPagamento={props.formasPagamento}
          setFormaPagamento={_setFormaPagamento}
        />
      }
      {procuraRepresentante &&
        <PesquisaRepresentantes
          empresa={props.empresa}
          perfil={props.perfil}
          id={props.usuario.codigo}
          open={procuraRepresentante} setOpen={setProcuraRepresentante}
          representantes={props.representantes} setRepresentantes={props.setRepresentantes}
          setRepresentante={_setRepresentante}
          setMensagem={setMensagem} setOpenMensagem={setOpenMensagem}
        />
      }
      {procuraProduto &&
        <PesquisaProdutos
          empresa={props.empresa}
          open={procuraProduto} setOpen={setProcuraProduto}
          produtos={props.produtos} setProdutos={props.setProdutos}
          setProduto={_setProduto}
          setMensagem={props.setMensagem} setOpenMensagem={props.setOpenMensagem}
        />
      }
    </Box>
    <Mensagem titulo='Novo Orçamento' mensagem={mensagem} open={openMensagem} ok={fechaMensagem} id='novo-orcamento' />
  </Dialog>
}