import { verifyLoggedAndSet } from '../utils'

export interface Produto {
  id: string
  codigoAnalitico: string
  codigoAnalitico2: string | null
  unidade: string
  unidadePedido: string
  descricao: string
  precoUnitario: number
  quantidadeUnidade: number | null
  precoVenda: number | null
  precoVenda2: number | null
  pesoBruto: number | null
  tipo: string | null
  comissao: number | null
  percIPI: number | null
  aliquotaPIS: number | null
  aliquotaCOFINS: number | null
}

export function getDefaultProduto(): Produto {
  return {
    id: '',
    codigoAnalitico: '',
    codigoAnalitico2: null,
    unidade: '',
    unidadePedido: '',
    descricao: '',
    precoUnitario: 0,
    quantidadeUnidade: 0,
    precoVenda: 0,
    precoVenda2: null,
    pesoBruto: 0,
    tipo: null,
    comissao: null,
    percIPI: null,
    aliquotaPIS: null,
    aliquotaCOFINS: null
  }
}

export async function getProdutos(empresa: string, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: Produto[]) => void) {
  return await verifyLoggedAndSet(`/produtos/${empresa.padStart(5, '0')}`, setMensagem, setOpenMensagem, setter)
}