import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, type GridColDef } from '@mui/x-data-grid'
import { getProdutos, type Produto } from './model/Produto'
import { Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import { formatCurrency } from './utils'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Código',
    width: 70
  },
  {
    field: 'codigoAnalitico',
    headerName: 'Código Analítico',
    width: 120
  },
  {
    field: 'descricao',
    headerName: 'Descrição Produto',
    width: 550
  },
  {
    field: 'unidadePedido',
    headerName: 'Embalagem',
    width: 90
  },
  {
    field: 'quantidadeUnidade',
    headerName: 'Volume',
    width: 70,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'unidade',
    headerName: 'Unidade',
    width: 70
  },
  {
    field: 'precoVenda',
    headerName: 'Preço Unitário',
    width: 110,
    align: 'right',
    headerAlign: 'right',
    valueGetter(params) {
      return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
    }
  },
  {
    field: 'precoUnitario',
    headerName: 'Preço Total',
    width: 120,
    align: 'right',
    headerAlign: 'right',
    valueGetter(params) {
      return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
    }
  }
]

export default function Produtos({ empresa, visible, produtos, setProdutos, setMensagem, setOpenMensagem }: { empresa: string, visible: boolean, produtos: Produto[], setProdutos(value: Produto[]): void, setMensagem(value: string): void, setOpenMensagem(value: boolean): void }) {
  const [loaded, setLoaded] = useState(false)
  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState([] as Produto[])

  async function load() {
    setLoaded(true)
    produtos.length || await getProdutos(empresa, setMensagem, setOpenMensagem, setProdutos)
  }

  if (!loaded)
    load()

  return (
    <Box sx={{
      ...(IS_MOBILE ? {} : { height: 'calc(100vh - 48px - 48px)' }),
      ...(IS_MOBILE ? { width: 'calc(100% - 10px)' } : { width: '100%' }),
      ...(!visible ? { display: 'none' } : {})
    }}>
      {IS_MOBILE && <>
        <div style={{ height: '10px' }}></div>
        <Grid container sx={{
          m: '5px',
          p: '10px'
        }}>
          <Grid item xs={12}>
            <TextField variant='standard' size='small' label='Filtro' value={filter}
              sx={{ width: '100%' }}
              onChange={event => {
                let filter = event.target.value
                setFilter(filter)
                filter = filter.toLowerCase()

                if (filter.trim().length > 0)
                  setFiltered(produtos.filter(produto =>
                    produto.id.includes(filter)
                    || produto.descricao.toLowerCase().includes(filter)
                  ))
                else
                  setFiltered([])
              }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption'>Código ou descrição do produto</Typography>
          </Grid>
        </Grid>
      </>}
      {IS_MOBILE
        ? <List>
          {filtered.map((produto, index) =>
            <>
              <ListItem sx={{
                backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
                m: '6px 0px 0px 6px',
                borderRadius: '6px'
              }}>
                <ListItemText
                  primary={`${produto.id} - ${produto.descricao}`}
                  secondary={<Grid container>
                    {[
                      ['Embalagem e unidade', `1 ${produto.unidadePedido} = ${produto.quantidadeUnidade ?? 1} ${produto.unidade}`],
                      produto.precoVenda && ['Preço unitário', `R$ ${formatCurrency(produto.precoVenda)}`],
                      produto.precoVenda && ['Preço embalagem', `R$ ${formatCurrency((produto.quantidadeUnidade ?? 1) * produto.precoVenda)}`]
                    ].filter((i): i is string[] => !!i).map(i =>
                      <>
                        <Grid item xs={6}>
                          <Typography variant='overline'>{i[0]}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2' marginTop='4px' sx={{ width: 'calc(50vw - 15px)', overflow: 'hidden', textOverflow: 'ellipsis' }}>{i[1]}</Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>}
                />
              </ListItem>
            </>
          )}
        </List>
        : <DataGrid
          rows={produtos}
          columns={columns}
          columnHeaderHeight={25}
          rowHeight={25}
        />
      }
    </Box>
  )
}