import React from 'react'
import { Grid, Typography, TextField } from '@mui/material'
import type { NewPedidoItem } from './model/NewPedidoItem'
import { formatCurrency } from './utils'

export function ItemPedido({ itens, setItens, item }: { itens: NewPedidoItem[], setItens(value: NewPedidoItem[]): void, item: NewPedidoItem }) {
  return <>
    <Grid item xs={2}>
      <Typography variant='overline'>Qtde</Typography>
    </Grid>
    <Grid item xs={4} paddingRight='5px'>
      <TextField variant='standard' size='small' inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={item.quantidadeStr} error={item.quantidade <= 0} onChange={(event) => {
        const index = itens.indexOf(item)
        const quantidadeStr = event.target.value.split('').filter(c => '0123456789'.includes(c)).join('')
        const quantidade = Number.parseInt(quantidadeStr) || 0

        const _item: NewPedidoItem = {
          ...item,
          quantidade,
          volumeTotalUnidades: quantidade * item.volume,
          valorTotal: quantidade * item.volume * item.valorUnitario,
          quantidadeStr
        }

        const _itens = [...itens]
        _itens[index] = _item

        setItens(_itens)
      }} />
    </Grid>
    <Grid item xs={6}>
      <Typography variant='subtitle2' marginTop='4px' sx={{ width: 'calc(50vw - 15px)', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${item.unidadePedido} = ${item.volumeTotalUnidades} ${item.unidade}`}</Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant='overline'>Unit.</Typography>
    </Grid>
    <Grid item xs={4} paddingRight='5px'>
      <TextField variant='standard' size='small' inputProps={{ inputMode: 'decimal', pattern: '[0-9.,]*' }} value={item.valorUnitarioStr} error={item.valorUnitario <= 0} onChange={(event) => {
        const index = itens.indexOf(item)
        const filtered = event.target.value.split('').filter(c => '0123456789,.'.includes(c)).join('')
        let parts = filtered.split(/[.,]/)
        const separator = parts.length > 1 ? ',' : ''

        if (separator)
          parts = [parts[0], parts.slice(1).join('')]

        const valorUnitarioStr = `${parts[0] ?? ''}${separator}${parts[1] ? `${parts[1].substring(0, 2)}` : ''}`
        const valorUnitario = Number.parseFloat(valorUnitarioStr.replace(',', '.')) || 0

        const _item: NewPedidoItem = {
          ...item,
          valorUnitario: valorUnitario,
          valorTotal: item.quantidade * item.volume * valorUnitario,
          valorUnitarioStr
        }

        const _itens = [...itens]
        _itens[index] = _item

        setItens(_itens)
      }} />
    </Grid>
    <Grid item xs={2}>
      <Typography variant='overline'>Valor</Typography>
    </Grid>
    <Grid item xs={4} paddingRight='5px'>
      <Typography variant='subtitle2' marginTop='4px' sx={{ width: 'calc(33vw - 15px)', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`R$ ${formatCurrency(item.valorTotal)}`}</Typography>
    </Grid>
  </>
}