import type { Perfil } from '../login'
import { verifyLoggedAndSet } from '../utils'

export type Situacao = 'A' | 'I' | 'D' | 'T' | 'G'

export interface Destinatario {
  id: number
  cnpj: string | null
  ie: string | null
  razaoSocial: string | null
  uf: string | null
  endereco: string | null
  numero: string | null
  bairro: string | null
  municipio: string | null
  cep: string | null
  telefone: string | null
  email: string | null
  situacao: Situacao | null
  representante: string | null
  nomeRepresentante: string | null
  fantasia: string | null
  cpf: string | null
  formaPagamento: string | null
  formaPagamentoNome: string | null
  numeroTransportadora: string | null
  cpfCnpj?: string | null
  representanteNome?: string
  tipo: number | null
  naoContribuinte: string | null
  isentoIPI: string | null
  pais: string | null
  codigoMunicipio: number | null
  localEntrega: number | null
  complemento: string | null
  calculoPadraoST: string | null
  inscricaoMunicipal: string | null
  rg: string | null
  contato: string | null
  celular: string | null
  internet: string | null
  observacaoExtra: string | null
  // caminhoXml: string | null
  emailMarketing: boolean | null
  // fornecedor: number | null
  comissao: number | null
  formaDesconto: string | null
  // modoFaturamento: string | null
  exigeIdadeSexo: boolean
}

export function getDefaultDestinatario(): Destinatario {
  return {
    id: 0,
    cnpj: null,
    ie: null,
    razaoSocial: null,
    uf: null,
    endereco: null,
    numero: null,
    bairro: null,
    municipio: null,
    cep: null,
    telefone: null,
    email: null,
    situacao: 'A',
    representante: null,
    nomeRepresentante: null,
    fantasia: null,
    cpf: null,
    formaPagamento: null,
    formaPagamentoNome: null,
    numeroTransportadora: null,
    // cpfCnpj: null,
    tipo: null,
    naoContribuinte: null,
    isentoIPI: null,
    pais: null,
    codigoMunicipio: null,
    localEntrega: null,
    complemento: null,
    calculoPadraoST: null,
    inscricaoMunicipal: null,
    rg: null,
    contato: null,
    celular: null,
    internet: null,
    observacaoExtra: null,
    // caminhoXml: null,
    emailMarketing: null,
    // fornecedor: null,
    comissao: null,
    formaDesconto: null,
    // modoFaturamento: null,
    exigeIdadeSexo: true
  }
}

export async function getDestinatarios(empresa: string, perfil: keyof typeof Perfil, id: number, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: Destinatario[]) => void, situacoes: Situacao[] = ['A'], onError?: () => void) {
  function _setter(value: Destinatario[]) {
    value = value.map(i => ({
      ...i,
      cpfCnpj: i.cnpj || i.cpf,
      representanteNome: i.representante ? `${+i.representante}${i.nomeRepresentante ? ` - ${i.nomeRepresentante}` : ''}` : ''
    }))

    setter(value)
  }

  return await verifyLoggedAndSet(`/destinatarios/${empresa.padStart(5, '0')}/${perfil}/${id}/${situacoes.join(',')}`, setMensagem, setOpenMensagem, _setter, onError)
}