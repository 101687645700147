import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function Mensagem(props: {
  titulo: string
  mensagem: string
  open: boolean
  ok(): void
  cancelar?(): void
  id: string
}) {
  let [mensagem1, ...mensagem2] = props.mensagem.split('\n')

  function close() {
    if (props.cancelar)
      props.cancelar()
    else
      props.ok()
  }

  return (
    <Dialog
      open={props.open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id={`alert-dialog-title-${props.id}`}>
        {props.titulo}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`alert-dialog-content-${props.id}`}>
          {mensagem1}
        </DialogContentText>
        {mensagem2.length
          ? <DialogContentText id={`alert-dialog-content-extra-${props.id}`}>
            {mensagem2.join('\n')}
          </DialogContentText>
          : undefined}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.ok} autoFocus={!props.cancelar}>
          OK
        </Button>
        {props.cancelar &&
          <Button onClick={props.cancelar} autoFocus color='secondary'>
            Cancelar
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}