import React, { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import Box from '@mui/material/Box'

import {
  Dialog, AppBar, IconButton, Toolbar, Typography, Grid, List, ListItem,
  ListItemText, TextField, Checkbox
} from '@mui/material'

import { getProdutos, type Produto } from './model/Produto'
import { formatCurrency } from './utils'
import type { NewPedidoItem } from './model/NewPedidoItem'
import { ItemPedido } from './item-pedido'
import { Transition } from './transition'

export default function SelecaoProdutosMobile({ empresa, open, setOpen, produtos, setProdutos, setProduto, setMensagem, setOpenMensagem, itens, setItens, removeItem }: { empresa: string, open: boolean, setOpen(value: boolean): void, produtos: Produto[], setProdutos(value: Produto[]): void, setProduto(value: Produto): void, setMensagem(value: string): void, setOpenMensagem(value: boolean): void, itens: NewPedidoItem[], setItens(value: NewPedidoItem[]): void, removeItem(item: NewPedidoItem): void }) {
  const [loaded, setLoaded] = useState(false)
  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState([] as Produto[])

  async function load() {
    setLoaded(true)
    await getProdutos(empresa, setMensagem, setOpenMensagem, setProdutos)
  }

  if (!(produtos.length || loaded))
    load()

  function handleClose() {
    setOpen(false)
  }

  function selectProduto(produto: Produto) {
    setProduto(produto)

    // setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CheckIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Seleção de Produtos
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          width: 'calc(100% - 10px)'
        }}
      >
        <div style={{ height: '48px' }}></div>
        <Grid container sx={{
          m: '5px',
          p: '10px'
        }}>
          <Grid item xs={12}>
            <TextField variant='standard' size='small' label='Filtro' value={filter}
              sx={{ width: '100%' }}
              onChange={event => {
                let filter = event.target.value
                setFilter(filter)
                filter = filter.toLowerCase()

                if (filter.trim().length > 0)
                  setFiltered(produtos.filter(produto =>
                    produto.id.includes(filter)
                    || produto.descricao.toLowerCase().includes(filter)
                  ))
                else
                  setFiltered([])
              }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption'>Código ou descrição do produto</Typography>
          </Grid>
        </Grid>
        <List>
          {filtered.map((produto, index) => {
            const item = itens.find(value => value.produto === produto.id)

            return <>
              <ListItem sx={{
                backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
                m: '6px 0px 0px 5px',
                borderRadius: '6px'
              }}>
                <ListItemText
                  primary={<Grid container>
                    <Grid item xs={11}>
                      {`${produto.id} - ${produto.descricao}`}
                    </Grid>
                    <Grid item xs={1} textAlign='center'>
                      <Checkbox checked={!!item} onChange={(event, checked) => {
                        if (checked)
                          selectProduto(produto)
                        else
                          removeItem(item!)
                      }} />
                    </Grid>
                  </Grid>}
                  secondary={<>
                    {`1 ${produto.unidadePedido} = ${produto.quantidadeUnidade ?? 1} ${produto.unidade} | Unit: ${formatCurrency(produto.precoVenda ?? 0)} | TOTAL: ${formatCurrency(produto.precoUnitario)}`}
                    {item && <Grid container><ItemPedido item={item} itens={itens} setItens={setItens} /></Grid>}
                  </>}
                />
              </ListItem>
            </>
          })}
        </List>
      </Box>
    </Dialog>
  )
}