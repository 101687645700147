import { verifyLoggedAndSet } from '../utils'

export interface PedidoItem {
  idPedido: number
  id: number
  produto: string
  nome: string | null
  quantidade: number | null
  valorUnitario: number | null
  valorTotal: number | null
  volume: number | null
  unidade: string | null
  volumeTotalUnidades: string
  unidadeEmbalagem: string | null
}

export function getDefaultPedidoItem(): PedidoItem {
  return {
    idPedido: 0,
    id: 0,
    produto: '',
    nome: '',
    quantidade: 0,
    valorUnitario: 0,
    valorTotal: 0,
    volume: 0,
    unidade: '',
    volumeTotalUnidades: '',
    unidadeEmbalagem: ''
  }
}

export async function getPedidoItens(empresa: string, pedido: number, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: PedidoItem[]) => void, onError?: () => void) {
  return await verifyLoggedAndSet(`/pedido-itens/${empresa.padStart(5, '0')}/${pedido}`, setMensagem, setOpenMensagem, setter, onError)
}