import type { Perfil } from '../login'
import { verifyLoggedAndSet } from '../utils'

export interface Representante {
  id: number
  nome: string | null
  supervisor: number | null
}

export function getDefaultRepresentante(): Representante {
  return {
    id: 0,
    nome: '',
    supervisor: 0
  }
}

export async function getRepresentantes(empresa: string, perfil: keyof typeof Perfil, id: number, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: Representante[]) => void, onError?: () => void) {
  return await verifyLoggedAndSet(`/representantes/${empresa.padStart(5, '0')}/${perfil}/${id}`, setMensagem, setOpenMensagem, setter, onError)
}