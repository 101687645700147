import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import { Dialog, AppBar, IconButton, Toolbar, Typography, List, ListItem, ListItemText } from '@mui/material'
import type { LoginEmpresa } from './model/LoginEmpresa'
import { formatCnpj } from './utils'
import { Transition } from './transition'

export default function PesquisaEmpresas({ open, setOpen, empresas, setEmpresa }: { open: boolean, setOpen(value: boolean): void, empresas: LoginEmpresa[], setEmpresa(value: LoginEmpresa): void }) {
  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Pesquisa de Empresas
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{ width: 'calc(100% - 12px)' }}
      >
        <List dense>
          {empresas.map((empresa, index) =>
            <ListItem sx={{
              backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
              m: '6px 0px 0px 6px',
              borderRadius: '6px'
            }}
              secondaryAction={
                <IconButton edge="end" aria-label="ok" color='primary' onClick={() => {
                  setEmpresa(empresa)
                  setOpen(false)
                }} >
                  <CheckIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={empresa.razaoSocial}
                secondary={empresa.cnpj ? formatCnpj(empresa.cnpj) : ''}
              />
            </ListItem>
          )}
        </List>
      </Box>
    </Dialog>
  )
}