import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/material'

export function Loading({ open, setOpen, load }: { open: boolean, setOpen(value: boolean): void, load(): Promise<void> }) {
  const [loading, setLoading] = useState(false)

  async function _load() {
    if (!loading) {
      setLoading(true)
      await load()
      setOpen(false)
    }
  }

  _load()

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
      <Typography marginLeft='10px'>Carregando...</Typography>
    </Backdrop>
  )
}