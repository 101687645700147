import type { Perfil } from '../login'

export interface Vendedor {
  codigo: number
  nome: string | null
  apelido: string | null
  loginWeb: string | null
  // senha: string | null
  comissao: number | null
  internet: string | null
  supervisor: number | null
  nomeSupervisor: string | null
  funcao: string | null
  empresa: string
  perfil: keyof typeof Perfil
  catalog: string,
  opcaoFederal: string | null
  simplesNacional: boolean
}

export function getDefaultVendedor(): Vendedor {
  return {
    codigo: 0,
    nome: '',
    apelido: '',
    loginWeb: null,
    comissao: 0,
    internet: '',
    supervisor: 0,
    nomeSupervisor: '',
    funcao: '',
    empresa: '',
    perfil: 'representante',
    catalog: '',
    opcaoFederal: null,
    simplesNacional: false
  }
}