import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './app'
import UAParser from 'ua-parser-js'

const userAgent = new UAParser
const device = userAgent.getDevice()
const isMobile = device.type === 'mobile'

if (isMobile)
  // @ts-expect-error
  globalThis.IS_MOBILE = true
else
  // @ts-expect-error
  globalThis.IS_MOBILE = false

let app = <App />

const root = createRoot(document.getElementById('app')!)
root.render(app)