import React, { useLayoutEffect, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { type Produto } from './model/Produto'

export default function SelecaoProduto({ produtos, produto, setProduto, hasFocus }: { produtos: Produto[], produto: Produto, setProduto(value: Produto | null): void, hasFocus: boolean }) {
  const input = useRef() as React.MutableRefObject<HTMLDivElement | null>

  useLayoutEffect(() => {
    if (hasFocus)
      input.current?.querySelector('input')?.focus()
  })

  return (
    <Autocomplete
      id="selecao-produto"
      sx={{ width: 440 }}
      options={produtos}
      autoHighlight
      value={produto}
      onChange={(e, produto) => {
        setProduto(produto)
      }}
      getOptionLabel={(produto) => produto.id ? `${produto.id} - ${produto.descricao}` : ''}
      renderInput={(params) => (
        // @ts-expect-error
        <TextField
          ref={input}
          {...params}
          label="Escolha o produto"
        />
      )}
    />
  )
}