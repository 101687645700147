export enum ItemMenu {
  Nenhum,
  Clientes,
  Cliente,
  NovoCliente,
  AlterarCliente,
  Produtos,
  Pedidos,
  Pedido,
  NovoPedido,
  Orcamentos,
  Orcamento,
  NovoOrcamento,
  Configuracao
}

export const descricaoMenu: Readonly<Record<ItemMenu, string | undefined>> = {
  [ItemMenu.Nenhum]: undefined,
  [ItemMenu.Clientes]: 'Clientes',
  [ItemMenu.Cliente]: 'Cliente',
  [ItemMenu.NovoCliente]: 'Novo Cliente',
  [ItemMenu.AlterarCliente]: 'Alterar Cliente',
  [ItemMenu.Produtos]: 'Produtos',
  [ItemMenu.Pedidos]: 'Pedidos',
  [ItemMenu.Pedido]: 'Pedido',
  [ItemMenu.NovoPedido]: 'Novo Pedido',
  [ItemMenu.Orcamentos]: 'Orçamentos',
  [ItemMenu.Orcamento]: 'Orçamento',
  [ItemMenu.NovoOrcamento]: 'Novo Orçamento',
  [ItemMenu.Configuracao]: 'Configuração'
}