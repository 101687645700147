import type { Perfil } from '../login'
import { verifyLoggedAndSet } from '../utils'

export interface Pedido {
  representante: number | null
  nome: string | null
  origem: 'APP' | 'WEB'
  supervisor: number | null
  id: number
  numeroInterno: string | null
  codigoCliente: number
  fantasia: string | null
  razaoSocial: string | null
  cnpj: string | null
  dataPedido: string
  horaPedido: string
  dataEntrega: string | null
  totalPedido: number
  pedidoCliente: string | null
  status: string | null
  identificacoes: string | null
  pagamento: string | null
  descricaoPagamento: string | null
  observacao: string | null
  endereco: string | null
  municipio: string | null
}

export function getDefaultPedido(): Pedido {
  return {
    representante: 0,
    nome: '',
    origem: 'WEB',
    supervisor: 0,
    id: 0,
    numeroInterno: '',
    codigoCliente: 0,
    fantasia: '',
    razaoSocial: '',
    cnpj: '',
    dataPedido: '',
    horaPedido: '',
    dataEntrega: '',
    totalPedido: 0,
    pedidoCliente: '',
    status: '',
    identificacoes: '',
    pagamento: '',
    descricaoPagamento: '',
    observacao: '',
    endereco: '',
    municipio: ''
  }
}

export async function getPedidos(empresa: string, perfil: keyof typeof Perfil, usuario: number, setMensagem: (value: string) => void, setOpenMensagem: (value: boolean) => void, setter: (value: Pedido[]) => void) {
  let pedidos: Pedido[] | undefined
  const result = await verifyLoggedAndSet<Pedido[]>(`/pedidos/${empresa.padStart(5, '0')}/${perfil}/${usuario}`, setMensagem, setOpenMensagem, (value) => pedidos = value)

  if (pedidos) {
    pedidos.forEach(v => {
      const data = new Date(v.dataPedido).toLocaleString('pt-BR')
      v.dataPedido = data.substring(0, 10)
      v.horaPedido = data.substring(12, 17)

      if (v.dataEntrega)
        v.dataEntrega = `${v.dataEntrega.substring(8, 10)}/${v.dataEntrega.substring(5, 7)}/${v.dataEntrega.substring(0, 4)}`
    })

    setter(pedidos)
  }

  return result
}