import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { Transition } from '../transition'
import type { Orcamento } from '../model/Orcamento'

export default function RelatorioOrcamento({ open, setOpen, empresa, orcamento }: { open: boolean, setOpen(value: boolean): void, empresa: string, orcamento: Orcamento }) {
  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: IS_MOBILE ? 'fixed' : 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Relatório de Orçamento
          </Typography>
        </Toolbar>
      </AppBar>
      {IS_MOBILE && <div style={{ height: '48px' }} />}
      <iframe style={{ height: 'calc(100vh - 48px)', width: '100%', border: 'none' }} src={`/relatorio-orcamento/${empresa}/${orcamento.id}/Orçamento ${orcamento.id} - ${orcamento.nomeCliente}`}></iframe>
    </Dialog>
  )
}