export interface LoginEmpresa {
  id: string
  codigoCliente: number
  razaoSocial: string
  status: string | null
  cnpj: string
  codigoEmpresaCliente: number
  catalog: string
}

export function getDefaultLoginEmpresa(): LoginEmpresa {
  return {
    id: '',
    codigoCliente: 0,
    razaoSocial: '',
    status: '',
    cnpj: '',
    codigoEmpresaCliente: 0,
    catalog: ''
  }
}