import { Grid, TextField } from '@mui/material'
import React from 'react'
import { formatCurrency } from '../utils'
import type { Orcamento } from '../model/Orcamento'

export function TotaisOrcamento(props: {
  disabled: boolean
  orcamento: Orcamento
}) {
  return <>
    <Grid item xs={6} md={2}>
      <TextField
        label='% Enc. Fin.'
        value={formatCurrency(props.orcamento.percEnc) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='$ Total Produtos'
        value={formatCurrency(props.orcamento.valorBruto) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='$ IPI'
        value={formatCurrency(props.orcamento.totalIpi) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='$ Frete'
        value={formatCurrency(props.orcamento.valorFrete) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='$ Desp. Acess.'
        value={formatCurrency(props.orcamento.valorEnc) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='$ Desconto'
        value={formatCurrency(props.orcamento.valorDesconto) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='$ Total Orçamento'
        value={formatCurrency(props.orcamento.valorTotal) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='Total ICMS'
        value={formatCurrency(props.orcamento.totalIcms) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='Total ICMS ST'
        value={formatCurrency(props.orcamento.totalIcmsST) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='Total PIS'
        value={formatCurrency(props.orcamento.totalPis) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}>
      <TextField
        label='Total COFINS'
        value={formatCurrency(props.orcamento.totalCofins) || ' '}
        variant={IS_MOBILE ? 'standard' : 'outlined'}
        sx={{
          m: '5px',
          width: 'calc(100% - 10px)'
        }}
        size='small'
        disabled
      />
    </Grid>
    <Grid item xs={6} md={2}></Grid>
  </>
}