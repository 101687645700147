import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material'

export function Sexo(props: {
  disabled: boolean
  sexo: string | null
  setSexo?(value: string | null): void
  required?: boolean
}) {
  function handleChangeSexo(event: SelectChangeEvent) {
    props.setSexo?.(event.target.value)
  }

  return <FormControl sx={{
    m: '5px',
    width: 'calc(100% - 10px)'
  }} variant={IS_MOBILE ? 'standard' : 'outlined'} size='small'>
    <InputLabel id="sexo-label">Sexo</InputLabel>
    <Select
      labelId="sexo-label"
      id="sexo-select"
      value={props.sexo || ' '}
      label="Sexo"
      onChange={handleChangeSexo}
      disabled={props.disabled}
      error={!!props.required && !props.sexo}
      sx={IS_MOBILE ? { p: '9px 0px 2px 0px' } : {}}
    >
      <MenuItem value={'M'}>Masculino</MenuItem>
      <MenuItem value={'F'}>Feminino</MenuItem>
      <MenuItem value={'N'}>Não Informado</MenuItem>
    </Select>
  </FormControl>
}