import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import type { Pedido } from './model/Pedido'
import { Transition } from './transition'

export default function RelatorioPedido({ open, setOpen, empresa, pedido }: { open: boolean, setOpen(value: boolean): void, empresa: string, pedido: Pedido }) {
  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: IS_MOBILE ? 'fixed' : 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Relatório de Pedido
          </Typography>
        </Toolbar>
      </AppBar>
      {IS_MOBILE && <div style={{ height: '48px' }} />}
      <iframe style={{ height: 'calc(100vh - 48px)', width: '100%', border: 'none' }} src={`/relatorio-pedido/${empresa}/${pedido.id}/Pedido ${pedido.id} - ${pedido.fantasia}`}></iframe>
    </Dialog>
  )
}