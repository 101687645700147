import { ThemeProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import createTheme from '@mui/material/styles/createTheme'
import { ptBR as ptBRdg } from '@mui/x-data-grid/locales/ptBR'
import { ptBR as ptBRdp } from '@mui/x-date-pickers/locales/ptBR'
import React, { useState } from 'react'
import MenuAppBar from './app-bar'
import Menu from './menu'
import { ItemMenu } from './item-menu'
import Clientes from './clientes/clientes'
import Abas from './abas'
import Login, { Perfil } from './login'
import { getDefaultVendedor, type Vendedor } from './model/Vendedor'
import PedidosVendas from './pedidos-vendas'
import PedidosVendasItens from './pedidos-vendas-itens'
import Produtos from './produtos'
import PedidoVenda from './pedido-venda'
import { getDefaultDestinatario, getDestinatarios, type Destinatario } from './model/Destinatario'
import type { FormaPagamento } from './model/FormaPagamento'
import type { Transportadora } from './model/Transportadora'
import 'dayjs/locale/pt-br'
import type { Produto } from './model/Produto'
import { getDefaultPedido, getPedidos, type Pedido } from './model/Pedido'
import Mensagem from './mensagem'
import type { Representante } from './model/Representante'
import Drawer from '@mui/material/Drawer'
import DetalhePedido from './detalhe-pedido'
import RelatorioPedido from './relatorio-pedido'
import { ConsultaOrcamentos, getConsultaOrcamentos, getConsultaOrcamentosItens } from './orcamentos/consulta'
import { getDefaultOrcamento, getDefaultOrcamentoItem, type Orcamento, type OrcamentoItem/*, type OrcamentoServico*/ } from './model/Orcamento'
import { OrcamentoDialog } from './orcamentos/orcamento-dialog'
import { AbaOrcamento } from './orcamentos/aba-orcamento'
import type { ProdutoDetalheOrcamento } from './model/ProdutoDetalheOrcamento'
import type { LocalEntrega } from './model/LocalEntrega'
import type { Contato } from './model/Contato'
import { verifyLoggedAndSet } from './utils'
import { Configuracao } from './configuracao'
import { IDTela, getConfiguracoes, type Configuracoes } from './model/Configuracao'
import type { TributacaoProduto } from './model/TributacaoProduto'
import { DadosCliente } from './clientes/cliente'
import { Acao } from './acao'
import type { Tipo } from './model/Tipo'
import type { Pais } from './model/Pais'
import type { Municipio } from './model/Municipio'
import type { FormaDesconto } from './model/FormaDesconto'
import type { Remetente } from './model/Remetente'
import RelatorioOrcamento from './orcamentos/relatorio-orcamento'

const theme = createTheme({
  palette: {
    mode: 'light'
  }
}, ptBRdg, ptBRdp)

export default function App() {
  const [showMenu, setShowMenu] = useState(!IS_MOBILE)
  const [logged, setLogged] = useState(false)
  const [empresa, setEmpresa] = useState('')
  let index = document.cookie.indexOf('nomeEmpresa=')
  let index2 = document.cookie.indexOf(';', index + 1)
  const [nomeEmpresa, setNomeEmpresa] = useState(index === -1 ? '' : document.cookie.substring(index + 12, index2 === -1 ? undefined : index2))
  const [perfil, setPerfil] = useState('supervisor' as keyof typeof Perfil)
  const [id, setId] = useState(0)
  const [menu, setMenu] = useState(ItemMenu.Nenhum)
  const [abas, setAbas] = useState([] as ItemMenu[])
  const [pedido, setPedido] = useState(0)
  const [vendedor, setVendedor] = useState(getDefaultVendedor())
  const [clientes, setClientes] = useState([] as Destinatario[])
  const [clientesOrcamentos, setClientesOrcamentos] = useState([] as Destinatario[])
  const [visualizarCliente, setVisualizarCliente] = useState(getDefaultDestinatario())
  const [novoCliente, setNovoCliente] = useState(getDefaultDestinatario())
  const [tipos, setTipos] = useState([] as Tipo[])
  const [procuraPais, setProcuraPais] = useState(false)
  const [procuraMunicipio, setProcuraMunicipio] = useState(false)
  const [paises, setPaises] = useState([] as Pais[])
  const [municipios, setMunicipios] = useState([] as Municipio[])
  const [formasPagamento, setFormasPagamento] = useState([] as FormaPagamento[])
  const [formasDesconto, setFormasDesconto] = useState([] as FormaDesconto[])
  const [remetentes, setRemetentes] = useState([] as Remetente[])
  const [transportadoras, setTransportadoras] = useState([] as Transportadora[])
  const [produtos, setProdutos] = useState([] as Produto[])
  const [pedidos, setPedidos] = useState([] as Pedido[])
  const [mensagem, setMensagem] = useState('')
  const [openMensagem, setOpenMensagem] = useState(false)
  const [tentouRelogar, setTentouRelogar] = useState(false)
  const [representantes, setRepresentantes] = useState([] as Representante[])
  const [pedidoRelatorio, setPedidoRelatorio] = useState(getDefaultPedido())
  const [openRelatorio, setOpenRelatorio] = useState(false)
  const [orcamentoRelatorio, setOrcamentoRelatorio] = useState(getDefaultOrcamento())
  const [openRelatorioOrcamento, setOpenRelatorioOrcamento] = useState(false)
  const [orcamentos, setOrcamentos] = useState([] as Orcamento[])
  const [orcamentosItens, setOrcamentosItens] = useState([] as OrcamentoItem[])
  // const [orcamentosServicos, setOrcamentosServicos] = useState([] as OrcamentoServico[])
  const [orcamento, setOrcamento] = useState(getDefaultOrcamento())
  const [orcamentoItens, setOrcamentoItens] = useState([] as OrcamentoItem[])
  // const [orcamentoServicos, setOrcamentoServicos] = useState([] as OrcamentoServico[])
  const [novoOrcamento, setNovoOrcamento] = useState(getDefaultOrcamento())
  const [novoOrcamentoItens, setNovoOrcamentoItens] = useState([getDefaultOrcamentoItem()])
  // const [novoOrcamentoServicos, setNovoOrcamentoServicos] = useState([] as OrcamentoServico[])
  const [openOrcamento, setOpenOrcamento] = useState(false)
  const [openNovoOrcamento, setOpenNovoOrcamento] = useState(false)
  const [abaOrcamento, setAbaOrcamento] = useState(AbaOrcamento.Dados)
  const [abaNovoOrcamento, setAbaNovoOrcamento] = useState(AbaOrcamento.Dados)
  const [produtoDetalheOrcamentos, setProdutoDetalheOrcamentos] = useState([] as ProdutoDetalheOrcamento[])
  const [locaisEntrega, setLocaisEntrega] = useState([] as LocalEntrega[])
  const [openCliente, setOpenCliente] = useState(false)
  const [todosRepresentantes, setTodosRepresentantes] = useState([] as Representante[])
  const [contatos, setContatos] = useState([] as Contato[])
  index = document.cookie.indexOf('cnpj=')
  const [cnpj, setCnpj] = useState(index === -1 ? '02756348000139' : document.cookie.substring(index + 5, index + 19))
  const [disabled, setDisabled] = useState(false)
  const [acaoOrcamento, setAcaoOrcamento] = useState(Acao.Nenhuma)
  index = document.cookie.indexOf('cliente=')
  index2 = document.cookie.indexOf(';', index + 1)
  const [cliente, setCliente] = useState(index === -1 ? '' : document.cookie.substring(index + 8, index2 === -1 ? undefined : index2))
  const [configuracoes, setConfiguracoes] = useState(null! as Configuracoes)
  const [tributacaoProdutos, setTributacaoProdutos] = useState([] as TributacaoProduto[])
  const [mostrarClientesOrcamentos, setMostrarClientesOrcamentos] = useState(false)

  function _setConfiguracoes(value: Configuracoes) {
    setConfiguracoes(value)

    if (IS_MOBILE)
      if (value.telas.includes(IDTela.Pedidos)) {
        setMenu(ItemMenu.Pedidos)
        setAbas([ItemMenu.Pedidos])
      } else if (value.telas.includes(IDTela.Orcamentos)) {
        setMenu(ItemMenu.Orcamentos)
        setAbas([ItemMenu.Orcamentos])
      }

    setMostrarClientesOrcamentos(
      value.telas.includes(IDTela.Orcamentos) && !value.filtrarClientesPorRepresentanteOrcamentos
    )
  }

  function showReport(pedido: Pedido) {
    setPedidoRelatorio(pedido)
    setOpenRelatorio(true)
  }

  function showOrcamentoReport(orcamento: Orcamento) {
    setOrcamentoRelatorio(orcamento)
    setOpenRelatorioOrcamento(true)
  }

  function fechaMensagem() {
    setOpenMensagem(false)
  }

  async function logar(usuario: string, senha: string, codigoEmpresa: string, nomeEmpresa: string, perfil: keyof typeof Perfil, catalog: string, cliente: number) {
    const username = encodeURI(`${codigoEmpresa}\n${perfil}\n${usuario}\n${catalog}`)
    senha = encodeURI(senha)

    try {
      const conexao = await fetch(`/login?username=${username}&password=${senha}`, {
        method: 'post'
      })

      if (conexao.status === 200) {
        await getConfiguracoes(codigoEmpresa, cliente.toString(), setMensagem, setOpenMensagem, _setConfiguracoes)

        const vendedor: Vendedor = await conexao.json()

        setEmpresa(codigoEmpresa)
        setNomeEmpresa(nomeEmpresa)
        document.cookie = `nomeEmpresa=${nomeEmpresa}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Strict; Secure`
        setCliente(cliente.toString())
        document.cookie = `cliente=${cliente}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Strict; Secure`
        setPerfil(perfil)
        setId(vendedor.codigo)
        setVendedor(vendedor)
        setLogged(true)
        document.cookie = 'logado=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Strict; Secure'
      } else if (conexao.status === 401) {
        setMensagem('Dados de login inválidos')
        setOpenMensagem(true)
      } else {
        setMensagem(`Ocorreu um erro ao tentar logar.\nErro ${conexao.status} - ${conexao.statusText}.`)
        setOpenMensagem(true)
      }
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor.\nVerifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)

      setOpenMensagem(true)
    }
  }

  async function relogar() {
    setTentouRelogar(true)

    if (!document.cookie.includes('logado=true'))
      return

    try {
      const result = await fetch('/usuario')

      if (result.status === 200) {
        const usuario: Vendedor = await result.json()

        await getConfiguracoes(usuario.empresa, cliente.toString(), setMensagem, setOpenMensagem, _setConfiguracoes)

        setEmpresa(usuario.empresa)
        setPerfil(usuario.perfil)
        setId(usuario.codigo)
        setVendedor(usuario)
        setLogged(true)
      } else if (result.status === 404 && result.redirected && result.url.endsWith('/login'))
        document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor.\nVerifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)

      setOpenMensagem(true)
    }
  }

  tentouRelogar || logged || relogar()

  async function deslogar() {
    try {
      const result = await fetch('/logout', { method: 'post' })

      if (result.status === 200) {
        const json: { logged: boolean } = await result.json()

        if (json.logged === false) {
          document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
          location.reload()

          return
        }
      } else if (result.status === 404 && result.redirected && result.url.endsWith('/login')) {
        document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
        setMensagem('Você está deslogado.\nFavor relogar.')
        setOpenMensagem(true)

        return
      }

      setMensagem(`Erro ao tentar deslogar.\nErro ${result.status} - ${result.statusText}.`)
      setOpenMensagem(true)
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor.\nVerifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)

      setOpenMensagem(true)
    }
  }

  function execMenu(menuItem: ItemMenu) {
    setMenu(menuItem)

    if (!abas.includes(menuItem))
      setAbas([...abas, menuItem])

    if (IS_MOBILE)
      setShowMenu(false)
  }

  function removeAba(aba: ItemMenu, parent?: ItemMenu) {
    const index = abas.indexOf(aba)

    if (IS_MOBILE && menu === ItemMenu.NovoPedido)
      setMenu(ItemMenu.Pedidos)
    else if (IS_MOBILE && (menu === ItemMenu.NovoOrcamento || menu === ItemMenu.Orcamento))
      setMenu(ItemMenu.Orcamentos)
    else if (IS_MOBILE && menu === ItemMenu.Cliente)
      setMenu(ItemMenu.Clientes)
    else if (IS_MOBILE && menu === ItemMenu.NovoCliente) {
      if (parent === ItemMenu.NovoOrcamento)
        setMenu(ItemMenu.Orcamentos)
      else
        setMenu(ItemMenu.Clientes)
    } else if (index > 0)
      setMenu(abas[index - 1])
    else if (abas.length > 1)
      setMenu(abas[index + 1])
    else
      setMenu(ItemMenu.Nenhum)

    const values = abas.slice()
    values.splice(index, 1)
    setAbas(values)
  }

  async function recarregaPedidos() {
    await getPedidos(empresa, perfil, vendedor.codigo, setMensagem, setOpenMensagem, setPedidos)
  }

  async function recarregaOrcamentos() {
    const consultaOrcamentos = getConsultaOrcamentos()

    if (consultaOrcamentos) {
      await verifyLoggedAndSet(consultaOrcamentos, setMensagem, setOpenMensagem, setOrcamentos)
      await verifyLoggedAndSet(getConsultaOrcamentosItens(), setMensagem, setOpenMensagem, setOrcamentosItens)
    }
  }

  async function recarregarClientes() {
    await getDestinatarios(empresa, configuracoes.filtrarClientesPorRepresentanteOrcamentos ? perfil : 'gerencia', vendedor.codigo, setMensagem, setOpenMensagem, mostrarClientesOrcamentos ? setClientesOrcamentos : setClientes, mostrarClientesOrcamentos ? ['A', 'T', 'G'] : ['A'])
  }

  async function recarregarClientesOrcamentos() {
    await getDestinatarios(empresa, configuracoes.filtrarClientesPorRepresentanteOrcamentos ? perfil : 'gerencia', vendedor.codigo, setMensagem, setOpenMensagem, setClientesOrcamentos, ['A', 'T', 'G'])
  }

  function adicionarCliente() {
    setNovoCliente({
      ...getDefaultDestinatario(),
      representante: vendedor.codigo.toString(),
      nomeRepresentante: vendedor.nome,
      representanteNome: `${vendedor.codigo}${vendedor.nome ? ` - ${vendedor.nome}` : ''}`
    })

    setOpenCliente(true)

    if (!abas.includes(ItemMenu.NovoCliente))
      execMenu(ItemMenu.NovoCliente)
  }

  function adicionarClienteMobile() {
    setNovoCliente({
      ...getDefaultDestinatario(),
      representante: vendedor.codigo.toString(),
      nomeRepresentante: vendedor.nome,
      representanteNome: `${vendedor.codigo}${vendedor.nome ? ` - ${vendedor.nome}` : ''}`
    })

    execMenu(ItemMenu.NovoCliente)
  }

  function procurarPais() {
    setProcuraPais(true)
  }

  function procurarMunicipio() {
    setProcuraMunicipio(true)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {IS_MOBILE ? <div style={{ height: '48px' }}></div> : undefined}
      {logged ?
        <>
          <MenuAppBar
            toggleMenu={() => {
              setShowMenu(!showMenu)
            }}
            logout={deslogar}
            usuario={vendedor}
            menu={menu} setMenu={execMenu}
            recarregaPedidos={recarregaPedidos}
            recarregaClientes={configuracoes.editarClientes ? recarregarClientes : undefined}
            novoCliente={configuracoes.editarClientes ? adicionarClienteMobile : undefined}
            setOpenNovoOrcamento={setOpenNovoOrcamento}
            setAcaoOrcamento={setAcaoOrcamento}
          />
          <Grid container>
            {IS_MOBILE
              ? <Drawer
                anchor='left'
                open={showMenu}
                onClose={(event, reason) => {
                  setShowMenu(false)
                }}
              >
                <Menu setMenu={execMenu} menus={abas} logout={deslogar} vendedor={vendedor} configuracoes={configuracoes} perfil={perfil} />
              </Drawer>
              : <Grid item md={2} sx={showMenu ? {} : { display: 'none' }}>
                <Menu setMenu={execMenu} menus={abas} logout={deslogar} vendedor={vendedor} configuracoes={configuracoes} perfil={perfil} />
              </Grid>
            }
            <Grid item md={10 + (IS_MOBILE || !showMenu ? 2 : 0)} sx={IS_MOBILE ? { width: 'calc(100% - 2px)' } : {}}>
              {IS_MOBILE || <Abas abas={abas} aba={menu} setMenu={setMenu} />}
              {
                abas.includes(ItemMenu.Clientes)
                  ? <Clientes
                    empresa={empresa}
                    vendedor={vendedor}
                    perfil={mostrarClientesOrcamentos ? 'gerencia' : perfil}
                    id={id}
                    visible={menu === ItemMenu.Clientes}
                    clientes={mostrarClientesOrcamentos ? clientesOrcamentos : clientes}
                    setClientes={mostrarClientesOrcamentos ? setClientesOrcamentos : setClientes}
                    setMensagem={setMensagem} setOpenMensagem={setOpenMensagem}
                    consfiguracoes={configuracoes}
                    abas={abas}
                    setMenu={execMenu}
                    recarregar={recarregarClientes}
                    novoCliente={novoCliente} setNovoCliente={setNovoCliente}
                    setVisualizarCliente={setVisualizarCliente}
                    tipos={tipos} setTipos={setTipos}
                    paises={paises} setPaises={setPaises}
                    municipios={municipios} setMunicipios={setMunicipios}
                    todosRepresentantes={todosRepresentantes} setTodosRepresentantes={setTodosRepresentantes}
                    formasPagamento={formasPagamento} setFormasPagamento={setFormasPagamento}
                    formasDesconto={formasDesconto} setFormasDesconto={setFormasDesconto}
                    // modosFaturamento={modosFaturamento} setModosFaturamento={setModosFaturamento}
                    remetentes={remetentes} setRemetentes={setRemetentes}
                    locaisEntrega={locaisEntrega} setLocaisEntrega={setLocaisEntrega}
                    configuracoes={configuracoes}
                  />
                  : undefined
              }
              {abas.includes(ItemMenu.Cliente) &&
                <DadosCliente
                  disabled={true}
                  visible={menu === ItemMenu.Cliente}
                  usuario={vendedor}
                  cliente={visualizarCliente} setCliente={setVisualizarCliente}
                  aba={ItemMenu.Cliente}
                  removeAba={removeAba}
                  tipos={tipos}
                  paises={paises}
                  procuraPais={procuraPais} setProcuraPais={setProcuraPais}
                  procurarPais={procurarPais}
                  municipios={municipios}
                  procuraMunicipio={procuraMunicipio} setProcuraMunicipio={setProcuraMunicipio}
                  procurarMunicipio={procurarMunicipio}
                  representantes={todosRepresentantes}
                  formasPagamento={formasPagamento}
                  formasDesconto={formasDesconto}
                  // modosFaturamento={modosFaturamento}
                  // remetentes={remetentes}
                  locaisEntrega={locaisEntrega}
                  recarregaClientes={recarregarClientes}
                  open={openCliente} setOpen={setOpenCliente}
                  codigoCliente={cliente}
                />
              }
              {(abas.includes(ItemMenu.NovoCliente) || abas.includes(ItemMenu.AlterarCliente)) &&
                <DadosCliente
                  disabled={disabled}
                  setDisabled={setDisabled}
                  visible={menu === ItemMenu.NovoCliente || menu === ItemMenu.AlterarCliente}
                  usuario={vendedor}
                  cliente={novoCliente} setCliente={setNovoCliente}
                  aba={menu}
                  removeAba={removeAba}
                  tipos={tipos}
                  paises={paises}
                  procuraPais={procuraPais} setProcuraPais={setProcuraPais}
                  procurarPais={procurarPais}
                  municipios={municipios}
                  procuraMunicipio={procuraMunicipio} setProcuraMunicipio={setProcuraMunicipio}
                  procurarMunicipio={procurarMunicipio}
                  representantes={todosRepresentantes}
                  formasPagamento={formasPagamento}
                  formasDesconto={formasDesconto}
                  // modosFaturamento={modosFaturamento}
                  // remetentes={remetentes}
                  locaisEntrega={locaisEntrega}
                  recarregaClientes={recarregarClientes}
                  open={openCliente} setOpen={setOpenCliente}
                  codigoCliente={cliente}
                />
              }
              {
                abas.includes(ItemMenu.Produtos)
                  ? <Produtos empresa={empresa} visible={menu === ItemMenu.Produtos} produtos={produtos} setProdutos={setProdutos} setMensagem={setMensagem} setOpenMensagem={setOpenMensagem} />
                  : undefined
              }
              {
                abas.includes(ItemMenu.Pedidos)
                  ? <>
                    <PedidosVendas empresa={empresa} perfil={perfil}
                      usuario={id} visible={menu === ItemMenu.Pedidos}
                      setPedido={setPedido} setMenu={execMenu} pedidos={pedidos} setPedidos={setPedidos} setMensagem={setMensagem} setOpenMensagem={setOpenMensagem} showReport={showReport} />
                    {IS_MOBILE
                      ? undefined
                      : <PedidosVendasItens empresa={empresa} pedido={pedido} visible={menu === ItemMenu.Pedidos} setMensagem={setMensagem} setOpenMensagem={setOpenMensagem} />
                    }
                  </>
                  : undefined
              }
              {
                abas.includes(ItemMenu.NovoPedido)
                  ? <PedidoVenda
                    empresa={empresa}
                    nomeEmpresa={nomeEmpresa}
                    perfil={perfil}
                    vendedor={vendedor}
                    visible={menu === ItemMenu.NovoPedido}
                    todosClientes={clientes} setTodosClientes={setClientes}
                    formasPagamento={formasPagamento} setFormasPagamento={setFormasPagamento}
                    transportadoras={transportadoras} setTransportadoras={setTransportadoras}
                    produtos={produtos} setProdutos={setProdutos}
                    removeAba={removeAba}
                    recarregaPedidos={recarregaPedidos}
                    representantes={representantes} setRepresentantes={setRepresentantes}
                    abas={abas}
                  />
                  : undefined
              }
              {abas.includes(ItemMenu.Pedido) &&
                <DetalhePedido
                  empresa={empresa} pedido={pedidos.find(p => p.id === pedido) ?? getDefaultPedido()}
                  setMenu={setMenu} visible={menu === ItemMenu.Pedido}
                  setMensagem={setMensagem} setOpenMensagem={setOpenMensagem}
                />
              }
              {abas.includes(ItemMenu.Configuracao) &&
                <Configuracao
                  cliente={cliente}
                  empresa={empresa}
                  visible={menu === ItemMenu.Configuracao}
                  setMensagem={setMensagem}
                  setOpenMensagem={setOpenMensagem}
                  configuracoes={configuracoes} setConfiguracoes={setConfiguracoes}
                  removeAba={removeAba}
                />
              }
              {abas.includes(ItemMenu.Orcamentos) &&
                <ConsultaOrcamentos
                  empresa={empresa}
                  perfil={perfil}
                  vendedor={vendedor}
                  visible={menu === ItemMenu.Orcamentos}
                  todosClientes={clientesOrcamentos} setTodosClientes={setClientesOrcamentos}
                  formasPagamento={formasPagamento} setFormasPagamento={setFormasPagamento}
                  transportadoras={transportadoras} setTransportadoras={setTransportadoras}
                  orcamentos={orcamentos} setOrcamentos={setOrcamentos}
                  orcamentosItens={orcamentosItens} setOrcamentosItens={setOrcamentosItens}
                  // orcamentosServicos={orcamentosServicos} setOrcamentosServicos={setOrcamentosServicos}
                  setMenu={execMenu}
                  setOrcamento={setOrcamento}
                  itens={orcamentoItens} setItens={setOrcamentoItens}
                  // servicos={orcamentoServicos} setServicos={setOrcamentoServicos}
                  setOpenOrcamento={setOpenOrcamento}
                  setOpenNovoOrcamento={setOpenNovoOrcamento}
                  setAbaOrcamento={setAbaOrcamento}
                  setAbaNovoOrcamento={setAbaNovoOrcamento}
                  produtoDetalheOrcamentos={produtoDetalheOrcamentos} setProdutoDetalheOrcamentos={setProdutoDetalheOrcamentos}
                  locaisEntrega={locaisEntrega} setLocaisEntrega={setLocaisEntrega}
                  representantes={representantes} setRepresentantes={setRepresentantes}
                  todosRepresentantes={todosRepresentantes} setTodosRepresentantes={setTodosRepresentantes}
                  contatos={contatos} setContatos={setContatos}
                  produtos={produtos} setProdutos={setProdutos}
                  acaoOrcamento={acaoOrcamento} setAcaoOrcamento={setAcaoOrcamento}
                  setNovoOrcamento={setNovoOrcamento} setNovoItens={setNovoOrcamentoItens}
                  tributacaoProdutos={tributacaoProdutos} setTributacaoProdutos={setTributacaoProdutos}
                  configuracoes={configuracoes}
                  abas={abas}
                  showReport={showOrcamentoReport}
                />
              }
              {openOrcamento &&
                <OrcamentoDialog
                  disabled
                  empresa={empresa}
                  perfil={perfil}
                  orcamento={orcamento} setOrcamento={setOrcamento}
                  orcamentoItens={orcamentoItens} setOrcamentoItens={setOrcamentoItens}
                  // orcamentoServicos={orcamentoServicos} setOrcamentoServicos={setOrcamentoServicos}
                  todosClientes={clientesOrcamentos} setTodosClientes={setClientesOrcamentos}
                  formasPagamento={formasPagamento} setFormasPagamento={setFormasPagamento}
                  transportadoras={transportadoras} setTransportadoras={setTransportadoras}
                  open={openOrcamento} setOpen={setOpenOrcamento}
                  aba={abaOrcamento} setAba={setAbaOrcamento}
                  produtoDetalheOrcamentos={produtoDetalheOrcamentos} setProdutoDetalheOrcamentos={setProdutoDetalheOrcamentos}
                  locaisEntrega={locaisEntrega}
                  representantes={representantes} setRepresentantes={setRepresentantes}
                  todosRepresentantes={todosRepresentantes}
                  contatos={contatos}
                  produtos={produtos} setProdutos={setProdutos}
                  setMensagem={setMensagem} setOpenMensagem={setOpenMensagem}
                  cnpj={cnpj}
                  usuario={vendedor}
                  recarregaOrcamentos={recarregaOrcamentos}
                  acaoOrcamento={acaoOrcamento} setAcaoOrcamento={setAcaoOrcamento}
                  tributacaoProdutos={tributacaoProdutos}
                  recarregarClientes={recarregarClientesOrcamentos}
                  novoCliente={configuracoes.editarClientes ? adicionarCliente : undefined}
                  abas={abas}
                />
              }
              {openNovoOrcamento &&
                <OrcamentoDialog
                  disabled={disabled}
                  setDisabled={setDisabled}
                  empresa={empresa}
                  perfil={perfil}
                  orcamento={novoOrcamento} setOrcamento={setNovoOrcamento}
                  orcamentoItens={novoOrcamentoItens} setOrcamentoItens={setNovoOrcamentoItens}
                  // orcamentoServicos={novoOrcamentoServicos} setOrcamentoServicos={setNovoOrcamentoServicos}
                  todosClientes={clientesOrcamentos} setTodosClientes={setClientesOrcamentos}
                  formasPagamento={formasPagamento} setFormasPagamento={setFormasPagamento}
                  transportadoras={transportadoras} setTransportadoras={setTransportadoras}
                  open={openNovoOrcamento} setOpen={setOpenNovoOrcamento}
                  aba={abaNovoOrcamento} setAba={setAbaNovoOrcamento}
                  produtoDetalheOrcamentos={produtoDetalheOrcamentos} setProdutoDetalheOrcamentos={setProdutoDetalheOrcamentos}
                  locaisEntrega={locaisEntrega}
                  representantes={representantes} setRepresentantes={setRepresentantes}
                  todosRepresentantes={todosRepresentantes}
                  contatos={contatos}
                  produtos={produtos} setProdutos={setProdutos}
                  setMensagem={setMensagem} setOpenMensagem={setOpenMensagem}
                  cnpj={cnpj}
                  usuario={vendedor}
                  recarregaOrcamentos={recarregaOrcamentos}
                  acaoOrcamento={acaoOrcamento} setAcaoOrcamento={setAcaoOrcamento}
                  tributacaoProdutos={tributacaoProdutos}
                  recarregarClientes={recarregarClientesOrcamentos}
                  novoCliente={configuracoes.editarClientes ? adicionarCliente : undefined}
                  abas={abas}
                />
              }
              {openRelatorio && <RelatorioPedido open={openRelatorio} setOpen={setOpenRelatorio} empresa={empresa} pedido={pedidoRelatorio} />}
              {openRelatorioOrcamento && <RelatorioOrcamento open={openRelatorioOrcamento} setOpen={setOpenRelatorioOrcamento} empresa={empresa} orcamento={orcamentoRelatorio} />}
            </Grid>
          </Grid>
        </> :
        <Login
          onLogin={logar}
          setMensagem={setMensagem} setOpenMensagem={setOpenMensagem}
          cnpj={cnpj} setCnpj={setCnpj}
        />
      }
      <Mensagem titulo='Gerenciador de Vendas' mensagem={mensagem} open={openMensagem} ok={fechaMensagem} id='pedidos-web' />
    </ThemeProvider>
  )
}