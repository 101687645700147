import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, useGridApiRef, type GridColDef } from '@mui/x-data-grid'
import { getPedidoItens, type PedidoItem } from './model/PedidoItem'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID Item',
    width: 80,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'produto',
    headerName: 'Produto',
    width: 70
  },
  {
    field: 'nome',
    headerName: 'Descrição Produto',
    width: 450
  },
  {
    field: 'volume',
    headerName: 'Volume Caixa',
    width: 100,
    align: 'right',
    headerAlign: 'right',
    valueGetter(params) {
      return `${(params.value as number).toLocaleString('pt-BR')}`
    }
  },
  {
    field: 'unidade',
    headerName: 'Unidade',
    width: 70
  },
  {
    field: 'quantidade',
    headerName: 'Quantidade',
    width: 90,
    align: 'right',
    headerAlign: 'right',
    valueGetter(params) {
      return `${(params.value as number).toLocaleString('pt-BR')}`
    }
  },
  {
    field: 'volumeTotalUnidades',
    headerName: 'Volume em Unidades',
    width: 160,
    align: 'right',
    headerAlign: 'right',
    valueGetter(params) {
      return `${(params.value as number).toLocaleString('pt-BR')}`
    }
  },
  {
    field: 'valorUnitario',
    headerName: '$ Unitário',
    width: 100,
    align: 'right',
    headerAlign: 'right',
    valueGetter(params) {
      return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
    }
  },
  {
    field: 'valorTotal',
    headerName: '$ TOTAL',
    width: 120,
    align: 'right',
    headerAlign: 'right',
    valueGetter(params) {
      return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
    }
  }
]

let pedidos: Record<number, PedidoItem[]> = {}
let pedidoItens: PedidoItem[] = []

export default function PedidosVendasItens({ empresa, pedido, visible, setMensagem, setOpenMensagem }: { empresa: string, pedido: number, visible: boolean, setMensagem(value: string): void, setOpenMensagem(value: boolean): void }) {
  const [loaded, setLoaded] = useState(0)
  const apiRef = useGridApiRef()

  function _setPedidoItens(value: PedidoItem[]) {
    pedidos[pedido] = value
    pedidoItens = value
  }

  async function load() {
    if (pedido) {
      if (loaded !== pedido) {
        setLoaded(pedido)

        if (pedidos[pedido])
          pedidoItens = pedidos[pedido]
        else
          await getPedidoItens(empresa, pedido, setMensagem, setOpenMensagem, _setPedidoItens, () => pedidoItens = [])
      }
    } else
      pedidoItens = []

    apiRef.current.setRows?.(pedidoItens)
  }

  load()

  return (
    <Box sx={{
      height: 'calc((100vh - 48px - 48px) / 2)',
      width: '100%',
      ...(!visible ? { display: 'none' } : {})
    }}>
      <DataGrid apiRef={apiRef}
        columns={columns}
        rows={pedidoItens}
        columnHeaderHeight={25}
        rowHeight={25}
      />
    </Box>
  )
}