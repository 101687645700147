import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Button } from '@mui/material'
import type { Vendedor } from './model/Vendedor'
import { ItemMenu, descricaoMenu } from './item-menu'
import { Acao } from './acao'

export default function MenuAppBar(props: {
  toggleMenu(): void
  logout(): void
  usuario: Vendedor
  menu: ItemMenu
  setMenu(value: ItemMenu): void
  recarregaPedidos(): void
  recarregaClientes?: (() => void) | undefined
  novoCliente?: (() => void) | undefined
  setOpenNovoOrcamento(value: boolean): void
  setAcaoOrcamento(value: Acao): void
}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position={IS_MOBILE ? 'fixed' : 'static'}>
        <Toolbar variant='dense'>
          {IS_MOBILE && [ItemMenu.NovoCliente, ItemMenu.AlterarCliente].includes(props.menu) ? undefined :
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                if (props.menu === ItemMenu.Pedido)
                  props.setMenu(ItemMenu.Pedidos)
                else if (props.menu === ItemMenu.Orcamento)
                  props.setMenu(ItemMenu.Orcamentos)
                else
                  props.toggleMenu()
              }}
            >
              {IS_MOBILE && [ItemMenu.Cliente, ItemMenu.Pedido, ItemMenu.Orcamento].includes(props.menu)
                ? <ArrowBackIcon />
                : <MenuIcon />
              }
            </IconButton>
          }
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textWrap: 'nowrap' }}>
            {IS_MOBILE ? descricaoMenu[props.menu] ?? 'Gerenciador de Vendas' : 'Gerenciador de Vendas'}
          </Typography>
          {IS_MOBILE ||
            <>
              <Box sx={{ display: { xs: 'none', sm: 'block', flexGrow: 1, width: 'calc(100% - 400px)', textAlign: 'center' } }}>
                <Typography
                  variant="subtitle1" component="div" sx={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {props.usuario.funcao === 'S'
                    ? `${props.usuario.codigo} - Supervisor ${props.usuario.nome}`
                    : props.usuario.funcao === 'G'
                      ? `${props.usuario.codigo} - ${props.usuario.nome} (Gerencia)`
                      : `${props.usuario.codigo} - Representante ${props.usuario.nome}${props.usuario.supervisor
                        ? ` / ${props.usuario.supervisor} - Supervisor ${props.usuario.nomeSupervisor}` : ''}`
                  }
                </Typography>
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Button sx={{ color: '#fff', marginLeft: '10px' }} onClick={props.logout}>Deslogar</Button>
              </Box>
            </>
          }
          {IS_MOBILE && props.menu === ItemMenu.Clientes
            ? <>
              {props.recarregaClientes &&
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="recarregar"
                  onClick={() => {
                    props.recarregaClientes!()
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              }
              {props.novoCliente &&
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="adicionar"
                  onClick={() => {
                    props.novoCliente!()
                  }}
                >
                  <AddIcon />
                </IconButton>
              }
            </>
            : undefined
          }
          {IS_MOBILE && props.menu === ItemMenu.Pedidos &&
            <>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="recarregar"
                onClick={() => {
                  props.recarregaPedidos()
                }}
              >
                <RefreshIcon />
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="adicionar"
                onClick={() => {
                  props.setMenu(ItemMenu.NovoPedido)
                }}
              >
                <AddIcon />
              </IconButton>
            </>
          }
          {IS_MOBILE && props.menu === ItemMenu.Orcamentos &&
            <>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="adicionar"
                onClick={() => {
                  props.setOpenNovoOrcamento(true)
                  props.setAcaoOrcamento(Acao.Incluir)
                }}
              >
                <AddIcon />
              </IconButton>
            </>
          }
        </Toolbar>
      </AppBar>
    </Box>
  )
}