import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { ItemMenu } from './item-menu'

export default function Abas({ abas, aba, setMenu }: { abas: ItemMenu[], aba: ItemMenu, setMenu(value: ItemMenu): void }) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setMenu(newValue)
  }

  function criaAbas(abas: ItemMenu[]) {
    return abas.map(a => {
      switch (a) {
        case ItemMenu.Clientes:
          return <Tab key={a} value={a} label="Clientes"
            onClick={() => setMenu(ItemMenu.Clientes)}
          />
        case ItemMenu.Cliente:
          return <Tab key={a} value={a} label="Cliente"
            onClick={() => setMenu(ItemMenu.Cliente)}
          />
        case ItemMenu.NovoCliente:
          return <Tab key={a} value={a} label="Novo Cliente"
            onClick={() => setMenu(ItemMenu.NovoCliente)}
          />
        case ItemMenu.AlterarCliente:
          return <Tab key={a} value={a} label="Alterar Cliente"
            onClick={() => setMenu(ItemMenu.AlterarCliente)}
          />
        case ItemMenu.Produtos:
          return <Tab key={a} value={a} label="Produtos"
            onClick={() => setMenu(ItemMenu.Produtos)}
          />
        case ItemMenu.Pedidos:
          return <Tab key={a} value={a} label="Pedidos"
            onClick={() => setMenu(ItemMenu.Pedidos)}
          />
        case ItemMenu.NovoPedido:
          return <Tab key={a} value={a} label="Novo Pedido"
            onClick={() => setMenu(ItemMenu.NovoPedido)}
          />
        case ItemMenu.Orcamentos:
          return <Tab key={a} value={a} label="Orçamentos"
            onClick={() => setMenu(ItemMenu.Orcamentos)}
          />
        case ItemMenu.Orcamento:
          return <Tab key={a} value={a} label="Orçamento"
            onClick={() => setMenu(ItemMenu.Orcamento)}
          />
        case ItemMenu.NovoOrcamento:
          return <Tab key={a} value={a} label="Novo Orçamento"
            onClick={() => setMenu(ItemMenu.NovoOrcamento)}
          />
        case ItemMenu.Configuracao:
          return <Tab key={a} value={a} label="Configuração"
            onClick={() => setMenu(ItemMenu.Configuracao)}
          />
        default:
          return undefined
      }
    })
  }

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      {
        abas.length
          ? <Tabs
            value={aba}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {IS_MOBILE
              ? criaAbas([aba])
              : criaAbas(abas)
            }
          </Tabs>
          : undefined}
    </Box>
  )
}