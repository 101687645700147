import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, type GridColDef, type GridRowParams } from '@mui/x-data-grid'
import { Dialog, AppBar, IconButton, Toolbar, Typography, Tooltip } from '@mui/material'
import { getRepresentantes, type Representante } from './model/Representante'
import type { Perfil } from './login'
import { Transition } from './transition'

export default function PesquisaRepresentantes(props: {
  empresa: string
  perfil: keyof typeof Perfil
  id: number
  open: boolean
  setOpen(value: boolean): void
  representantes: Representante[]
  setRepresentantes(value: Representante[]): void
  setRepresentante(value: Representante): void
  setMensagem(value: string): void
  setOpenMensagem(value: boolean): void
}) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
      align: 'right',
      headerAlign: 'right',
      sortable: !IS_MOBILE
    },
    {
      field: 'nome',
      headerName: 'Representante',
      width: 480
    }
  ]

  if (IS_MOBILE)
    columns.unshift({
      field: 'acoes',
      type: 'actions',
      width: 50,
      getActions(params) {
        return [
          <GridActionsCellItem
            icon={<Tooltip title='Selecionar Representante'><CheckCircleIcon color='primary' /></Tooltip>}
            label='Selecionar Representante'
            onClick={() => handleSelectClick(params)}
          />
        ]
      }
    })

  const [loaded, setLoaded] = useState(false)

  async function load() {
    setLoaded(true)
    await getRepresentantes(props.empresa, props.perfil, props.id, props.setMensagem, props.setOpenMensagem, props.setRepresentantes)
  }

  if (!(props.representantes.length || loaded))
    load()

  function handleClose() {
    props.setOpen(false)
  }

  function handleSelectClick(params: GridRowParams<Representante>) {
    props.setRepresentante(params.row)
    props.setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Pesquisa de Representantes
          </Typography>
          {IS_MOBILE || <Typography variant="subtitle1" component="div">
            Clique duplo para selecionar
          </Typography>}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 'calc(100vh - 48px)',
          width: '100%',
        }}
      >
        <DataGrid
          rows={props.representantes}
          columns={columns}
          columnHeaderHeight={25}
          rowHeight={25}
          onRowDoubleClick={handleSelectClick}
        />
      </Box>
    </Dialog>
  )
}