import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material'

export function Uf(props: {
  disabled: boolean
  uf: string | null
  setUf?(value: string | null): void
  required?: boolean
}) {
  function handleChangeUf(event: SelectChangeEvent) {
    props.setUf?.(event.target.value || null)
  }

  return <FormControl sx={{
    m: '5px',
    width: 'calc(100% - 10px)'
  }} variant={IS_MOBILE ? 'standard' : 'outlined'} size='small'>
    <InputLabel id="uf-label">UF</InputLabel>
    <Select
      labelId="uf-label"
      id="uf-select"
      value={props.uf ?? ''}
      label="UF"
      onChange={handleChangeUf}
      disabled={props.disabled}
      error={!!props.required && !props.uf}
    >
      <MenuItem value=''></MenuItem>
      <MenuItem value='AC'>AC - Acre</MenuItem>
      <MenuItem value='AL'>AL - Alagoas</MenuItem>
      <MenuItem value='AM'>AM - Amazonas</MenuItem>
      <MenuItem value='AP'>AP - Amapá</MenuItem>
      <MenuItem value='BA'>BA - Bahia</MenuItem>
      <MenuItem value='CE'>CE - Ceará</MenuItem>
      <MenuItem value='DF'>DF - Distr. Federal</MenuItem>
      <MenuItem value='ES'>ES - Espirito Santo</MenuItem>
      <MenuItem value='EX'>EX - Exterior</MenuItem>
      <MenuItem value='GO'>GO - Goias</MenuItem>
      <MenuItem value='MA'>MA - Maranhão</MenuItem>
      <MenuItem value='MG'>MG - Minas Gerais</MenuItem>
      <MenuItem value='MS'>MS - Mato Grosso do Sul</MenuItem>
      <MenuItem value='MT'>MT - Mato Grosso</MenuItem>
      <MenuItem value='PA'>PA - Pará</MenuItem>
      <MenuItem value='PB'>PB - Paraíba</MenuItem>
      <MenuItem value='PE'>PE - Pernambuco</MenuItem>
      <MenuItem value='PI'>PI - Piauí</MenuItem>
      <MenuItem value='PR'>PR - Paraná</MenuItem>
      <MenuItem value='RJ'>RJ - Rio de Janeiro</MenuItem>
      <MenuItem value='RN'>RN - Rio Grande do Norte</MenuItem>
      <MenuItem value='RO'>RO - Rondonia</MenuItem>
      <MenuItem value='RR'>RR - Roraima</MenuItem>
      <MenuItem value='RS'>RS - Rio Grande do Sul</MenuItem>
      <MenuItem value='SC'>SC - Santa Catarina</MenuItem>
      <MenuItem value='SE'>SE - Sergipe</MenuItem>
      <MenuItem value='SP'>SP - São Paulo</MenuItem>
      <MenuItem value='TO'>TO - Tocantins</MenuItem>
    </Select>
  </FormControl>
}