import React from 'react'
import AddIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, Typography, IconButton, Divider, List, ListItem, ListItemText } from '@mui/material'
import type { NewPedidoItem } from './model/NewPedidoItem'
import { formatCurrency } from './utils'
import { ItemPedido } from './item-pedido'

export function PedidoItens({
  setProcuraProduto,
  disabled,
  itens,
  setItens,
  removeItem
}: {
  setProcuraProduto(value: boolean): void,
  disabled: boolean,
  itens: NewPedidoItem[],
  setItens(value: NewPedidoItem[]): void,
  removeItem(item: NewPedidoItem): void
}) {
  return <>
    <Grid item xs={12} textAlign='center'>
      <Typography variant='button'>Itens</Typography>
    </Grid>
    <List>
      {itens.map((item, index) =>
        <><ListItem sx={{
          backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
          m: '6px 0px 0px 1px',
          borderRadius: '6px'
        }}>
          <ListItemText
            primary={<Grid container>
              <Grid item xs={11}>
                <Typography variant='subtitle1'>
                  {`${item.produto} - ${item.nome}`}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign='center'>
                <IconButton color='primary' sx={{ padding: '0px' }} onClick={() => {
                  removeItem(item)
                }}><DeleteIcon /></IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='overline'>{`1 ${item.unidadePedido} = ${item.volume} ${item.unidade} | Unit: ${formatCurrency(item.valorUnitarioProduto)} | TOTAL: ${formatCurrency(item.valorTotalProduto)}`}</Typography>
              </Grid>
            </Grid>} secondary={<Grid container><ItemPedido item={item} itens={itens} setItens={setItens} /></Grid>} />
        </ListItem></>
      )}
    </List>
    <Grid item xs={6}>
      <Typography variant='subtitle1' paddingLeft='10px' paddingTop='8px'>{`TOTAL: R$ ${formatCurrency(itens.reduce((p, c) => p + c.valorTotal, 0))}`}</Typography>
    </Grid>
    <Grid item xs={6} textAlign='right'>
      <IconButton color='primary' onClick={() => setProcuraProduto(true)}><AddIcon /></IconButton>
    </Grid>
    <Grid item xs={12}>
      <Divider variant='middle' />
    </Grid>
  </>
}