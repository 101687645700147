import React, { useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '@mui/icons-material/Restore'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import { DataGrid, useGridApiRef, type GridColDef, GridToolbarContainer, GridActionsCellItem, type GridRowId, GridCellEditStopReasons } from '@mui/x-data-grid'
import type { NewPedidoItem } from './model/NewPedidoItem'
import { getDefaultProduto, type Produto } from './model/Produto'
import type { Perfil } from './login'
import { Button, Tooltip } from '@mui/material'
import SelecaoProduto from './selecao-produto'

let itens: NewPedidoItem[]
export let idItem: GridRowId

export function getItens() {
  return itens
}

export function resetItens() {
  itens = [{
    id: 1,
    produto: '',
    nome: '',
    volume: 0,
    unidadePedido: '',
    unidade: '',
    quantidade: 1,
    volumeTotalUnidades: 0,
    valorUnitario: 0,
    valorTotal: 0,
    quantidadeStr: '',
    valorUnitarioStr: '',
    valorUnitarioProduto: 0,
    valorTotalProduto: 0
  }]
}

resetItens()

export default function PedidoVendaItens({ perfil, produtos, setProcuraProduto, gridChanged, disabled }: { perfil: keyof typeof Perfil, produtos: Produto[], setProcuraProduto(value: boolean): void, gridChanged(): void, disabled: boolean }) {
  useEffect(() => {
    apiRef.current.setRows?.(itens)
  })

  const columns: GridColDef[] = [
    {
      field: 'acoes',
      type: 'actions',
      headerName: 'Ações',
      width: 106,
      getActions({ id }) {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Buscar Produto">
                <SearchIcon />
              </Tooltip>
            }
            label="Buscar Produto"
            onClick={() => handleSearchClick(id)}
            disabled={disabled}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Restaurar Valor Unitário">
                <RestoreIcon />
              </Tooltip>
            }
            label="Restaurar Valor Unitário"
            onClick={() => handleRestoreClick(id)}
            disabled={disabled}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Remover Item">
                <DeleteIcon />
              </Tooltip>
            }
            label="Remover Item"
            onClick={() => handleDeleteClick(id)}
            disabled={disabled}
          />
        ]
      }
    },
    {
      field: 'produto',
      headerName: 'Produto',
      width: 450,
      editable: true,
      cellClassName(params) {
        return (params.row as NewPedidoItem).nome ? '' : 'cell-error'
      },
      renderCell(params) {
        const row = params.row as NewPedidoItem

        return row.produto ? `${row.produto} - ${row.nome}` : ''
      },
      renderEditCell(params) {
        return <SelecaoProduto hasFocus={params.hasFocus} produtos={produtos} produto={
          produtos.find(p => p.id === (params.row as NewPedidoItem).produto) ?? getDefaultProduto()
        } setProduto={(produto: Produto | null) => {
          const row = params.row as NewPedidoItem
          const item = itens.find(i => i.id === row.id)!

          item.produto = row.produto = produto?.id ?? ''
          item.nome = row.nome = produto?.descricao ?? ''
          item.volume = row.volume = produto?.quantidadeUnidade ?? 1
          item.unidade = row.unidade = produto?.unidade ?? ''
          item.quantidade = row.quantidade = 1
          item.volumeTotalUnidades = row.volumeTotalUnidades = item.volume * item.quantidade
          item.valorUnitario = row.valorUnitario = produto?.precoVenda ?? 0
          item.valorTotal = row.valorTotal = item.volumeTotalUnidades * item.valorUnitario

          gridChanged()
        }} />
      }
    },
    {
      field: 'volume',
      headerName: 'Volume Caixa',
      width: 100,
      align: 'right',
      headerAlign: 'right',
      cellClassName: 'cell-disabled',
      type: 'number'
    },
    {
      field: 'unidade',
      headerName: 'Unidade',
      width: 70,
      cellClassName: 'cell-disabled'
    },
    {
      field: 'quantidade',
      headerName: 'Quantidade Caixas',
      width: 140,
      align: 'right',
      headerAlign: 'right',
      editable: true,
      cellClassName(params) {
        return (params.row as NewPedidoItem).quantidade > 0 ? '' : 'cell-error'
      },
      type: 'number'
    },
    {
      field: 'volumeTotalUnidades',
      headerName: 'Volume em Unidades',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      cellClassName: 'cell-disabled',
      type: 'number'
    },
    {
      field: 'valorUnitario',
      headerName: '$ Valor Unitário',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      valueFormatter(params) {
        return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
      },
      editable: true,
      cellClassName(params) {
        return (params.row as NewPedidoItem).valorUnitario > 0 ? '' : 'cell-error'
      },
      type: 'number'
    },
    {
      field: 'valorTotal',
      headerName: '$ Total por Item',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      valueFormatter(params) {
        return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
      },
      cellClassName: 'cell-disabled',
      type: 'number'
    }
  ]

  const apiRef = useGridApiRef()

  function podeAdicionar() {
    return itens.every(i => !!(i.nome && (i.quantidade > 0) && (i.valorUnitario > 0)))
  }

  function addItem() {
    if (podeAdicionar()) {
      const newId = itens.length ? itens.at(-1)!.id + 1 : 1

      itens = [...itens, {
        id: newId,
        produto: '',
        nome: '',
        volume: 0,
        unidadePedido: '',
        unidade: '',
        quantidade: 1,
        volumeTotalUnidades: 0,
        valorUnitario: 0,
        valorTotal: 0,
        quantidadeStr: '',
        valorUnitarioStr: '',
        valorUnitarioProduto: 0,
        valorTotalProduto: 0
      }]

      apiRef.current.setRows(itens)
      apiRef.current.setCellFocus(newId, 'produto')
      gridChanged()
    }
  }

  function handleDeleteClick(id: GridRowId) {
    let index = itens.findIndex(i => i.id === id)

    itens = itens.filter((row) => row.id !== id)

    if (index === itens.length)
      index--

    apiRef.current.setRows(itens)

    if (index >= 0)
      apiRef.current.setCellFocus(itens[index].id, 'produto')

    gridChanged()
  }

  function handleRestoreClick(id: GridRowId) {
    const item = itens.find(i => i.id === id)

    if (item) {
      const produto = produtos.find(p => p.id === item.produto)

      if (produto) {
        item.valorUnitario = produto.precoVenda ?? 0
        item.valorTotal = item.volumeTotalUnidades * item.valorUnitario
        apiRef.current.setRows(itens)
        gridChanged()
      }
    }
  }

  function handleSearchClick(id: GridRowId) {
    idItem = id

    setProcuraProduto(true)
  }

  return (
    <Box sx={{
      m: '5px',
      height: `calc(100vh - 48px - 48px - 243px - 46.5px${perfil !== 'representante' ? ' - 50px' : ''})`,
      width: 'calc(100% - 10px)'
    }}>
      <DataGrid apiRef={apiRef}
        columns={columns}
        rows={getItens()}
        columnHeaderHeight={25}
        rowHeight={25}
        onCellEditStop={(params, event, details) => {
          if (params.reason === GridCellEditStopReasons.cellFocusOut)
            params.reason = GridCellEditStopReasons.escapeKeyDown

          if (params.reason === GridCellEditStopReasons.escapeKeyDown)
            return

          const row = params.row as NewPedidoItem
          const item = itens.find(i => i.id === row.id)!

          // @ts-expect-error
          let value: string = event.target.value
          const column = params.field

          if (column === 'produto' && value === '') {
            // value = value.padStart(6, '0')
            // const produto = produtos.find(p => p.id === value)

            // if (produto) {
            //   item.produto = produto.id
            //   item.nome = produto.descricao
            //   item.volume = produto.quantidadeUnidade ?? 1
            //   item.unidade = produto.unidade
            //   item.valorUnitario = produto.precoVenda ?? 1
            // } else {
            item.produto = ''
            item.nome = ''
            item.volume = 0
            item.unidade = ''
            item.valorUnitario = 0
            // }

            item.quantidade = 1
          } else if (column === 'valorUnitario')
            item.valorUnitario = Number.parseFloat(value.replace(',', '.'))
          else if (column === 'quantidade')
            item.quantidade = Number.parseFloat(value)

          item.volumeTotalUnidades = item.quantidade * item.volume
          item.valorTotal = item.volumeTotalUnidades * item.valorUnitario

          row.produto = item.produto
          row.nome = item.nome
          row.volume = item.volume
          row.unidade = item.unidade
          row.valorUnitario = item.valorUnitario
          row.quantidade = item.quantidade
          row.volumeTotalUnidades = item.volumeTotalUnidades
          row.valorTotal = item.valorTotal

          // switch (column) {
          //   case 'produto':
          //     console.log('produto', params.id)
          //     apiRef.current.setCellFocus(params.id, 'quantidade')

          //     break
          //   case 'quantidade':
          //     console.log('quantidade', params.id)
          //     apiRef.current.setCellFocus(params.id, 'valorUnitario')

          //     break
          // }

          gridChanged()
        }}
        onCellKeyDown={(params, event, details) => {
          if (params.cellMode === 'view') {
            if (event.key === 'ArrowDown') {
              const lastId = itens.length ? itens.at(-1)!.id : 0

              if (params.id === lastId)
                addItem()
            } else if (event.key === 'Tab') {
              if (event.shiftKey)
                event.key = 'ArrowLeft'
              else
                event.key = 'ArrowRight'
            }
          }
        }}
        slots={{
          toolbar: AddPedidoVendaItem
        }}
        slotProps={{ toolbar: { addItem, disabled } }}
      />
    </Box>
  )
}

function AddPedidoVendaItem({ addItem, disabled }: { addItem(): void, disabled: boolean }) {
  return <GridToolbarContainer>
    <Button color="primary" startIcon={<AddIcon />} onClick={() => {
      addItem()
    }} disabled={disabled}>
      Adicionar
    </Button>
  </GridToolbarContainer>
}