import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import PrintIcon from '@mui/icons-material/Print'
import ShareIcon from '@mui/icons-material/Share'
import DoneIcon from '@mui/icons-material/Done'
import Box from '@mui/material/Box'
import { DataGrid, useGridApiRef, type GridColDef, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid'
import { getPedidos, type Pedido } from './model/Pedido'
import type { Perfil } from './login'
import { Button, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import { ItemMenu } from './item-menu'
import { formatCnpj, formatCurrency } from './utils'

export default function PedidosVendas({ empresa, perfil, usuario, visible, setPedido, setMenu, pedidos, setPedidos, setMensagem, setOpenMensagem, showReport }: { empresa: string, perfil: keyof typeof Perfil, usuario: number, visible: boolean, setPedido(value: number): void, setMenu(value: ItemMenu): void, pedidos: Pedido[], setPedidos(value: Pedido[]): void, setMensagem(value: string): void, setOpenMensagem(value: boolean): void, showReport(pedido: Pedido): void }) {
  const apiRef = useGridApiRef()
  const [loaded, setLoaded] = useState(false)
  const [filter, setFilter] = useState('')
  const [filtered, setFiltered] = useState(getUnfiltered())

  function applyFilter(_filter: string = filter) {
    if (_filter.trim().length > 0) {
      _filter = _filter.toLowerCase()

      setFiltered(pedidos.filter(pedido =>
        pedido.fantasia?.toLowerCase().includes(_filter)
        || pedido.razaoSocial?.toLowerCase().includes(_filter)
        || pedido.cnpj?.includes(_filter)
        || pedido.codigoCliente.toString().includes(_filter)
        || pedido.endereco?.toLowerCase().includes(_filter)
        || pedido.municipio?.toLowerCase().includes(_filter)
        || pedido.id.toString().includes(_filter)
        || pedido.numeroInterno?.toString().includes(_filter)
      ))
    } else
      setFiltered(getUnfiltered())
  }

  useEffect(() => {
    applyFilter()
  }, [pedidos])

  function getUnfiltered(_pedidos: Pedido[] = pedidos): Pedido[] {
    return _pedidos.slice(0, 5)
  }

  const representanteColumns: GridColDef[] = [
    {
      field: 'representante',
      headerName: 'Representante',
      width: 110,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 250
    }
  ]

  const supervisorColumns: GridColDef[] = [{
    field: 'supervisor',
    headerName: 'Supervisor',
    width: 110
  }]

  const actionsColumns: GridColDef[] = [
    {
      field: 'acoes',
      type: 'actions',
      headerName: 'Ações',
      width: 60,
      getActions({ row }) {
        return [
          <GridActionsCellItem
            icon={<PrintIcon />}
            label="Imprimir Pedido"
            onClick={() => handlePrintClick(row)}
          />
        ]
      }
    }
  ]

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Nr.',
      width: 60,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'numeroInterno',
      headerName: 'Nr. Interno',
      width: 90
    },
    {
      field: 'codigoCliente',
      headerName: 'Cod. Cliente',
      width: 90,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'fantasia',
      headerName: 'Fantasia',
      width: 300
    },
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      width: 500
    },
    {
      field: 'dataPedido',
      headerName: 'Data Pedido',
      width: 110
    },
    {
      field: 'dataEntrega',
      headerName: 'Data Entrega',
      width: 110
    },
    {
      field: 'totalPedido',
      headerName: '$ Total Pedido',
      width: 100,
      align: 'right',
      headerAlign: 'right',
      valueGetter(params) {
        return `R$ ${(params.value as number).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
      }
    },
    {
      field: 'pedidoCliente',
      headerName: 'Pedido Cliente',
      width: 110
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150
    },
    {
      field: 'identificacoes',
      headerName: 'Identificações',
      width: 450
    },
    {
      field: 'origem',
      headerName: 'Origem',
      width: 60
    }
  ]

  async function handlePrintClick(row: Pedido) {
    let blob: Blob

    try {
      if (IS_MOBILE) {
        const name = `Pedido ${row.id} - ${row.fantasia}`
        const result = await fetch(`/relatorio-pedido/${empresa}/${row.id}/${name}`)

        if (result.status === 200) {
          blob = await result.blob()

          await navigator.share({
            files: [new File([blob], `${name}.pdf`, { type: 'application/pdf' })]
          })
        } else if (result.status === 404 && result.redirected && result.url.endsWith('/login')) {
          document.cookie = 'logado=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Secure'
          setMensagem('Você está deslogado.\nFavor relogar.')
          setOpenMensagem(true)
        } else {
          setMensagem(`Erro ${result.status} - ${result.statusText}.`)
          setOpenMensagem(true)
        }
      } else
        showReport(row)
    } catch (e) {
      const mensagem = (e as Error).message

      if (mensagem === 'Failed to fetch')
        setMensagem('Erro ao tentar conectar com o servidor.\nVerifique sua conexão.')
      else
        setMensagem(`Erro: ${mensagem}.`)

      setOpenMensagem(true)
    }
  }

  function _setPedidos(_pedidos: Pedido[]) {
    setPedidos(_pedidos)
    setFiltered(getUnfiltered(_pedidos))
  }

  async function load() {
    if (!(pedidos.length || loaded)) {
      setLoaded(true)
      await getPedidos(empresa, perfil, usuario, setMensagem, setOpenMensagem, _setPedidos)
    }
  }

  load()

  return (
    <Box sx={{
      ...(IS_MOBILE ? {} : { height: 'calc((100vh - 48px - 48px) / 2)' }),
      ...(IS_MOBILE ? { width: 'calc(100% - 10px)' } : { width: '100%' }),
      ...(!visible ? { display: 'none' } : {})
    }}>
      {IS_MOBILE && <>
        <div style={{ height: '10px' }}></div>
        <Grid container sx={{
          m: '5px',
          p: '10px'
        }}>
          <Grid item xs={12}>
            <TextField variant='standard' size='small' label='Filtro' value={filter}
              sx={{ width: '100%' }}
              onChange={event => {
                let filter = event.target.value
                setFilter(filter)
                applyFilter(filter)
              }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption'>Fantasia, Razão Social, CNPJ, código do cliente, código do pedido, endereço ou município</Typography>
          </Grid>
        </Grid>
      </>}
      {IS_MOBILE
        ? <List>
          {filtered.map((pedido, index) =>
            <>
              <ListItem sx={{
                backgroundColor: index % 2 ? '#f8f8f8' : '#f0f0f0',
                m: '6px 0px 0px 6px',
                borderRadius: '6px'
              }}>
                <ListItemText
                  primary={`${pedido.codigoCliente}${pedido.fantasia ? ` - ${pedido.fantasia}` : ''}`}
                  secondary={<>
                    {[
                      pedido.cnpj && ['CNPJ', formatCnpj(pedido.cnpj)],
                      ['Data e hora', `${pedido.dataPedido} ${pedido.horaPedido}`],
                      pedido.pagamento && ['Pagamento', `${pedido.pagamento}${pedido.descricaoPagamento ? ` - ${pedido.descricaoPagamento}` : ''}`],
                      pedido.status && ['Status', `${pedido.status}${pedido.identificacoes ? ` - ${pedido.identificacoes}` : ''}`],
                      pedido.numeroInterno ? ['Pedido WEB/Interno', `${pedido.id} / ${pedido.numeroInterno}`] : ['Pedido WEB', pedido.id],
                      pedido.observacao && ['Observação', pedido.observacao],
                      [`TOTAL: R$ ${formatCurrency(pedido.totalPedido)}`]
                    ].filter((i): i is (string | number)[] => !!i).map(i =>
                      <Grid container>
                        {i[0] && i[1]
                          ? <>
                            <Grid item xs={6}>
                              <Typography variant='overline'>{i[0]}</Typography>
                            </Grid>
                            <Grid item xs={i[0] === 'Pedido WEB/Interno' ? 5 : 6}>
                              <Typography variant='subtitle2' marginTop='4px'>{i[1]}</Typography>
                            </Grid>
                            {i[0] === 'Pedido WEB/Interno'
                              ? <Grid item xs={1} textAlign='right'>
                                <DoneIcon color='success' />
                              </Grid>
                              : undefined
                            }
                          </>
                          : i[0]
                            ? <>
                              <Grid item xs={9}>
                                <Typography variant='h6'>{i[0]}</Typography>
                              </Grid>
                              <Grid item xs={3} textAlign='right'>
                                <IconButton color='primary' sx={{ p: '4px' }} onClick={event => {
                                  setPedido(pedido.id)
                                  setMenu(ItemMenu.Pedido)
                                }}>
                                  <VisibilityIcon />
                                </IconButton>
                                <IconButton color='primary' sx={{ p: '4px' }} onClick={event => {
                                  handlePrintClick(pedido)
                                }}>
                                  {IS_MOBILE ? <ShareIcon /> : <PrintIcon />}
                                </IconButton>
                              </Grid>
                            </>
                            : undefined
                        }
                      </Grid>
                    )}
                  </>}
                />
              </ListItem>
            </>
          )}
        </List>
        : <DataGrid
          apiRef={apiRef}
          columns={[
            ...actionsColumns,
            ...(perfil !== 'representante' ? representanteColumns : []),
            ...(perfil === 'gerencia' ? supervisorColumns : []),
            ...columns
          ]}
          rows={pedidos}
          columnHeaderHeight={25}
          rowHeight={25}
          onRowSelectionModelChange={(model, details) => {
            setPedido(model.length === 1 ? +model[0] : 0)
          }}
          slots={{
            toolbar: AddPedidoVenda
          }}
          slotProps={{ toolbar: { setMenu } }}
          onFilterModelChange={(model, detais) => {
            apiRef.current.setRowSelectionModel([])
            setPedido(0)
          }}
        />
      }
    </Box>
  )
}

function AddPedidoVenda({ setMenu }: { setMenu(value: ItemMenu): void }) {
  return <GridToolbarContainer>
    <Button color="primary" startIcon={<AddIcon />} onClick={() => {
      setMenu(ItemMenu.NovoPedido)
    }}>
      Adicionar
    </Button>
  </GridToolbarContainer>
}